import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { NbComponentStatus } from '@nebular/theme';
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";

export type Suggestion = string;

@Component({
    selector: "iovent-search",
    templateUrl: "./iovent-search.component.html",
    styleUrls: ["./iovent-search.component.scss"]
})
export class IoventSearchComponent extends Localized implements OnInit {


    @ViewChild('searchInput', { static: true }) searchInput: ElementRef;


    @Input() termLength: number = 0;
    @Input() autoSearch: number = 1;
    @Input() placeholder: string = this.translate("🌐General.SearchTerm");
    @Input() searchterm: string = "";
    @Input()
    set suggestions(s: Suggestion[]) {
        this._suggestions = s;
    }

    @Output() onSearch: EventEmitter<string> = new EventEmitter();

    _suggestions: Suggestion[];

    constructor(translationService: TranslationService) {
        super(translationService);
    }

    ngOnInit() {
    }

    getStatus(): NbComponentStatus {
        if (this.searchterm != "") return "info";
        return "primary";
    }

    getSearchTermFromInput(): string {
        return this.searchInput.nativeElement.value;
    }


    timeout_handle;
    clearTimeout() {
        clearTimeout(this.timeout_handle);
    }


    search() {
        clearTimeout();
        this.searchterm = this.getSearchTermFromInput();
        this.onSearch.emit(this.searchterm);
        setTimeout(this.clearTimeout.bind(this), 0); // stop follow up timeouts - event order unknown
    }

    change() {
        clearTimeout()
        if (this.autoSearch >= 0) {
            const temp = this.getSearchTermFromInput();
            if (temp.length >= this.termLength || temp.length === 0) {
                this.timeout_handle = setTimeout(this.search.bind(this), this.autoSearch * 1000);
            }
        }
    }

}
