import { IEnvironment } from "./defaults/environment-defaults";

import { environment as angularEnvironment } from "./environment";
import { environment as demoEnvironment } from "./defaults/environment.demo";
import { environment as localEnvironment } from "./defaults/environment.local";
import { localOverrides } from "./environment.local.overrides";
import { environment as sandboxEnvironment } from "./defaults/environment.sandbox.dev";
import { environment as sharedTestingEnvironment } from "./defaults/environment.shared.dev";
import { environment as sharedProdEnvironment } from "./defaults/environment.shared-prod";


import { environment as turProd } from "./defaults/environment.tur";
import { environment as turStaging } from "./defaults/environment.tur-staging";

// domain/default->localsettings->env

const environments = {
    demo: demoEnvironment,
    sandbox: sandboxEnvironment,
    testing: sharedTestingEnvironment,
    "staging.tur": turStaging,
    tur: turProd
}

export function environment(): IEnvironment {
    let env = localEnvironment;

    if (window.location.host.includes(".iovent")) {
        const subdomain = window.location.host.split('.iovent')[0];
        env = environments[subdomain] ?? sharedProdEnvironment;
    }    

    return { 
        ...env,                 // default values
        ...localOverrides,      // values for local testing should be inserted here
        ...angularEnvironment,  // these values override everything
     };
}
