<div class="EversysProductGenerator">
  <ng-container *ngIf="!generatedProductBases">
    <div class="EversysProductGenerator-formLayout">
      <div>
        <div cdkDropList [cdkDropListData]="options" (cdkDropListDropped)="drop($event)">
          <nb-card *ngFor="let option of options" expanded cdkDrag>
            <nb-card-header>
              <button nbButton ghost>
                <nb-icon icon="menu" ></nb-icon>
              </button>
              &nbsp;&nbsp;{{option.Name}} <i>({{option.ID}})</i>&nbsp;&nbsp;
              <button nbButton ghost status="danger" (click)="removeOption(option)">
                <nb-icon icon="trash-2-outline" ></nb-icon>
              </button>
              
            </nb-card-header>
            <nb-card-body>
              <table class="simple-table">
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Aktion</th>
                  
                </tr>
                <tr *ngFor="let choice of option.Choices">
                  
                  <td>
                    <input nbInput type="text" placeholder="ID" [(ngModel)]="choice.ID">
                  </td>
                  <td>
                    <input nbInput type="text" placeholder="Name" [(ngModel)]="choice.Name">
                  </td>
                  <td>
                    <button nbButton ghost status="danger" (click)="removeChoice(option, choice)">
                      <nb-icon icon="trash-2-outline" ></nb-icon>
                    </button>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <button nbButton ghost status="success" (click)="addChoice(option)">
                      <nb-icon icon="plus"></nb-icon>
                    </button>
                  </td>
                </tr>
              </table>
            </nb-card-body>
          </nb-card>
          <div>
            <input nbInput type="text" placeholder="ID" [(ngModel)]="newOptionId">
            <input nbInput type="text" placeholder="Name" [(ngModel)]="newOptionName">
            <button nbButton status="success" (click)="addOption()">
              <nb-icon icon="plus"></nb-icon>
              Option hinzufügen
            </button>
        </div>
        </div>
      </div>
      <div>
        <nb-card>
          <nb-card-header>
            Products
          </nb-card-header>
          <nb-card-body>
            <div>
              <div *ngFor="let blueprintProduct of blueprintProducts">
                <nb-checkbox [(checked)]="blueprintProduct.checked">{{ blueprintProduct.productBase.Name }} <i>({{ blueprintProduct.productBase.ID }})</i></nb-checkbox>
              </div>
            </div>
          </nb-card-body>
        </nb-card>

        <nb-card>
          <nb-card-header>
            Generierungsinformationen
          </nb-card-header>
          <nb-card-body>
            <div class="row">
              <div class="col-4">
                <label for="productPrefix">Produkt-Prefix</label>
              </div>
              <div class="col-8">
                <input nbInput type="text" name="productPrefix" [(ngModel)]="productPrefix">
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="theme">Themes</label>
              </div>
              <div class="col-8">
                <nb-select name="theme" [selected]="theme" (selectedChange)="changeTheme($event)">
                      <nb-option *ngFor="let theme of productThemes" [value]="theme">{{theme.name}}</nb-option>
                  </nb-select>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label for="vat">{{"🌐Products.Instance.VATRate" | translate:lang}}</label>
              </div>
              <div class="col-8">
                <nb-select name="vat" [selected]="vat" (selectedChange)="changeVat($event)">
                      <nb-option [value]="0">0%</nb-option>
                      <nb-option [value]="5">5%</nb-option>
                      <nb-option [value]="7">7%</nb-option>
                      <nb-option [value]="10">10%</nb-option>
                      <nb-option [value]="16">16%</nb-option>
                      <nb-option [value]="19">19%</nb-option>
                  </nb-select>
              </div>
            </div>        
          
          </nb-card-body>
          <nb-card-footer>
            <button nbButton status="basic" (click)="closeDialog()">{{ '🌐General.Cancel' | translate:lang }}</button>
            <button nbButton status="primary" (click)="generate()">Generate</button>
          </nb-card-footer>
        </nb-card>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="generatedProductBases">
    <nb-card>
      <nb-card-header>
        Generierte Produkte
      </nb-card-header>
      <nb-card-body>
        <table class="simple-table">
          <tr>
            <th>Produkt</th>
            <th>{{"🌐Products.Instance.MachineNumber" | translate:lang}}</th>
            <th>{{"🌐Products.Instance.ServingTimeInSeconds" | translate:lang}}</th>
            <th>Beans</th>
            <th>Milk</th>
            <th>Powder</th>
          </tr>
          <ng-container *ngFor="let productBase of generatedProductBases">
            <tr *ngFor="let productInstance of productBase.Instances">
              <td>{{ productInstance.Name }} <i>({{ productInstance.ID }})</i></td>
              <td>
                <input [(ngModel)]="productInstance.SelectionNumber" nbInput class="text-right" />
              </td>
              <td>
                <input [(ngModel)]="productInstance.ServingTime" nbInput class="text-right" />
              </td>
              <td>
                <div class="resourceCellContent">
                  <nb-checkbox
                    *ngFor="let resource of beanResources" 
                    [checked]="isResourceChecked(productInstance.Resources, resource)"
                    (toggle)="toggleResource(productInstance.Resources, resource)"
                  >{{resource}}</nb-checkbox>
                </div>
              </td>
              <td>
                <div class="resourceCellContent">
                  <nb-checkbox
                    *ngFor="let resource of milkResources" 
                    [checked]="isResourceChecked(productInstance.Resources, resource)"
                    (toggle)="toggleResource(productInstance.Resources, resource)"
                  >{{resource}}</nb-checkbox>
                </div>
              </td>
              <td>
                <div class="resourceCellContent">
                  <nb-checkbox
                    *ngFor="let resource of powderResources" 
                    [checked]="isResourceChecked(productInstance.Resources, resource)"
                    (toggle)="toggleResource(productInstance.Resources, resource)"
                  >{{resource}}</nb-checkbox>
                </div>
              </td>
            </tr>
          </ng-container>
        </table>
      </nb-card-body>
      <nb-card-footer>
        <button nbButton status="basic" (click)="rejectReview()">{{ '🌐General.Cancel' | translate:lang }}</button>
        <button nbButton status="primary" (click)="save()">{{ '🌐General.Save' | translate:lang }}</button>
      </nb-card-footer>
    </nb-card>
  </ng-container>
</div>