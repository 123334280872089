import * as deepmerge from 'deepmerge';
import { environmentDefaults, IEnvironment } from "./environment-defaults";
import { LanguageCode } from "../../shared-gen/Model/Localization/LanguageCode";

/**
 * Development environment for several smaller projects, starting in 2021-06 (portal.iovent.net).
 */
export const environment: IEnvironment = {
    ...environmentDefaults, 
    
    // Project settings
    projectName: "shared",
    defaultLanguage: LanguageCode.DE,

    testStage: "Dev",
    testWarning: "Shared Sandbox",
    
};
