
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NbMediaBreakpointsService, NbSidebarService } from "@nebular/theme";

@Component({
    selector: 'ngx-one-column-layout',
    styleUrls: ['./one-column.layout.scss'],
    template: `
    <nb-layout withScroll restoreScrollTop>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive start>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <!-- subheader xor fixed: https://github.com/akveo/nebular/issues/1305 -->
      <!--
      <nb-layout-header  subheader class="w-100 fixed-subheader-container">
        <nb-layout-header class="fixed-subheader" [style.width.px]="subheader_placeholder_ref?.nativeElement?.offsetWidth">
          <devicefilter class="d-none d-sm-block"></devicefilter>
        </nb-layout-header>
        <div #subheader_placeholder class="w-100" ></div>
      </nb-layout-header>
-->
      <!--
      <nb-layout-header subheader class="fixed-subheader" style="width: 100%; padding-left: 2.25em;">
      <devicefilter class="d-none d-sm-block"></devicefilter>
      </nb-layout-header>
      -->

      <nb-layout-column (click)="closeSideMenu()">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <!--
      <nb-layout-footer fixed class="d-sm-none fixed-bottom ">
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
      -->
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent implements AfterViewInit {
    constructor(private sidebarService: NbSidebarService,
        private breakpointsService: NbMediaBreakpointsService,
        private cd: ChangeDetectorRef) { }

    @ViewChild('subheader_placeholder')
    subheader_placeholder_ref: ElementRef; // HTMLElement

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    closeSideMenu() {
        //Collapse on small devices
        if (window.innerWidth < this.breakpointsService.getBreakpointsMap().sm) {
            this.sidebarService.collapse("menu-sidebar");
        }
    }
}
