import * as deepmerge from 'deepmerge';
import { environmentDefaults, IEnvironment } from "./environment-defaults";
import { LanguageCode } from 'shared-gen/Model/Localization/LanguageCode';

/**
 * Development environment for the sandbox project (eude-sandbox-vm-01.iovent.net).
 */
export const environment: IEnvironment = {
    ...environmentDefaults,

    // Project settings
    projectName: "tur", // same as T&R
    defaultLanguage: LanguageCode.DE,

    priceMinStepCents: 5,
    totalPriceMinStepCents: 10,

    testStage: "Dev",
    testWarning: "Sandbox",
};
