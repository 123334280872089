import { Component } from '@angular/core';

@Component({
    selector: 'ngx-two-columns-layout',
    styleUrls: ['./two-columns.layout.scss'],
    template: `
    <nb-layout windowMode>
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column class="small mobile-padding-bottom">
      </nb-layout-column>

      <nb-layout-column class="mobile-padding-bottom">
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed class="d-sm-none fixed-bottom ">
        <!--<ngx-footer></ngx-footer>-->
      </nb-layout-footer>

    </nb-layout>
  `,
})
export class TwoColumnsLayoutComponent { }
