import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";

@Component({
    selector: "ngx-confirm-dialog",
    templateUrl: "./confirm-dialog.component.html",
    styleUrls: ["./confirm-dialog.component.scss"]
})
export class ConfirmDialogComponent extends Localized {
    public title: string;
    public confirmMessage: string;

    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: {
                    title: string,
                    confirmMessage: string
                },
                translationService: TranslationService) {
        super(translationService);
        this.title = data.title;
        this.confirmMessage = data.confirmMessage;
    }
}
