import { Component, OnDestroy, OnInit } from "@angular/core";
import { NbDialogService, NbMediaBreakpointsService, NbMenuBag, NbMenuService, NbSidebarService, NbThemeService } from "@nebular/theme";

import { Router } from "@angular/router";
import { VersionDialogComponent } from "app/components/@dialogs/version-dialog/version-dialog.component";
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { environment } from "../../../../environments/runtime-environment";
import { LanguageCode } from "../../../../shared-gen/Model/Localization/LanguageCode";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";
import { User } from "../../../@core/interfaces/common/users";
import { UserStore } from "../../../@core/stores/user.store";
import { LayoutService } from '../../../@core/utils';

@Component({
    selector: "ngx-header",
    styleUrls: ["./header.component.scss"],
    templateUrl: "./header.component.html"
})
export class HeaderComponent extends Localized implements OnInit, OnDestroy {

    private destroy$: Subject<void> = new Subject<void>();
    userPictureOnly: boolean = false;
    user: User;

    themes: { value: string, name: string }[] = [
        {
            value: "default",
            name: "Light"
        },
        {
            value: "dark",
            name: "Dark"
        },
        {
            value: "cosmic",
            name: "Cosmic"
        },
        {
            value: "corporate",
            name: "Corporate"
        },
        {
            value: "iovent",
            name: "Iovent"
        }
    ];

    currentTheme = "iovent";

    userMenu = this.getMenuItems();
    getMenuItems() {
        let ret = [];
        if (this.user?.ioventUser?.ID) {
            ret.push({ title: this.translate("🌐General.Profile"), link: "/pages/users/detail/" + this.user.ioventUser.ID });
        }
        if (this.user?.ioventUser?.ProviderID == "tur") {
            ret.push({ title: "Nutzungsbedingungen", target: "_blank", url: "assets/tur/nutzungsbedingungen.pdf" });
            ret.push({ title: "Datenschutzerklärung", target: "_blank", url: "assets/tur/datenschutz.pdf" });
        }
        ret.push({ title: this.translate("🌐General.Help"), target: "_blank", url: this.createHelpUrl() });
        ret.push({ title: this.translate("🌐General.UpdateNews"), link: "/pages/changelog" });
        ret.push({ title: this.translate("🌐General.Version"), data: { id: "version" } });
        ret.push({ title: this.translate("🌐General.Logout"), link: "/auth/logout" });
        return ret;
    }

    env = environment();

    showThemeSelection = false;

    selectedLanguage: string = LanguageCode.DE;
    selectLanguages = environment().testStage == "Dev"
        ? [LanguageCode.DE, LanguageCode.EN, LanguageCode.LOCA]
        : [LanguageCode.DE, LanguageCode.EN];

    constructor(private sidebarService: NbSidebarService,
        private themeService: NbThemeService,
        private userStore: UserStore,
        private layoutService: LayoutService,
        private breakpointService: NbMediaBreakpointsService,
        private router: Router,
        private menuService: NbMenuService,
        private dialogService: NbDialogService,
        translationService: TranslationService) {
        super(translationService);
    }

    async ngOnInit() {
        this.currentTheme = this.themeService.currentTheme;

        this.user = this.userStore.getUser();
        this.userMenu = this.getMenuItems();

        const { xl } = this.breakpointService.getBreakpointsMap();
        this.themeService.onMediaQueryChange()
            .pipe(
                map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
                takeUntil(this.destroy$)
            )
            .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

        this.themeService.onThemeChange()
            .pipe(
                map(({ name }) => name),
                takeUntil(this.destroy$)
            )
            .subscribe(themeName => this.currentTheme = themeName);

        this.selectedLanguage = this.translationService.getLanguageCode();

        // Subscribe to MenuItemClick Event and filter for Version id in data
        this.menuService.onItemClick().subscribe((menuBag: NbMenuBag) => {
            if (menuBag.item.data && menuBag.item.data.id === 'version') {
                this.dialogService.open(VersionDialogComponent);
            }
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.destroy$.next();
        this.destroy$.complete();
    }

    createHelpUrl(): string {
        let url = "https://docs.iovent.net";
        if (environment().backendUrl.indexOf("//staging.") > -1)
            url = "https://staging.docs.iovent.net";
        else if (this.user?.ioventUser?.ProviderID == "tur")
            url = "https://docs.tur.iovent.net";
        // See repository "docs.iovent.net", file "access.js.raw".
        const now = new Date();
        const token = "" + (now.getFullYear() + 999) + "" + (now.getMonth() + 14 + 1) + "" + (now.getDate() + 11);
        return url + "?token=" + token;
    }

    changeTheme(themeName: string) {
        this.userStore.setSetting(themeName)
            .pipe(takeUntil(this.destroy$))
            .subscribe();

        this.themeService.changeTheme(themeName);
    }

    toggleSidebar(): boolean {
        this.sidebarService.toggle(true, "menu-sidebar");
        this.layoutService.changeLayoutSize();

        return false;
    }

    navigateHome() {
        this.router.navigate([""]);
        return false;
    }

    changeLanguage(languageCode: LanguageCode | string) {
        this.translationService.setLanguageCode(languageCode as any);
    }

    onLanguageChanged() {
        super.onLanguageChanged();
        // Update user context menu
        this.userMenu = this.getMenuItems();
    }

}
