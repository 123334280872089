import { LanguageCode } from '../../shared-gen/Model/Localization/LanguageCode';
import { ProductBase } from '../../shared-gen/Model/Products/ProductBase';
import { Product } from '../../shared-gen/Model/Products/Product';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Language } from '../model/localization/language';
import { LanguageFun } from '../model/localization/language.fun';
import { ProductFun } from '../model/products/product.fun';
import { LanguagePack } from '../../shared-gen/Model/Localization/LanguagePack';
import { LocalStorage } from '@ngx-pwa/local-storage';

/**
 * Translate given vocable IDs to the current or a given [LanguageCode].
 * The [LanguagePack] and current [LanguageCode] are managed by this class
 * and can be queried for changes.
 *
 * This service stores the selected language (set by [setLanguageCode])
 * permanently in the local storage.
 *
 * This service does not load the language pack. It must be set from outside
 * by calling [setLanguagePack].
 */
@Injectable({
    providedIn: 'root'
})
export class TranslationService {

    private readonly storageKey = 'languageCode';
    private readonly logErrors = false;

    constructor(private storage: LocalStorage) {
        this.language = new BehaviorSubject<Language>(LanguageFun.createNew());
        console.log("TranslationService initialized", this.current);
        this.init();
    }

    private async init() {
        const languageCode = (await this.storage.getItem<LanguageCode>(this.storageKey).toPromise());
        if (languageCode) {
            console.log("Loaded current language from storage: " + languageCode);
            this.setLanguageCode(languageCode as LanguageCode);
        }
    }

    /**
     * The currently selected language and the current language pack.
     * Can be observed for changes to update translated texts.
     */
    language: BehaviorSubject<Language>;

    private get current(): Language {
        return this.language.getValue();
    }

    /**
     * Returns the current language code.
     */
    getLanguageCode(): LanguageCode {
        return this.current.languageCode;
    }

    /**
     * Sets the language code to the given language as defined in BCP 47, e.g. "de" or "en-US".
     */
    setLanguageCodeFromBrowser(language: string) {
        const languageCode = language.split("-")[0].toUpperCase() as LanguageCode;
        this.setLanguageCode(languageCode);
    }

    /**
     * Sets the given language code as the current one and saves it
     * in the local storage.
     */
    setLanguageCode(languageCode: LanguageCode) {
        console.log("Set languageCode: " + languageCode, this.current);
        this.language.next({
            languageCode: languageCode,
            languagePack: this.current.languagePack,
            overrideKey: this.current.overrideKey
        });
        this.storage.setItem(this.storageKey, languageCode);
    }

    /**
     * Sets the given language pack.
     */
    setLanguagePack(languagePack: LanguagePack) {
        try {
            if (languagePack) {
                console.log("Set languagePack", this.current);
                this.language.next({
                    languageCode: this.current.languageCode,
                    languagePack: languagePack,
                    overrideKey: this.current.overrideKey
                });
            }
        } catch (err) { console.error(err); }
    }


    /**
       * Sets the given language override key.
       */
    setOverrideKey(overrideKey?: string) {
        console.log("Set overrideKey: " + overrideKey, this.current);
        this.language.next({
            languageCode: this.current.languageCode,
            languagePack: this.current.languagePack,
            overrideKey: overrideKey
        });
    }



    /**
     * Returns the translation of the given vocable ID in the given language (or current language), or
     * in English or German if it can not be found. If none is found,
     * the vocab ID itself is returned, but for real vocab IDs (starting with "🌐")
     * only the part after the last ".", e.g. "Pages" for ID "🌐General.Pages".
     * If "toHtml" is true, e.g. "\n" is replaced by "<br/>".
     */
    translate(vocabID: string, languageCode?: LanguageCode, toHtml = false): string {
        const foundVocabIds = vocabID?.match(/🌐[\d\w.]+/g) ?? [];
        const containsMultipleVocabIds = foundVocabIds.length > 1;
        if (containsMultipleVocabIds) {
            // if multiple keys are contained we need to translate each one
            let resultingString = vocabID;
            for(const foundVocabID of foundVocabIds) {
                const translatedText = this.translate(foundVocabID, languageCode, toHtml);
                resultingString = resultingString.replace(foundVocabID, translatedText);
            }
            return resultingString;
        }
        // Remove vocab marker from the ID
        const isRealID = vocabID.startsWith("🌐");
        if (isRealID)
            vocabID = vocabID.substring(2); // 🌐 is two chars

        // Look up vocab ID
        // TODO: keep or cleanup overrides
        //let vocab = undefined;
        //if(this.current.overrideKey)
        //  vocab = this.current.languagePack.KeyedOverrides?.[this.current.overrideKey]?.[vocabID];
        //if(!vocab)
        let vocab = this.current.languagePack.Vocabs[vocabID];

        if (languageCode == LanguageCode.LOCA)
            return (vocab == null ? "❌ " : "✅ ") + vocabID;
        if (vocab != null) {
            const languageCodes = this.getLanguageCodes(languageCode);
            for (const lc of languageCodes) {
                //console.log(vocabID + " " + this.current.overrideKey + " " + lc);
                let word = vocab.Translations[lc];
                if (word == null && lc == languageCodes[0] && this.logErrors)
                    console.error("Missing " + languageCodes[0] + " translation for: " + vocabID);
                if (word != null) {
                    // Replace escaped characters
                    word = word.replace(/\\n/g, "\n");
                    // Replace HTML
                    if (toHtml) {
                        word = word.replace(/\n/g, "<br/>");
                    }
                    return word;
                }
            }
        }
        if (this.logErrors)
            console.error("Missing all translations for: " + vocabID);
        // Not found at all.
        if (isRealID) {
            // Return part after last ".".
            const lastDotPos = vocabID.lastIndexOf('.');
            return vocabID.substring(lastDotPos + 1);
        }
        else {
            return vocabID;
        }
    }

    /**
     * Returns the translation of the given vocable ID in the given language (or current language), or
     * in English or German if it can not be found. The given Token/value pairs are replaced, e.g.
     * ["%value%, 50, "%currency%", "EUR"].
     * If "toHtml" is true, e.g. "\n" is replaced by "<br/>".
     */
    translateWithTokens(vocabID: string, tokens: any[], languageCode?: LanguageCode, toHtml = false): string {
        let ret = this.translate(vocabID, languageCode, toHtml);
        for (let i = 0; i < tokens.length - 1; i += 2)
            ret = ret.replace("" + tokens[i], "" + tokens[i + 1]);
        return ret;
    }

    /**
     * Translates the given property of the given product or base product
     * into the given language (or current language).
     * For example, when productID is "MyProd" and property is "Name",
     * then the vocable ID is "Product[MyProdID].Name".
     *
     * When there is no translation of the [Product]'s property, which is considered fine
     * and not an error, the translation of the [ProductBase]'s property is returned instead.
     * If still not found (which is an error), the same is tried for English and then German.
     * If still not found, the product's property value is returned.
     *
     * When a translation value of "~" is found, this means that the default
     * product property value should be used, e.g. the name stored in the object.
     */
    translateProduct(product: Product | ProductBase, property: 'Name' | 'Details', languageCode?: LanguageCode): string {
        const fallback = (product as any)[property] + ""; // Convert to string
        const baseID = ProductFun.getBaseID(product.ID);
        const languageCodes = this.getLanguageCodes(languageCode);
        for (const lc of languageCodes) {
            for (const p of [`Product[${product.ID}]`, `ProductBase[${baseID}]`]) {
                const vocabID = `${p}.${property}`;
                const vocabs = this.current.languagePack.Vocabs[vocabID];
                if (vocabs != null) {
                    const word = vocabs.Translations[lc];
                    if (word != null) {
                        if (lc == languageCodes[0] && this.logErrors)
                            console.error("Missing " + languageCodes[0] + " translation for: " + vocabID);
                        if (word == "~")
                            return fallback; // Use value of property instead of translation
                        else
                            return word;
                    }
                }
            }
        }
        if (this.logErrors)
            console.error("Missing all translations for product " + product.ID + ", property " + property);
        return fallback;
    }

    /**
     * Gets the given language code (or the current one, if null)
     * and EN and DE (if this is not already this language code).
     */
    private getLanguageCodes(languageCode?: LanguageCode): LanguageCode[] {
        let ret = [languageCode || this.current.languageCode];
        if (languageCode != LanguageCode.EN)
            ret.push(LanguageCode.EN);
        if (languageCode != LanguageCode.DE)
            ret.push(LanguageCode.DE);
        return ret;
    }



}
