import { Component, Input, OnInit } from "@angular/core";
import { DeviceOverview } from "../../../../shared-gen/Model/Devices/DeviceOverview";
import { Resource } from "../../../../shared-gen/Model/Products/Resource";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";

@Component({
    selector: "eversys-resources",
    templateUrl: "./eversys-resources.component.html",
    styleUrls: ["./eversys-resources.component.scss"]
})
export class EversysResourcesComponent extends Localized {

    _resources: Resource[];
    _supplies: { [key in Resource]: number }

    state = {
        "BeansRight": "green",
        "BeansLeft": "green",
        "Milk": "green",
        "BeansRightCount": 0,
        "BeansLeftCount": 0,
        "MilkCount": 0
    };

    @Input()
    set resources(r: Resource[]) {
        this._resources = r;
        this.init();
    }

    @Input()
    set supplies(s: { [key in Resource]: number }) {
        this._supplies = s;
        this.init();
    }



    constructor(translationService: TranslationService) {
        super(translationService);
    }

    init() {

        if (this._resources) {
            if (this._resources.indexOf(Resource.Milk) === -1) {
                this.state.Milk = "red";
            }

            if (this._resources.indexOf(Resource.BeansRight) === -1 && /* Obsolete: */ this._resources.indexOf(Resource.Espresso) === -1) {
                this.state.BeansRight = "red";
            }

            if (this._resources.indexOf(Resource.BeansLeft) === -1 && /* Obsolete: */ this._resources.indexOf(Resource.CafeCreme) === -1) {
                this.state.BeansLeft = "red";
            }
        }

        if (this._supplies) {

            if (this._supplies[Resource.Milk] && this._supplies[Resource.Milk] > 0) {
                this.state.MilkCount = this._supplies[Resource.Milk];
            }
            else {
                this.state.Milk = "red";
            }

            if (this._supplies[Resource.BeansRight] && this._supplies[Resource.BeansRight] > 0) {
                this.state.BeansRightCount = this._supplies[Resource.BeansRight];
            }
            else {
                this.state.BeansRight = "red";
            }

            if (this._supplies[Resource.BeansLeft] && this._supplies[Resource.BeansLeft] > 0) {
                this.state.BeansLeftCount = this._supplies[Resource.BeansLeft];
            }
            else {
                this.state.BeansLeft = "red";
            }

        }
    }
}
