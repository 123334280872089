import { ProductInstance } from 'shared-gen/Model/Products/ProductInstance';
import { ProductInstanceFun } from 'shared/model/products/product-instance.fun';
import { OptionChoice } from 'shared-gen/Model/Products/OptionChoice';
import { ProductType } from 'shared-gen/Model/Products/ProductType';
import { OptionFun } from 'shared/model/products/options.fun';
import { ProductBase } from 'shared-gen/Model/Products/ProductBase';
import { Option } from 'shared-gen/Model/Products/Option';
import { cloneDeep } from 'shared/utils/core-utils';
import { EversysProductGeneratorTheme } from './product-themes';


export interface EversysProductGenerationOptions {
  productPrefix: string,
  vat: number,
  providerId: string,
  theme: EversysProductGeneratorTheme
}
export const generateProductBase = (blueprintProductBase: ProductBase, desiredOptions: Option[], options: EversysProductGenerationOptions) => {
  const { productPrefix, providerId, vat, theme } = options;

  const productBaseId = (productPrefix ?? '') + blueprintProductBase.ID;
  const newProductBase: ProductBase = {
    ...cloneDeep(blueprintProductBase),
    ID: productBaseId,
    ProviderID: providerId,
    Hash: undefined,
    Options: desiredOptions,
    Image: theme[productBaseId] ?? blueprintProductBase.Image,
    Instances: generateProductInstances(blueprintProductBase, desiredOptions, productBaseId, vat)
  }
  return newProductBase;
}

const generateProductInstances = (blueprintProductBase: ProductBase, desiredOptions: Option[], newProductBaseID: string, vat: number) => {
  if (desiredOptions.length === 0) {
    const productInstance = generateProductInstance(blueprintProductBase, [], newProductBaseID, vat);
    return [productInstance];
  }
  const allOptionChoiceCombinations = determineAllChoiceCombinations(desiredOptions);
  const productInstances: ProductInstance[] = [];
  for (const optionChoiceCombination of allOptionChoiceCombinations) {
    productInstances.push(generateProductInstance(blueprintProductBase, optionChoiceCombination, newProductBaseID, vat));
  }
  
  return productInstances;
}

const generateProductInstance = (blueprintProductBase: ProductBase, optionChoices: OptionChoice[], newProductBaseID: string, vat: number) => {
  const suitableProductInstance = findSuitableProductInstanceFromBlueprint(blueprintProductBase, optionChoices);
  const sizeOptionChoice = optionChoices.find(optionChoice => optionChoice.OptionID === 'Size');
  const productInstance: ProductInstance = {
    ID: ProductInstanceFun.buildProductInstanceId(newProductBaseID, optionChoices),
    Name: blueprintProductBase.Name + (sizeOptionChoice ? ' ' + sizeOptionChoice.ChoiceName : ''),
    LocalizedNames:blueprintProductBase.LocalizedNames,
    ProductType: ProductType.Eversys,
    Price: suitableProductInstance?.Price ?? 0,
    Deposit: suitableProductInstance?.Deposit ?? 0,
    Resources: suitableProductInstance?.Resources ?? [],
    SelectionNumber: suitableProductInstance.SelectionNumber,
    ServingTime: suitableProductInstance.ServingTime,
    Vat: vat
  };
  return productInstance;
}

/**
 * Tries to find a suitable product instance from given product base that matches the desired option choices.
 * It filters by option choice one after another and determines a list of matching instances. It tries to find
 * the most specific instances and returns an arbitrary one
 */
const findSuitableProductInstanceFromBlueprint = (productBase: ProductBase, optionChoices: OptionChoice[]): ProductInstance | undefined => {
  let lastKnownMatches = productBase.Instances;
  for(const optionChoice of optionChoices) {
    const nextMatches = lastKnownMatches.filter(productInstance => {
      const resolvedOptionChoices = ProductInstanceFun.resolveOptionChoices(productBase.Options, productInstance.ID);
      return resolvedOptionChoices.some(productInstanceOptionChoice => productInstanceOptionChoice.OptionID === optionChoice.OptionID && productInstanceOptionChoice.ChoiceID === optionChoice.ChoiceID);
    });
    if (nextMatches.length > 0) {
      lastKnownMatches = nextMatches;
    }
  }
  return lastKnownMatches[0];
}

/**
 * Determines all possible combinations of given options. One combination is an array of OptionChoice.
 * All combinations is an array of combinations.
 */
const determineAllChoiceCombinations = (options: Option[]): OptionChoice[][] => {
  // if only one option exists then the result is just the list of choices
  if (options.length === 0) {
      return [];
  }

  // create a copy
  const followingOptions = [...options];
  // remove first option. Be aware that splice returns the deleted elements and modifies the followingOptions array in place
  const [firstOption] = followingOptions.splice(0,1);
  // get all following option choices
  const followingCombinations = determineAllChoiceCombinations(followingOptions);
  // combine this option choices with all following option choice combinations
  const resultingOptionChoices: OptionChoice[][] = [];
  for(const choice of firstOption.Choices) {
      const optionChoice: OptionChoice = OptionFun.createOptionChoice(firstOption, choice);
      if (followingCombinations.length === 0) {
        resultingOptionChoices.push([optionChoice])
      }
      for (const combination of followingCombinations) {
          resultingOptionChoices.push([optionChoice, ...combination]);
      }
  }
  return resultingOptionChoices;
}
