import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { DataService, ListOptions } from "../../services/data.service";
import { StorageService } from "../../services/storage.service";
import { Location } from "../../../shared-gen/Model/Locations/Location";
import { Device } from "../../../shared-gen/Model/Devices/Device";
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";

interface ResultType {
    Locations: Location[] | string[],
    Devices: Device[] | string[],
}

@Component({
    selector: "ngx-filter",
    templateUrl: "./filter.component.html",
    styleUrls: ["./filter.component.scss"]
})

export class FilterComponent extends Localized implements OnInit, OnDestroy {

    subscription: Subscription = new Subscription();

    @Output() onChange: EventEmitter<ResultType> = new EventEmitter();

    @Output() onFilter: EventEmitter<ResultType> = new EventEmitter();

    @Input() resultType: "Objects" | "IDs" = "IDs";
    @Input() respectDeviceFilter: boolean = false;

    chosenLocations: Location[] = [];
    chosenDevices: Device[] = [];

    locations: Location[];
    devices: Device[] = [];

    quickLoc: Location;
    quickDev: Device;

    constructor(protected dataService: DataService,
        protected storageService: StorageService,
        translationService: TranslationService) {
        super(translationService);
        this.subscription.add(this.storageService.filter.subscribe(it => {
            if (it != null) {
                console.log("filter: " + it);
                this.init();
            }
        }));
    }

    ngOnInit() {
        this.init();
    }

    init() {

        //this.quickLoc = this.storageService.getLocation();
        if (this.quickLoc) {
            this.chosenLocations = [
                this.quickLoc
            ];
        }
        //this.quickDev = this.storageService.getDevice();
        if (this.quickDev) {
            //this.quickDev = dev;
            this.chosenDevices = [
                this.quickDev
            ];
        }

        this.load();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    async load() {
        const data = await this.dataService.getLocations(
            <ListOptions>{
                OrderBy: "ID",
                OrderAsc: true
            },
            {},
            "unfiltered"
        );
        this.locations = data;
        await this.loadDevices();
    }

    async loadDevices() {
        const data = await this.dataService.getDevices(
            <ListOptions>{
                OrderBy: "ID",
                OrderAsc: true
            },
            {
                LocationID: this.chosenLocations.map((location) => location.ID)
            },
            "unfiltered"
        );
        this.devices = data;
        this.chosenDevices = this.chosenDevices.filter(it => {
            return this.devices.findIndex(dev => {
                return dev.ID === it.ID;
            }) !== -1;
        });
    }

    isChosenLocation(location: Location) {
        return this.chosenLocations.findIndex(it => {
            return it.ID === location.ID;
        }) !== -1;
    }

    async chooseLocation(location: Location) {
        const index = this.chosenLocations.findIndex(it => {
            return it.ID === location.ID;
        });
        if (index !== -1) {
            this.chosenLocations.splice(index, 1);
        } else {
            this.chosenLocations.push(location);
        }

        await this.loadDevices();
        this.emitOnChange();
    }

    async chooseAllLocations(e) {
        if (e.srcElement.checked) {
            this.chosenLocations = this.locations.map(location => location);
        } else {
            this.chosenLocations = this.respectDeviceFilter && this.quickLoc ? [this.quickLoc] : [];

        }
        await this.loadDevices();
        this.emitOnChange();
    }

    isChosenDevice(device: Device) {
        return this.chosenDevices.findIndex(it => {
            return it.ID === device.ID;
        }) !== -1;
    }

    chooseDevice(device: Device) {
        const index = this.chosenDevices.findIndex(it => {
            return it.ID === device.ID;
        });
        if (index !== -1) {
            this.chosenDevices.splice(index, 1);
        } else {
            this.chosenDevices.push(device);
        }
        this.emitOnChange();
    }

    async chooseAllDevices(e) {
        if (e.srcElement.checked) {
            this.chosenDevices = this.devices.map(location => location);
        } else {
            this.chosenDevices = this.respectDeviceFilter && this.quickDev ? [this.quickDev] : [];
        }
        this.emitOnChange();
    }

    getResult(): ResultType {
        if (this.resultType === "IDs") {
            return {
                Locations: this.chosenLocations.map(location => location.ID),
                Devices: this.chosenDevices.map(device => device.ID)
            };
        } else {
            return {
                Locations: this.chosenLocations,
                Devices: this.chosenDevices
            };
        }
    }

    emitOnChange() {
        this.onChange.emit(this.getResult());
    }

    emitOnFilter() {
        this.onFilter.emit(this.getResult());
    }

}
