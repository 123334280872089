import { Component } from "@angular/core";
import { DataService } from "../../../../../../services/data.service";
import { MatDialogRef } from "@angular/material/dialog";
import { GuiService } from "../../../../../../services/gui.service";
import { ProductBase } from "../../../../../../../shared-gen/Model/Products/ProductBase";
import { Option } from "../../../../../../../shared-gen/Model/Products/Option";
import { getDefaultID } from "../../../../../../../shared/core";
import { Choice } from "../../../../../../../shared-gen/Model/Products/Choice";
import { Localized } from "../../../../../../../shared/localized";
import { TranslationService } from "../../../../../../../shared/services/translation.service";
import { cleanID } from "app/@models/core.fun";

@Component({
    selector: "ngx-product-option",
    templateUrl: "./product-option.component.html",
    styleUrls: ["./product-option.component.scss"]
})
export class ProductOptionComponent extends Localized {

    obj: Option | any;
    data: ProductBase;
    validID: boolean = true;
    disableClose: boolean = false;

    constructor(private dataService: DataService,
        public dialogRef: MatDialogRef<ProductOptionComponent>,
        private guiService: GuiService,
        translationService: TranslationService) {
        super(translationService);
    }

    ngOnInit() {
        this.obj.OldID = this.obj.ID;
    }

    async checkID() {
        let valid = false;
        if (this.obj.ID === this.obj.OldID) valid = true;
        if (this.data.Options.findIndex(it => {
            return it.ID === this.obj.ID;
        }) === -1) valid = true;

        this.validID = valid;
        return valid;
    }

    sanitizePossibiltyIds() {
        if (this.obj) {
            this.obj.Choices.forEach((choice: Choice) => {
                choice.ID = cleanID(choice.ID);
            });
        }
    }

    async save() {
        if (await this.checkID() && this.obj.Name !== "") {
            let ids: string[] = [];
            for (let key in this.obj.Choices) {
                const choice = this.obj.Choices[key];
                //remove empty choices or ID exists!
                let del = ids.indexOf(choice.ID) !== -1;
                ids.push(choice.ID);
                if (!choice.ID || del) {
                    this.obj.Choices.splice(key, 1);
                }
            }
            if (this.obj.Choices.length > 0) {
                this.dialogRef.close(this.obj);
            }
        }
    }

    addChoice() {
        this.obj.Choices.push({
            ID: getDefaultID(),
            Name: ""
        });
    }

    deleteChoice(choice: Choice) {
        if (this.data.Instances.findIndex(it => {
            return it.ID === `${this.data.ID}-${choice.ID}`;
        }) === -1) {

            const key = this.obj.Choices.findIndex(it => {
                return it.ID === choice.ID;
            });
            this.obj.Choices.splice(key, 1);
        } else {
            this.guiService.showToast(this.translate("🌐Products.Option.ChoiceInUse"), this.translate("🌐Products.Option.Failed"), "danger");
        }
    }
}
