import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/runtime-environment";

/**
 * API controller for the TuR API extensions.
 */
@Injectable({
    providedIn: 'root'
})
export class TurApiService {

    get baseUrl(): string {
        return environment().backendUrl + "/extensions/tur";
    }

    constructor(protected http: HttpClient) {
    }

    /**
     * Returns true, if the authenticated user has to accept new terms of service.
     */
    async isNewTermsOfServiceVersion(): Promise<boolean> {
        return await this.http.get<boolean>(`${this.baseUrl}/new-tos-version`).toPromise();
    }

    /**
     * Accepts the current terms of service for the authenticated user.
     */
    async acceptTermsOfService(): Promise<void> {
        return await this.http.post<void>(`${this.baseUrl}/accept-tos`, null).toPromise();
    }

}
