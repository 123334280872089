import { Option } from "shared-gen/Model/Products/Option";
import { OptionChoice } from "shared-gen/Model/Products/OptionChoice";
import { OptionFun } from "./options.fun";

export class ProductInstanceFun {

    static isValidID(id: string): boolean {
        return !id.includes('-?');
    }

    /**
     * Extracts the option choices from the product instance id. E.g. Cappu-S-H with options: Size and Milk resolves to
     * Size S, MilkSort Hafer
     * @param options 
     * @param productInstanceId 
     * @returns 
     */
    static resolveOptionChoices(options: Option[], productInstanceId: string): OptionChoice[] {
        const productInstanceChoiceIds = productInstanceId.split('-').slice(1); // slice removes product base name
        const optionChoices: OptionChoice[] = options.map((option, optionIndex) => {
            const choiceId = productInstanceChoiceIds[optionIndex];
            const choice = option.Choices.find(it => it.ID === choiceId);
            if (!choice) {
                throw new Error(`cannot create OptionChoice without choice. Didn't find choice ${choiceId} in option ${option.ID}`);
            }
            return OptionFun.createOptionChoice(option, choice, optionIndex);
        });
        return optionChoices;
    }

    /**
     * constructs a product instance id for a given products base name and all it's option choices.
     * E.g. Cappu of Size S and milk sort Hafer resolved to: Cappu-S-H
     * 
     * @param productBaseName
     * @param optionChoices 
     * @returns 
     */
    static buildProductInstanceId(productBaseName: string, optionChoices: OptionChoice[]) {
        if (!optionChoices?.length) {
            return productBaseName;
        }
        return `${productBaseName}-${optionChoices.map(optionChoice => optionChoice.ChoiceID).join('-')}`;
    }

}
