import { LanguageCode } from '../../../shared-gen/Model/Localization/LanguageCode';
import { Language } from './language';

export class LanguageFun {

    static createNew(): Language {
        return {
            languageCode: LanguageCode.DE,
            languagePack: {
                Vocabs: {},
                //  KeyedOverrides: {}
            },
            overrideKey: null
        }
    }

}
