<ng-container *ngFor="let state of states">
    <ng-container *ngIf="count[state] > 0; else elseBlock">
        <span [nbTooltip]="productIDs[state]">
            <span class="number">{{count[state]}}</span>
            <span [class]="'circle ' + state"></span>
        </span>
    </ng-container>
    <ng-template #elseBlock>
        <span class="number grayNumber">0</span>
        <span class="circle gray"></span>
    </ng-template>
</ng-container>
