<ul>
    <!-- Doors -->
    <ng-container *ngFor="let door of getAllDoors()">
        <li *ngIf="isDoorOpen(door)">
            {{"🌐Component." + door | translate:lang}}
            {{"🌐Verb.Opened" | translate:lang | lowercase}}</li>
    </ng-container>

    <!-- Payment status -->
    <ng-container *ngIf="isChangeLow()">
        <li>{{"🌐PaymentStatus.IsChangeLow" | translate:lang}}</li>
    </ng-container>
    <ng-container *ngIf="!isOfflineVoucherAccepted()">
        <li>{{"🌐PaymentStatus.IsOfflineVoucherAccepted-False" | translate:lang}}</li>
    </ng-container>
    <ng-container *ngFor="let peripheral of getAllPeripherals()">
        <ng-container *ngIf="isPeripheralNotOnline(peripheral)">
            <li>{{"🌐Component." + peripheral | translate:lang}}: {{"🌐Verb.OutOfOrder" | translate:lang}}</li>
        </ng-container>
    </ng-container>

</ul>
