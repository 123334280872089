/**
* State of the vending machine controller.
*             The VMC acts like a finite automaton with the states defined here.
*             These states are based on the <see cref="T:Iovent.Machine.Status.OpState" /> of the machine controller,
*             but contain additional states for the vending process.
*/
export enum VmcState {
    /**
    * The VMC is initializing itself, e.g. while the machine is starting or heating,
    *             or while the payment peripherals are initialized.
    *             The screen should show a "Please wait..." message.
    */
    Initializing = "Initializing",
    /**
    * The machine is cleaning itself or preparing to clean itself
    *             (internal countdown for cleaning). A warning message must be shown
    *             on the screen, e.g. "Cleaning... Caution! Hot water!".
    */
    Cleaning = "Cleaning",
    /**
    * The machine is online and ready to serve products.
    *             When a product is ordered, we switch into the <see cref="F:Iovent.Vending.Status.VmcState.Paying" /> state
    *             if there is not enough credit yet. Otherwise we switch into the <see cref="F:Iovent.Vending.Status.VmcState.CupPlacing" />
    *             state, if no cup was detected yet, or directly into the <see cref="F:Iovent.Vending.Status.VmcState.Serving" /> state
    *             when both enough credit and the cup are detected.
    */
    Ready = "Ready",
    /**
    * One or multiple products was ordered but the full price was not paid yet.
    *             We are waiting for payment. In this state, it is not possible any more
    *             to change the cart (instead, cancel the order before).
    *             As soon as enough money was deposited
    *             (or when already paid when ordering the product) we switch into
    *             the <see cref="F:Iovent.Vending.Status.VmcState.CupPlacing" /> state, or, if the cup is detected already,
    *             directly into the <see cref="F:Iovent.Vending.Status.VmcState.Serving" /> state.
    */
    Paying = "Paying",
    /**
    * A product was ordered and paid. Now we are waiting for a cup to be
    *             placed under the machine's tap. When a cup is detected (or the user
    *             presses a button to continue), we switch into the <see cref="F:Iovent.Vending.Status.VmcState.Serving" /> state.
    */
    CupPlacing = "CupPlacing",
    /**
    * The machine is currently serving a product and (if any)
    *             change money is returned to the customer.
    *             The screen should show a "Product serving..." and
    *             (if <see cref="P:Iovent.Vending.Status.VendingStatus.ShowRemoveChange" /> is set)
    *             a "Please remove change" message.
    */
    Serving = "Serving",
    /**
    * The product was served successfully and the change money (if any) was returned.
    *             The screen could show something like "Bon appétit!" for example.
    *             After a given time, we will switch back to <see cref="F:Iovent.Vending.Status.VmcState.Ready" /> state.
    */
    Served = "Served",
    /**
    * At least one product could not be served and the price should be refunded.
    *             In this case, also a refund message should be displayed on the screen.
    *             This state is active at least a certain amount of time until the machine
    *             decides if it can continue (<see cref="F:Iovent.Vending.Status.VmcState.Ready" />) or if intervention by the
    *             service staff is required (<see cref="F:Iovent.Vending.Status.VmcState.Intervention" />).
    */
    Failed = "Failed",
    /**
    * After a certain amount of time when no payment change was detected and no order
    *             was started, the VMC switches from the <see cref="F:Iovent.Vending.Status.VmcState.Ready" /> state
    *             into the inactive state, which results in the
    *             cancellation of the current order and pay back of prepaid money.
    */
    Inactive = "Inactive",
    /** A <see cref="T:Iovent.Events.CashBalance" /> is getting created. */
    CashBalance = "CashBalance",
    /**
    * The machine can not serve products right now, because something has to be
    *             fixed, maybe by the service staff (e.g. refill coffee beans in a coffee machine).
    *             The screen should show a message like "Please call service staff!" for example.
    *             The controller tries to leave this state automatically, if the machine
    *             status allows it.
    */
    Intervention = "Intervention",
    /**
    * The machine is in maintenance mode, i.e. a service technician is currently working
    *             on the machine and no customer interaction is possible. This mode can for example
    *             be entered when a door of the machine is opened, and can be left, when the door
    *             is closed and/or the technician confirmed to leave the maintenance mode.
    */
    Maintenance = "Maintenance"
}
