export enum DoorVerb {
    /**
    * A door can be opened.
    *             Antonym of <see cref="F:Iovent.Model.Events.DoorVerb.Closed" />.
    */
    Opened = "Opened",
    /**
    * A door can be closed.
    *             Antonym of <see cref="F:Iovent.Model.Events.DoorVerb.Opened" />.
    */
    Closed = "Closed"
}
