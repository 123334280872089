<nb-card class="nb-nested-card" *ngIf="hasRights">
    <nb-card-header class="d-flex justify-content-between flex-wrap">
        <span class="px-3 pb-2">{{"🌐General.Log" | translate:lang}}</span>
        <div class="mt-1 px-3 d-flex justify-content-between flex-wrap">
            <nb-checkbox class="pr-3" status="info" [(ngModel)]="isStreaming" (change)="changedStreaming()">
                {{"🌐General.Streaming" | translate:lang}}</nb-checkbox>
            <span class="pr-3 streaming-process">{{streamingProcess}}</span>
            <nb-select class="pr-3" size="small" status="info" [(selected)]="count" (selectedChange)="reload()">
                <nb-option [value]="10">10</nb-option>
                <nb-option [value]="100">100</nb-option>
                <nb-option [value]="1000">1000</nb-option>
            </nb-select>
            <button class="mx-3" nbButton size="small" status="info" [disabled]="isStreaming"
                (click)="reload()">{{"🌐General.Reload" | translate:lang}}</button>
        </div>
    </nb-card-header>
    <nb-card-body class="py-3" style="overflow-y: none;">
        <div [ngClass]="{'log-frame': true, '--streaming': isStreaming }">
            <div class="message" *ngIf="state == 'Loading'">{{"🌐General.Loading" | translate:lang}}...</div>
            <div class="message" *ngIf="state == 'Error'">{{"🌐General.Error" | translate:lang}}</div>
            <ng-container *ngIf="state == 'Loaded'">
                <div class="log-entries">
                    <p *ngFor="let entry of logEntries">
                        <nb-icon [icon]="getIcon(entry)" [status]="getStatus(entry)"></nb-icon> <span class="py-3">
                            {{entry.TimeStamp | localDate }}</span> {{entry.Mode}} {{entry.Origin}} {{entry.Message}}
                    </p>
                </div>
            </ng-container>
        </div>
    </nb-card-body>
</nb-card>
