<nb-card>

    <nb-card-header>
        {{("🌐Update.Edit" | translate:lang).replace("%entity-type%", '🌐EntityType.' + entityType | translate:lang)}}
    </nb-card-header>

    <nb-card-body>
        <p>{{("🌐Update.FollowingEntries" | translate:lang).replace("%entity-type%", '🌐EntityType.' + entityType |
            translate:lang)}}</p>
        <p>{{"🌐Update.AutoApply" | translate:lang}}</p>

        <ng-container *ngFor="let deps of dependencies.Entities | keyvalue">
            <ng-container *ngIf="deps.value.length > 0">
                <h4>{{"🌐EntityType." + deps.key | translate:lang}}</h4>
                <ul [class]="deps.value.length > 10 ? 'multicolumn' : ''">
                    <li *ngFor="let ent of deps.value">{{ent.Name}} ({{ent.ID}})</li>
                </ul>
            </ng-container>
        </ng-container>
    </nb-card-body>

    <nb-card-footer class="d-flex">
        <button nbButton status="success" (click)="dialogRef.close(true)" class="w-100 mr-2">{{"🌐Update.Yes" |
            translate:lang}}</button>
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-100 ml-2">{{"🌐Update.No" |
            translate:lang}}</button>
    </nb-card-footer>

</nb-card>
