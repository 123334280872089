import { VmcState } from "../../../shared-gen/Vending/Status/VmcState";
import { getDefaultID, StatusColor } from "../../core";
import { Device } from "shared-gen/Model/Devices/Device";
import { EntityType } from "shared-gen/Model/Utils/EntityType";
import { LanguageCode } from "shared-gen/Model/Localization/LanguageCode";
import { MenuFun } from "shared/model/menus/menu.fun";

/**
 * Gets the status color for the given VendingStatus.
 * If none is given, red is returned.
 */
export function getDeviceStatusColor(vmcState: VmcState, problems: any[]): StatusColor {
    if (vmcState == null)
        return 'gray';
    switch (vmcState) {
        case 'Initializing':
        case 'Paying':
        case 'Ready':
        case 'CupPlacing':
        case 'Serving':
        case 'Served':
        case 'Inactive':
        case 'CashBalance':
            if (problems && problems.length > 0)
                return 'greenyellow';
            else
                return 'green'
        case 'Cleaning':
            if (problems && problems.length > 0)
                return 'greenyellow';
            else
                return 'greenyellow'
        case 'Failed':
            if (problems && problems.length > 0)
                return 'yellowred';
            else
                return 'greenred';
        case 'Intervention':
            return 'orange';
        default:
            return 'green';
    }
}


export class DeviceFun {

    static createNew(deviceID: string, providerID: string): Device {
        return {
            Type: EntityType.Device,
            ID: deviceID,
            ProviderID: providerID,
            Name: "",
            LicenseKey: "",
            LocationID: "",
            LocationInfo: {
                Type: EntityType.Location,
                ID: "",
                Name: "",
                ProviderID: providerID,
                ParentID: ""
            },
            Menu: MenuFun.createNew(""),
            StartZoneDate: "2021-01-01T00:00:00",
            Activated: false,
            ActivationDate: "",
        };
    }

    /** Returns true iff a device / device config can be saved */
    static canBeSaved(device: Device | undefined): boolean {
        if (device?.Name == "") return false;
        if (device?.LocationInfo.ID == getDefaultID()) return false;
        if (device?.Config && device?.Config.ID === getDefaultID()) return false;
        if (device?.Slave && device?.Slave.Device.ID === getDefaultID()) return false;
        if (device?.Slave?.Menu && device?.Slave.Menu.ID === getDefaultID()) return false;
        return true;
    }

}
