import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LanguageCode } from 'shared-gen/Model/Localization/LanguageCode';
import { LocalizedString } from 'shared-gen/Model/Utils/LocalizedString';
import { Localized } from 'shared/localized';
import { TranslationService } from 'shared/services/translation.service';

@Component({
  selector: 'localized-html-input',
  templateUrl: './localized-html-input.component.html',
  styleUrls: ['./localized-html-input.component.scss']
})
export class LocalizedHtmlInputComponent extends Localized implements OnInit, OnChanges {

  /**
   * Given "LocalizedString" object that is getting filled by this input.
   */
  @Input()
  localizedHtml: LocalizedString;
  @Output()
  localizedHtmlChange: EventEmitter<LocalizedString> = new EventEmitter<LocalizedString>();

  /**
   * The non-localized field value. E.g. Name (in contracts to LocalizedNames)
   */
  @Input()
  value: string;
  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  disabled: boolean = false;

  allLanguages: LanguageCode[] = Object.values(LanguageCode);

  isExpanded = false;

  constructor(translationService: TranslationService) {
    super(translationService);
  }

  ngOnInit(): void {
    if (!this.localizedHtml) {
      this.localizedHtml = {};
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.localizedHtml && !this.localizedHtml) {
      this.localizedHtml = {};
    }
  }

  onExpandButtonClicked() {
    this.isExpanded = !this.isExpanded;
  }

  getFlagUrl(languageCode: LanguageCode) {
    return 'url(/assets/images/flags/' + languageCode.toLowerCase() + '.png)';
  }

  getNumberOfFilledTranslations() {
    const validTranslations = this.allLanguages.map(languageCode => this.localizedHtml[languageCode]).filter(translationText => !!translationText);
    return validTranslations.length;
  }

  onValueChange() {
    this.valueChange.emit(this.value);
  }
  onLocalizedHtmlChange() {
    this.localizedHtmlChange.emit(this.localizedHtml);
  }

}
