import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Localized } from "shared/localized";
import { TranslationService } from "shared/services/translation.service";
import { Product } from "shared-gen/Model/Products/Product";
import { PortalLocationTableItem } from "shared-gen/Model/Locations/Views/PortalLocationTableItem";
import { LocationResourceStatus } from '../../../../shared-gen/Model/LocationResourceStatus';
import { ProductBaseFun } from "shared/model/products/product-base.fun";
import { SupplyOrder } from "shared-gen/Model/SupplyOrder";
import { ProductInstance } from "shared-gen/Model/Products/ProductInstance";


type Mode = "add" | "set" | "order";

@Component({
    selector: "ngx-supplies-dialog",
    templateUrl: "./supplies-dialog.component.html",
    styleUrls: ["./supplies-dialog.component.scss"]
})
export class SuppliesDialogComponent extends Localized {

    resourceProducts: Product[];

    getResourceProduct(id: string): Product {
        if (this.resourceProducts) {
            for (let p of this.resourceProducts) {
                if (p.ID == id)
                    return p;
            }
        }
        return null;
    }

    getResourceProductName(id: string): string {
        return this.getResourceProduct(id)?.Name ?? this.translationService.translate(id); // TODO: set language ?
    }

    getResourceProductUnit(id: string): string {
        return ProductBaseFun.smallResourceUnitFromProduct(this.getResourceProduct(id));
    }

    locationTableItem: PortalLocationTableItem = null;
    supplyStatus: LocationResourceStatus = null;
    supplyOrder: SupplyOrder = null;

    lines: { product: Product, amount: number }[] = [];

    _mode: Mode = "add";
    get mode(): Mode {
        return this._mode;
    }
    set mode(m: Mode) {
        this._mode = m;

        if (this._mode == "set") {
            this.lines = [];
            // TODO: pre-add all resources configured at devices of this location, not only already tracked resources
            for (const key in this.supplyStatus?.CurrentResources) {
                let product: Product = this.getResourceProduct(key);
                if (product && !this.lines.find(it => it.product == product)) {
                    this.lines.push({ product: product, amount: Math.round(this.supplyStatus.CurrentResources[key]) });
                }
            }
        }
        else if (this._mode == "add") {
            // TODO: pre-add all resources configured at devices of this location, not only already tracked resources
            for (const key in this.supplyStatus?.CurrentResources) {
                let product: Product = this.getResourceProduct(key);
                if (product && !this.lines.find(it => it.product == product)) {
                    this.lines.push({ product: product, amount: 0 });
                }
            }
        }
        else if (this._mode == "order") {
            this.lines = [];
            for (const key in this.supplyOrder?.OrderedResources) {
                let product: Product = this.getResourceProduct(key);
                if (product && !this.lines.find(it => it.product == product)) {
                    this.lines.push({ product: product, amount: Math.round(this.supplyOrder?.OrderedResources[key]) });
                }
            }
        }

    }

    supplySetDate: Date = new Date();

    get now(): Date {
        return new Date();
    }

    minTimedStartZoneDate = new Date("2021-10-01"); // TODO ?

    constructor(public dialogRef: MatDialogRef<SuppliesDialogComponent>, translationService: TranslationService) {
        super(translationService);
    }

    initalize() {

        if (this.supplyOrder) {
            this.mode = "order";
        }
        else if (!this.supplyStatus || this.supplyStatus.LastInventoryZoneDate.includes('9999')) {
            this.mode = "set";
        }

        if (this.lines.length == 0) {
            this.addLine();
        }

        console.log("dialog:");
        console.log(this.resourceProducts);

    }

    addLine() {
        this.lines.push({ product: null, amount: 0 });
    }

    confirm() {
        this.dialogRef.close({ lines: this.lines, mode: this.mode, date: this.supplySetDate });
    }

}
