<nb-card>
    <nb-card-header>
        <h5>{{"🌐General.TableHelp.TableHelpTitle" | translate:lang}}</h5>
    </nb-card-header>
    <nb-card-body>


        <div class="row spacing">
            <div class="col">
                <h6>
                    <nb-icon icon="checkmark-square-2-outline"></nb-icon> {{"🌐General.TableHelp.TableFilters" |
                    translate:lang}}
                </h6>
                <img id="explain-picture-dropdown" src="../../../../assets/images/help/dropdown_filtering.png" alt="">
                <table>
                    <tbody>
                        <tr>
                            <td class="numbering">&#x2780;</td>
                            <td>{{"🌐General.TableHelp.Dropdown" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2781;</td>
                            <td>{{"🌐General.TableHelp.Date" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2782;</td>
                            <td>{{"🌐General.TableHelp.FreeTextSearch" | translate:lang}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row spacing">
            <div class="col">
                <div>
                    <h6>
                        <nb-icon icon="flip-outline"></nb-icon> {{"🌐General.TableHelp.Sorting" | translate:lang}}
                    </h6>
                    <p style="margin: 0;">
                        {{"🌐General.TableHelp.SortingDetail" | translate:lang}}
                    </p>
                </div>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <nb-icon icon="chevron-up-outline"></nb-icon>
                            </td>
                            <td>{{"🌐General.TableHelp.SortAsc" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td>
                                <nb-icon icon="chevron-down-outline"></nb-icon>
                            </td>
                            <td>{{"🌐General.TableHelp.SortDesc" | translate:lang}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row spacing">
            <div class="col">
                <h6>
                    <nb-icon icon="funnel-outline"></nb-icon> {{"🌐General.TableHelp.ColumnFilters" | translate:lang}}
                </h6>
                <table>
                    <tbody>
                        <tr>
                            <td class="numbering">&#x2780;</td>
                            <td>{{"🌐General.TableHelp.ActivateFilter" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2781;</td>
                            <td>{{"General.TableHelp.FilterOperators" | translate:lang}}:</td>
                        </tr>
                        <tr class="operator">
                            <td>~</td>
                            <td>{{"🌐General.TableHelp.Approximately" | translate:lang}}</td>
                        </tr>
                        <tr class="operator">
                            <td>!~</td>
                            <td>{{"🌐General.TableHelp.NotApproximately" | translate:lang}}</td>
                        </tr>
                        <tr class="operator">
                            <td>=</td>
                            <td>{{"🌐General.TableHelp.Equals" | translate:lang}}</td>
                        </tr>
                        <tr class="operator">
                            <td>!=</td>
                            <td>{{"🌐General.TableHelp.NotEquals" | translate:lang}}</td>
                        </tr>
                        <tr class="operator">
                            <td>&lt;</td>
                            <td>{{"🌐General.TableHelp.LessThan" | translate:lang}}</td>
                        </tr>
                        <tr class="operator">
                            <td>&gt;</td>
                            <td>{{"🌐General.TableHelp.GreaterThan" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2782;</td>
                            <td>{{"🌐General.TableHelp.ValueEntry" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2783;</td>
                            <td>{{"🌐General.TableHelp.RemoveFilter" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2784;</td>
                            <td>{{"🌐General.TableHelp.AddFilter" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2785;</td>
                            <td>{{"🌐General.TableHelp.FilterColumnOp" | translate:lang}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col">
                <img id="explain-picture-filter" src="../../../../assets/images/help/add_remove_filter.png" alt="">
            </div>
        </div>

        <div class="row">
            <div class="col">
                <h6>
                    <nb-icon icon="edit-outline"></nb-icon> {{"🌐General.TableHelp.EditColumns" | translate:lang}}
                </h6>
                <img id="explain-picture-columns" src="../../../../assets/images/help/add_remove_column.png" alt="">
                <table>
                    <tbody>
                        <tr>
                            <td class="numbering">&#x2780;</td>
                            <td>{{"🌐General.TableHelp.MoveColumnLeft" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2781;</td>
                            <td>{{"🌐General.TableHelp.MoveColumnRight" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2782;</td>
                            <td>{{"🌐General.TableHelp.HideColumn" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2783;</td>
                            <td>{{"🌐General.TableHelp.ShowColumn" | translate:lang}}</td>
                        </tr>
                        <tr>
                            <td class="numbering">&#x2784;</td>
                            <td>{{"🌐General.TableHelp.ConfirmColumns" | translate:lang}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </nb-card-body>
</nb-card>
