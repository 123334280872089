<nb-card *ngIf="products" status="primary">
    <nb-card-header class="d-flex justify-content-between py-0 px-0">
        <div class="d-flex flex-row h-100 mx-2 row align-items-center">
            <span class="py-3 px-3">{{"🌐Products.Table.Products" | translate:lang}}</span>
        </div>

        <div class="d-flex flex-row mx-2 row align-items-center">
            <div *ngIf="isEditable && hasRightEversysProductGenerator"
                class="icon-card-header-outer cursor-pointer mx-2" (click)="openEversysProductGenerator()">
                <nb-icon icon="layout-outline" status="primary"></nb-icon>
            </div>
            <div *ngIf="isEditable"
                class="icon-card-header-outer cursor-pointer mx-2" (click)="add()">
                <nb-icon icon="plus-outline" status="primary"></nb-icon>
            </div>
        </div>

       
    </nb-card-header>

    <nb-card-body>
        <nb-card>
            <nb-card-body>

                <nb-form-field>
                   Produktgruppen
                    <nb-select 
                        multiple
                        [selected]="filteredProductTypeGroups"
                        (selectedChange)="changeFilteredProductTypeGroups($event)"
                    >
                        <nb-option *ngFor="let productTypeGroup of availableProductTypeGroups" [value]="productTypeGroup">
                            {{("🌐ProductTypeGroup." + productTypeGroup.name) | translate:lang}}
                        </nb-option>
                    </nb-select>
                </nb-form-field>
            </nb-card-body>
        </nb-card>

        <iovent-table [data]="products_result" (pageChanged)="productPageChanged($event)" [(PageSize)]="productPageSize"
            (PageSizeChange)="onProductBasePageSizeChange()">

            <thead>
                <tr>
                    <th></th>
                    <th (click)="sortProductBase('ID')" [class]="'sortable text-link '+sortedByProductBase('ID')">
                        {{"🌐Products.Table.ID" | translate:lang}}
                        <nb-icon *ngIf="productSortBy == 'ID'"
                            [icon]="productOrderAsc?'chevron-up-outline':'chevron-down-outline'"></nb-icon>
                    </th>
                    <th>{{"🌐Products.Table.Name" | translate:lang}}</th>
                    <th class="centered">{{"🌐Products.Table.Illustration" | translate:lang}}</th>

                    <th>{{("🌐Products.CustomID" | translate:lang).replace(":","")}}</th>
                    <ng-container *ngIf="env.projectName == 'tur'">
                        <th>{{"🌐Products.Table.SAPNumber" | translate:lang}}</th>
                        <th>{{"🌐Products.Table.EAN" | translate:lang}}</th>
                    </ng-container>
                    <th>{{"🌐Products.Table.Price" | translate:lang}}</th>
                    <th>{{"🌐General.Actions" | translate:lang}}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let product of products; let i = index">
                    <tr [class]="'tr-data-row ' + (i % 2 ? 'odd' : 'even')"
                        [class.tr-data-row-opened]="product.Instances" [class.cursor-pointer]="product.Instances">
                        <td (click)="openProductBase($event, product)" class="position-relative">
                            <ng-container *ngIf="product.Instances && product.Instances.length > 1">
                                <nb-icon [icon]="productIsOpen(product)?'chevron-down-outline':'chevron-right-outline'"
                                    class="child-table-opener text-link"></nb-icon>
                                <span *ngIf="!productIsOpen(product)">({{product.Instances.length}})</span>
                            </ng-container>
                        </td>

                        <td (click)="edit(product)">
                            {{product.ID}}
                        </td>
                        <td (click)="edit(product)">
                            {{product.Name}}
                        </td>

                        <td class="centered" (click)="edit(product)">
                            <img src="{{env.imageUrl}}{{product.Image}}" *ngIf="product.Image" alt="{{product.Name}}" />
                        </td>

                        <td (click)="edit(product)">
                            {{singleInstance(product)?.CustomID}}
                        </td>
                        <ng-container *ngIf="env.projectName == 'tur'">
                            <td (click)="edit(product)">{{singleInstance(product)?.Extensions?.TurProductX?.SapNumber}}</td>
                            <td (click)="edit(product)">{{singleInstance(product)?.Extensions?.TurProductX?.EanCode}}</td>
                        </ng-container>
                        <td (click)="edit(product)">
                            <ng-container *ngIf="singleInstance(product)">{{singleInstance(product)?.Price / 100 |
                                iocurrency:currency}}</ng-container>
                        </td>
                        <td class="nowrap">
                            <span  class="cursor-pointer mr-2">
                                <nb-icon pack="custom-icons" [icon]="isEditable ? 'bearbeiten' : 'lupe'"
                                (click)="edit(product)" class="table-icon"></nb-icon>
                            </span>
                            <span *ngIf="isEditable" (click)="delete(product)" class="cursor-pointer ml-2">
                                <nb-icon pack="custom-icons" icon="loeschen" class="table-icon"></nb-icon>
                            </span>
                        </td>
                    </tr>
                    <ng-container *ngIf="productIsOpen(product) && product.Instances">

                        <tr *ngFor="let instance of product.Instances"
                            [class]="'tr-data-row tr-data-row-inner ' + (i % 2 ? 'odd' : 'even')">
                            <td>
                            </td>
                            <td>
                                {{instance.ID}}
                            </td>
                            <td>
                                {{instance.Name}}
                            </td>
                            <td>
                                {{instance.CustomID}}
                            </td>
                            <td>
                            </td>
                            <ng-container *ngIf="env.projectName == 'tur'">
                                <td>{{instance.Extensions?.TurProductX?.SapNumber}}</td>
                                <td>{{instance.Extensions?.TurProductX?.EanCode}}</td>
                            </ng-container>
                            <td>{{instance.Price / 100 | iocurrency:currency}}</td>
                            <td>
                                <!-- TODO: direct instance edit -->
                            </td>
                        </tr>

                    </ng-container>
                </ng-container>
            </tbody>

        </iovent-table>

    </nb-card-body>
</nb-card>
