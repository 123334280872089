<nb-card>
    <nb-card-header>
        <img src="assets/images/iovent-text-logo.png" style="height: 50px; width: auto;" />
    </nb-card-header>
    <nb-card-body>
        <div class="version-container">
            <div class="version-title">Portal</div>
            <div class="version-number">{{portalVersion}}</div>
        </div>
        <div class="version-container">
            <div class="version-title">Backend</div>
            <div class="version-number">{{backendVersion}}</div>
        </div>
    </nb-card-body>
</nb-card>
