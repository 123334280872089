
import { Component, OnInit, AfterViewInit, ViewChild, } from '@angular/core';
import { StorageService, DateRange, SelectedDateRange, SelectableDateRanges } from '../../services/storage.service';
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";
import { NbCalendarRange, NbDatepickerDirective } from '@nebular/theme'
import moment from 'moment';

@Component({
    selector: "datefilter",
    templateUrl: "./datefilter.component.html",
    styleUrls: ["./datefilter.component.scss"]
})
export class DateFilterComponent extends Localized implements OnInit, AfterViewInit {

    selectedDateRange: SelectedDateRange;
    selectableDateRanges = SelectableDateRanges;

    @ViewChild(NbDatepickerDirective, { static: false }) datePickerDirective: NbDatepickerDirective<any>;

    constructor(
        private storageService: StorageService,
        translationService: TranslationService) {
        super(translationService);
    }

    async ngOnInit() {
        this.selectedDateRange = this.storageService.getSelectedDateRange();
        this.coreChangeDateRange(this.getDateRange(this.selectedDateRange));
    }

    async ngAfterViewInit() {

    }

    // usage of datePickerDirective.writeValue due to nb-datepicker bugs:
    // https://github.com/akveo/nebular/issues/2222
    private coreChangeDateRange(dateRange: DateRange) {
        this.storageService.setDateRange(dateRange);
        setTimeout(() => { this.datePickerDirective.writeValue(dateRange); }, 0);
    }

    changeDateRange(e: NbCalendarRange<Date>) {
        if (e.end) {
            let dateRange: DateRange = { start: moment(e.start).startOf('day').toDate(), end: moment(e.end).endOf('day').toDate() };
            this.selectedDateRange = 'Custom'
            this.storageService.setSelectedDateRange(this.selectedDateRange);
            this.coreChangeDateRange(dateRange);
        }
    }

    changeSelectedDateRange(e: SelectedDateRange) {
        this.selectedDateRange = e
        this.storageService.setSelectedDateRange(this.selectedDateRange);
        this.coreChangeDateRange(this.getDateRange(this.selectedDateRange));
    }


    getDefaultSelectedRange(): SelectedDateRange {
        return 'CurrentDay';
    }

    getDateRange(selectedDateRange: SelectedDateRange): DateRange {
        switch (selectedDateRange) {
            case 'PreviousYear':
                return DateFilterComponent.getUnitDateRange('year', -1);
            case 'CurrentYear':
                return DateFilterComponent.getUnitDateRange('year', 0);
            case 'PreviousMonth':
                return DateFilterComponent.getUnitDateRange('month', -1);
            case 'CurrentMonth':
                return DateFilterComponent.getUnitDateRange('month', 0);
            case 'PreviousWeek':
                return DateFilterComponent.getUnitDateRange('week', -1);
            case 'CurrentWeek':
                return DateFilterComponent.getUnitDateRange('week', 0);
            case 'PreviousDay':
                return DateFilterComponent.getUnitDateRange('day', -1);
            case 'CurrentDay':
                return DateFilterComponent.getUnitDateRange('day', 0);
            case 'Custom':
            default:
                let temp: DateRange = this.storageService.getDateRange();
                if (temp) {
                    return temp;
                } else { // fallback to default, but do not store permamently
                    this.selectedDateRange = this.getDefaultSelectedRange();
                    return this.getDateRange(this.selectedDateRange);
                }
        }
    }


    static getUnitDateRange(unitOfTime: 'day' | 'week' | 'month' | 'year', diff: number = 0): DateRange {
        let base = moment().add(diff, unitOfTime);
        let u: 'day' | 'week' | 'month' | 'year' | 'isoWeek' = unitOfTime != 'week' ? unitOfTime : 'isoWeek'; // isoWeek starts at Monday
        let range = { start: base.startOf(u).toDate(), end: base.endOf(u).toDate() };
        //console.log(range);
        return range;
    }



}
