import { IEnvironment } from "./environment-defaults";
import * as turEnvironment from "./environment.tur";

/**
 * Staging environment for the "iovent tur" project (staging.tur.iovent.net).
 */
export const environment: IEnvironment = {
    ...turEnvironment.environment, 
    
    // Test settings
    testStage: "Staging",
    testWarning: "TuR Staging", 
    
};
