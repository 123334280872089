import { MenuPage } from "shared-gen/Model/Menus/MenuPage";
import { ProductBase } from "shared-gen/Model/Products/ProductBase";
import { Product } from "shared-gen/Model/Products/Product";
import { ProductInstance } from "shared-gen/Model/Products/ProductInstance";

export class MenuPageFun {

    static createNew(columns = 4, rows = 2): MenuPage {
        return {
            Name: '',
            Columns: columns,
            Cells: []
        };
    }

    /**
     * Gets all products from the given menu page, by iterating
     * the cells, product bases and accumulating their instances.
     */
    static getAllProducts(menuPage: MenuPage): ProductInstance[] {
        const acc: Product[] = [];
        MenuPageFun.getAllProductsAcc(menuPage, acc);
        return acc;
    }

    /**
     * Accumulates all products from the given menu page, by iterating
     * the cells, product bases and accumulating their instances into the
     * given array (each product ID only once).
     */
    static getAllProductsAcc(menuPage: MenuPage, acc: ProductInstance[]) {
        for (let cell of menuPage.Cells)
            if (cell && cell.Product)
                for (let product of cell.Product.Instances)
                    if (false == acc.some(it => it.ID == product.ID))
                        acc.push(product);
    }

    /**
     * Gets all base products from the given menu page, by iterating
     * the cells and accumulating their base products.
     */
    static getAllProductBases(menuPage: MenuPage): ProductBase[] {
        const acc: ProductBase[] = [];
        MenuPageFun.getAllProductBasesAcc(menuPage, acc);
        return acc;
    }

    /**
     * Accumulates all base products from the given menu page, by iterating
     * the cells and accumulating their base products into the
     * given array (each base product ID only once).
     */
    static getAllProductBasesAcc(menuPage: MenuPage, acc: ProductBase[]) {
        for (let cell of menuPage.Cells)
            if (cell && cell.Product)
                if (false == acc.some(it => it.ID == cell.Product?.ID))
                    acc.push(cell.Product);
    }

}
