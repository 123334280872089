import { Component, Input, OnInit } from "@angular/core";
import { DeviceOverview } from "../../../../shared-gen/Model/Devices/DeviceOverview";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";
import { DataService } from "../../../services/data.service";

@Component({
    selector: "sandenvendovm-resources",
    templateUrl: "./sandenvendovm-resources.component.html",
    styleUrls: ["./sandenvendovm-resources.component.scss"]
})
export class SandenVendoVMResourcesComponent extends Localized {

    _deviceID: string;

    // Indices
    readonly states = ["full", "short", "empty"];

    // Content [ full, short, empty ]
    count = { full: 0, short: 0, empty: 0 };
    productIDs = { full: "", short: "", empty: "" };

    @Input()
    set deviceID(id: string) {
        this._deviceID = id;
        this.init();
    }

    constructor(translationService: TranslationService,
        private dataService: DataService) {
        super(translationService);
    }

    async init() {
        const fillStatus = await this.dataService.getDeviceFillStatus(
            this._deviceID, this.translationService.getLanguageCode());
        if (fillStatus) {
            for (const fill of fillStatus.Products) {
                const remaining = fill.RemainingCount || 0;
                const max = fill.Capacity || 0;
                const state = max > 0 && remaining / max >= 0.3
                    ? "full" : (remaining == 0 ? "empty" : "short");
                this.count[state]++;
                if (this.productIDs[state].length > 0)
                    this.productIDs[state] += ", ";
                this.productIDs[state] += fill.ProductName + " (" + remaining + "/" + max + ")";
            }
        }
    }

}
