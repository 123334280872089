import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { NbDialogService, NbSortDirection, NbSortRequest } from '@nebular/theme';
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";
import { ListResponse } from "../../services/data.service";
import { TableHelpDialogComponent } from "../@dialogs/table-help-dialog/table-help-dialog.component";


/*
declare global {
  interface Object {
    getPropertyByPath(path: string): any;
  }

}

Object.prototype.getPropertyByPath = function (path: string) {
  return path.split('.').reduce((a, b) => a[b], this);
}
*/




@Directive({
    selector: '[Column]'
})
export class IoventTableColumnTemplateDirective<T = any> implements OnInit {
    @Input() Column: string;
    @Input() SortBy: string;
    @Input() FilterBy: string;
    @Input() Header: string;
    @Input() Size?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'; // specify the width of a column, default is undefined, css class column-header-d will be applied
    @Input() NoFilter: boolean = false;
    @Input() NoSort: boolean = false;
    @Input()
    public set FilterValues(v: string) {
        this.Values = v.split(",").map((item: string) => item.trim());
    }
    public Values: string[] = [];


    @Input() Classes: string = null; // Additional classes, like "myclass1 myclass2 myclass3"
    @Output() click = new EventEmitter<T>();
    constructor(public template: TemplateRef<any>) {
    }
    ngOnInit() {
        this.NoFilter = !(this.NoFilter === false);
        this.NoSort = !(this.NoSort === false);
    }
    forwardClick(data: T) {
        if (data) {
            this.click.emit(data);
        }
    }
}


type BooleanOp = "AND" | "OR";

const valueOps: string[] = ["~", "!~", "=", "!=", "<=", "<", ">", ">="];
type ValueOp = typeof valueOps[number];

type FilterPart = { partOp: BooleanOp; valueOp: ValueOp; value: string; }


export type Filter = "devicefilter" | "locationfilter" | "datefilter" | "filter" | "search";

@Component({
    selector: "iovent-table",
    templateUrl: "./iovent-table.component.html",
    styleUrls: ["./iovent-table.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class IoventTableComponent<T> extends Localized implements OnInit, AfterViewInit, OnDestroy {

    json = JSON.stringify;

    /**
     * expects a JSON string i.E. [\"dropdown\",\"search\"]
     */
    @Input() showFilter: Filter[] = [];

    @Input() stickyFrame: boolean = false;

    @Input() settings: object;

    @Input()
    set data(d: ListResponse<T>) {
        this.changeData(d);
    }


    @Input()
    set treedata(d: TreeNode<T>[]) {
        this.changeTreeData(d);
    }

    @Input() tableName: string;

    columnNames: string[] = [];
    @Input("columns") set _columnNames(cn: string[]) {
        if (cn) {
            this.columnNames = cn;
            console.log("columnNames: " + this.columnNames.join(", "));
            this.updateColumns();
        }
    }

    defaultColumnNames: string[] = [];
    @Input("defaultColumns") set _defaultColumnNames(cn: string[]) {
        if (cn) {
            this.defaultColumnNames = cn;
            console.log("defaultColumnNames: " + this.defaultColumnNames.join(", "));
            this.updateColumns();
        }
    }

    extraColumnNames: string[] = [];
    @Input("extraColumns") set _extraColumnNames(cn: string[]) {
        if (cn) {
            this.extraColumnNames = cn;
            console.log("extraColumnNames: " + this.extraColumnNames.join(", "));
            this.updateColumns();
        }
    }


    @Input() type: "table" | "grid" | "tree" = "table";
    @Input() details: boolean = false;
    @Input() expanded: boolean = true;

    @Output() pageChanged: EventEmitter<number> = new EventEmitter();

    pageSizes: number[] = [10, 25, 50, 100];
    @Input() PageSize: number = 50;
    @Output() PageSizeChange: EventEmitter<number> = new EventEmitter();
    @Input() infiniteScroll: boolean = false;

    @Output() sortChanged: EventEmitter<NbSortRequest> = new EventEmitter();

    @Input() sortBy: string = null;
    @Input() orderAsc: boolean = false;

    @Output() queryChanged: EventEmitter<string> = new EventEmitter();
    @Input() autoQuery: number = 2;

    @Input('getPath') getPath: (t) => string[] = TreeUtils.getPathDefault;

    editColumn(column: string, op: string) { this.columnEdit.emit({ Column: column, Op: op }); }
    @Output() columnEdit: EventEmitter<object> = new EventEmitter();

    @ViewChild(IoventTableColumnTemplateDirective)
    set actionsColumnTemplate_(c: IoventTableColumnTemplateDirective) {
        c = c as IoventTableColumnTemplateDirective<T>;
        if (c && c != this.actionsColumnTemplate) {
            this.actionsColumnTemplate = c;
            //console.log("actionsColumnTemplate: " + this.actionsColumnTemplate.Column);
            this.updateColumns();
        }
    }
    actionsColumnTemplate: IoventTableColumnTemplateDirective<T>;

    @ContentChildren(IoventTableColumnTemplateDirective)
    set columnTemplates_(c: QueryList<IoventTableColumnTemplateDirective>) {
        if (c) {
            this.availableColumnTemplates = c.toArray().map(x => x as IoventTableColumnTemplateDirective<T>);
            //console.log("availableColumnTemplates: " + this.availableColumnTemplates.map(x => x.Column).join(", "));
            this.updateColumns();
        }
    }

    availableColumnTemplates: IoventTableColumnTemplateDirective<T>[];
    columnTemplates: IoventTableColumnTemplateDirective<T>[];

    columns: string[];

    editColumns: boolean = false;

    toggleColumnEdit() {
        console.log(this.columnTemplates)
        this.editColumns = !this.editColumns;
        this.updateColumns();
    }

    updateColumns() {
        if (this.availableColumnTemplates) {

            if (!this.defaultColumnNames || this.defaultColumnNames.length == 0)
                this.defaultColumnNames = this.availableColumnTemplates.map(x => x.Column);

            if (!this.columnNames || this.columnNames.length == 0)
                this.columnNames = this.defaultColumnNames;

            let shownColumnNames = this.columnNames;

            if (this.editColumns) {
                let allColumnNames = this.defaultColumnNames.concat(this.extraColumnNames);
                let unusedColumnNames = allColumnNames.filter(it => !this.columnNames.includes(it));
                shownColumnNames = shownColumnNames.concat(unusedColumnNames);
            }

            // make $Actions always last and add if missing
            shownColumnNames = shownColumnNames.filter(it => it != "$Actions");
            if (this.tableName && !shownColumnNames.includes('$Actions'))
                shownColumnNames = shownColumnNames.concat(['$Actions'])

            if (this.tableName && this.availableColumnTemplates.filter(it => it.Column == '$Actions').length == 0 && this.actionsColumnTemplate != undefined)
                this.availableColumnTemplates = this.availableColumnTemplates.concat([this.actionsColumnTemplate]);

            //console.log("shownColumns: " + shownColumns.join(", "));
            //console.log("availableColumnTemplates for mapping: " + this.availableColumnTemplates.map(x => x.Name).join(", "));

            this.columnTemplates = shownColumnNames.length > 0
                ? shownColumnNames.map(name => this.availableColumnTemplates.find(it => it.Column == name)).filter(it => it != undefined)
                : this.availableColumnTemplates;

            // only render columns that have templates
            this.columns = this.columnTemplates.map(x => x.Column);


            // always show filters
            // if(!this.filter[this.columns[0]])
            //   this.columns.forEach(column => this.addFilterPart(column));

            //console.log("columns: " + this.columns.join(", "));
            this.cd.detectChanges();
        }
    }

    getColumnTemplateDirective(templateName: string): IoventTableColumnTemplateDirective<T> {
        return this.columnTemplates.find(x => x.Column == templateName);
    }
    getColumnTemplate(templateName: string): TemplateRef<any> {
        return this.getColumnTemplateDirective(templateName)?.template;
    }
    getColumnHasFilterValues(templateName: string): boolean {
        let directive = this.getColumnTemplateDirective(templateName);
        return directive && directive.Values && directive.Values.length > 0;
    }

    @ContentChild("details") detailTemplate: TemplateRef<any>;

    // tableheaderTop;
    tableheader: HTMLElement;

    getTdTop() {
        let height = this.tableheader?.offsetHeight;
        //console.log("height: " + height + " top: " + this.tableheaderTop);
        return height; // +this.tableheaderTop;
    }


    _data: ListResponse<T>;
    _treedata: TreeNode<T>[];
    initialized = false;

    firstIndex = 0;
    lastIndex = 0;
    totalCount: number = 0;
    totalPages: number = 0;
    isIncomplete = false;
    pages: number[] = [];


    constructor(translationService: TranslationService,
        protected cd: ChangeDetectorRef,
        private dialogService: NbDialogService,
        private elementRef: ElementRef) {
        super(translationService);
    }

    ngOnInit() {
        this.initialized = true;
        this.handleChangedData();
    }

    ngAfterContentInit() {

    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    ngAfterViewInit() {
        this.tableheader = this.elementRef.nativeElement.querySelector('#tableheader');
        //this.tableheaderTop = document.defaultView.getComputedStyle(document.getElementById('tableheader'),null).getPropertyValue('top');
        //this.cd.detectChanges();
        this.updateColumns();
    }

    changeData(d: ListResponse<T>) {
        this._data = d;
        this.handleChangedData();
    }

    handleChangedData() {
        if (!this.initialized) return;
        this.pages = [];
        this._treedata = [];

        if (this._data) {

            this.firstIndex = this._data.PageIndex * this._data.PageSize + 1;
            this.lastIndex = this.firstIndex + this._data.Items.length - 1;

            this.totalCount = this._data.TotalCount;
            this.isIncomplete = this._data.IsIncomplete;
            this.totalPages = this._data.TotalPageCount;

            const maxVisiblePages = 4;
            let currentPage = this._data.PageIndex + 1;
            let firstVisiblePage = Math.max(1, currentPage - maxVisiblePages / 2);
            for (let i = firstVisiblePage; this.pages.length < maxVisiblePages && i <= this.totalPages; i++) {
                this.pages.push(i);
            }

            this._treedata = [];

            if (this._data.Items) {

                if (this.type == "tree") {
                    this._treedata = TreeUtils.createTree(this._data.Items, this.getPath, this.expanded);
                }
                else if (this.type == "grid") {
                    this._treedata = this._data.Items.map(x => { return { data: { depth: 0, inner: x } }; });
                }

                if (this.details) {
                    TreeUtils.addDetails(this._treedata);
                }

            }

        }

    }

    openTableHelpDialog() {
        this.dialogService.open(TableHelpDialogComponent);
    }

    changeTreeData(d: TreeNode<T>[]) {
        this._treedata = d;
    }

    getPropertyByPath(obj: Object, path: string) {
        let or = path.split('|');
        for (let i = 0; i < or.length; i++) {
            let d = or[i].split('.').reduce((a, b) => a != null ? a[b] : null, obj);
            if (d !== undefined) {
                return d;
            }
        }
        return undefined;
    }

    isLeaf(node: TreeNode<T>): boolean { return TreeUtils.isLeaf(node); }
    isDetails(node: TreeNode<T>): boolean { return TreeUtils.isDetails(node); }

    openPage(page) {
        this.pageChanged.emit(page);
    }

    changePageSize() {
        this.PageSizeChange.emit(this.PageSize);
    }

    changeSort(sortRequest: NbSortRequest): void {
        if (!sortRequest.column.startsWith("$")) {
            // short circuit:
            this.sortBy = sortRequest.column;
            this.orderAsc = sortRequest.direction == NbSortDirection.ASCENDING;
            // long circuit via bindings:
            this.sortChanged.emit(sortRequest);
        }
    }


    toogleSort(column: string): void {

        if (column.startsWith('$'))
            return;

        var template = this.getColumnTemplateDirective(column);

        if (template.NoSort)
            return;

        if (template.SortBy)
            column = template.SortBy;

        // short circuit:
        if (this.sortBy != column) {
            this.orderAsc = true;
            this.sortBy = column;
        } else {
            this.orderAsc = !this.orderAsc;
        }
        // long circuit via bindings:
        let sortRequest: NbSortRequest = {
            column: column,
            direction: this.orderAsc === true ? NbSortDirection.ASCENDING : NbSortDirection.DESCENDING
        }
        this.sortChanged.emit(sortRequest);

    }

    getSortDirection(column: string): NbSortDirection {
        if (this.sortBy === column) {
            return this.orderAsc === true ? NbSortDirection.ASCENDING : NbSortDirection.DESCENDING;
        }
        return NbSortDirection.NONE;
    }

    filter: { [key: string]: { columnOp: BooleanOp; filterParts: FilterPart[] } } = {};

    trackByIndex(index: number, obj: any): any {
        return index;
    }

    addFilterPart(column: string) {
        let part: FilterPart = { partOp: "OR", valueOp: "~", value: "" }
        if (!this.filter[column]) {
            this.filter[column] = { columnOp: "AND", filterParts: [part] };
        } else {
            this.filter[column].filterParts.push(part);
        }
        // this.filter[column].filterParts[this.filter[column].filterParts.length-1].value = (this.filter[column].filterParts.length-1).toString();
        // NOTE: does not trigger change since default filter does not actually filter anything
    }

    removeFilterPart(column: string, index: number) {
        this.filter[column].filterParts.splice(index, 1);
        this.query();
    }

    toggleBooleanOp(op: BooleanOp): BooleanOp {
        if (op == "AND") {
            return "OR";
        } else {
            return "AND";
        }
    }

    convertBooleanOp(op: BooleanOp): string {
        if (op == "AND") {
            return "&&";
        } else {
            return "||";
        }
    }


    toggleColumnOp(column: string) {
        this.filter[column].columnOp = this.toggleBooleanOp(this.filter[column].columnOp);
        this.query();
    }

    /*
    toggleFilterPartOp(column: string, index: number) {
      this.filter[column].filterParts[index].partOp = this.toggleBooleanOp(this.filter[column].filterParts[index].partOp);
      this.query();
    }
    */

    valueOps = valueOps; // expose global const locally

    changeFilterPartValueOp(column: string, index: number, e) {
        this.filter[column].filterParts[index].valueOp = e;
        this.query();
    }

    getQuery(): string {
        let query = "";
        let i = 0;
        for (let column in this.filter) {
            let columnFilter = this.filter[column];
            let data = column;
            var template = this.getColumnTemplateDirective(column);
            if (template.FilterBy)
                data = template.FilterBy;
            if (columnFilter.filterParts.length > 0) {
                let j = 0;
                for (let key in columnFilter.filterParts) {
                    let part = columnFilter.filterParts[key];
                    if (part.value != undefined && part.value.length > 0) {
                        if (j === 0) {
                            if (i > 0) {
                                query += this.convertBooleanOp("AND");
                            }
                            query += "(";
                        }
                        else query += this.convertBooleanOp(columnFilter.columnOp);
                        // TODO: escape & | ( < = > < ~  etc. in values ? Or add support for quoted values ?
                        let cleanValue = part.value.replace(/[&\|\(\)<=><~§:!]*/gi, "")
                        part.value = cleanValue;
                        query += data + part.valueOp + cleanValue;
                        j++;
                    }
                }
                if (j > 0) {
                    query += ")";
                    i++;
                }
            }
        }
        return query;
    }

    timeout_handle;
    clearTimeout() {
        clearTimeout(this.timeout_handle);
    }

    query() {
        this.clearTimeout();
        let query = this.getQuery();
        this.queryChanged.emit(query);
        setTimeout(this.clearTimeout.bind(this), 0); // stop follow up timeouts - event order unknown
    }

    startAutoQueryTimeout() {
        if (this.autoQuery >= 0) {
            this.clearTimeout();
            this.timeout_handle = (setTimeout(this.query.bind(this), this.autoQuery * 500));
        }
    }

    isFilterSet(...filters: Filter[]) {
        return filters.some(f => this.showFilter.indexOf(f) > -1);
    }


}

export interface TreeNodeData<T> {
    inner: T;
    depth: number;
    details?: boolean;
}

export interface TreeNode<T> {
    node?: string;
    data: TreeNodeData<T>;
    children?: TreeNode<T>[];
    expanded?: boolean;
}

export class TreeUtils {

    static isLeaf<T>(node: TreeNode<T>): boolean { return !node.children || node.children.length == 0 || TreeUtils.isDetails<T>(node.children[0]); }
    static isDetails<T>(node: TreeNode<T>): boolean { return node.data["details"] === true; }

    static addDetails<T>(tree: TreeNode<T>[], all: boolean = false) {
        for (let i = 0; i < tree.length; i++) {
            let node = tree[i];
            if (node.children == null) {
                node.children = [];
            }
            if (all || node.children.length == 0) {
                node.children.push({ node: node.node, data: { depth: node.data.depth, details: true, inner: node.data.inner } })
            }
            else {
                this.addDetails(node.children);
            }
        }
    }

    static getPathDefault = (t): string[] => {
        let path: string[] = [];

        if (t['GroupID']) {
            path.push(t.GroupID);
            if (t['ID']) {
                path.push(t.ID);
            }
        }
        else if (t['LocationID']) {
            path = t.LocationID.split("-");

            if (t['DeviceID']) {
                path.push(t.DeviceID);
            }

            if (t['ID']) {
                path.push(t.ID);
            }
            else if (t['Number']) {
                path.push(t.Number.toString());
            }
            else if (t['Serial']) {
                path.push(t.Serial.toString());
            } else {
                path.push("?");
            }

        } else { // no location reference -> location itself (or something completely different)
            if (t['ID']) {
                path = t.ID.split("-");
            }
        }
        //console.log(path);
        return path;
    }

    // based on https://gist.github.com/stephanbogner/4b590f992ead470658a5ebf09167b03d
    static createTree<T>(array: T[], getPath: (t) => string[] = this.getPathDefault, expanded: boolean = false): TreeNode<T>[] {
        let tree: TreeNode<T>[] = [];
        this.addRangeToTree(array, tree, 0, getPath, expanded);
        return tree;
    }

    static addRangeToTree<T>(array: T[], tree: TreeNode<T>[], depth: number, getPath: (t) => string[] = this.getPathDefault, expanded: boolean = false) {
        for (let i = 0; i < array.length; i++) {
            this.addToTree(array[i], tree, depth, getPath, expanded);
        }
    }

    static addToTree<T>(t: T, tree: TreeNode<T>[], depth: number, getPath: (t) => string[] = this.getPathDefault, expanded: boolean = false) {
        let path = getPath(t);
        let currentLevel = tree;
        let currentDepth = depth;
        let node: TreeNode<T>;

        for (let j = 0; j < path.length; j++) {
            node = this.findWhere(currentLevel, 'node', path[j]);
            let id = path.slice(0, j + 1).join("-");
            if (node == null) {
                node = {
                    node: path[j],
                    data: { depth: currentDepth, inner: { ID: id } as any },
                    children: [],
                    expanded: expanded,
                };
                currentLevel.push(node);
            }
            currentLevel = node.children;
            currentDepth++;
        }

        // handle pathless node
        if (node == null) {
            node = { node: "", data: { depth: depth, inner: {} as any }, children: [] };
            currentLevel.push(node);
        }

        node.data.inner = t;
    }

    static findWhere<T>(array: TreeNode<T>[], key, value): TreeNode<T> {
        for (let t = 0; t < array.length; t++) {
            if (array[t][key] === value) return array[t];
        }
        return null;
    }

}
