import { Pipe, PipeTransform } from '@angular/core';
import { Currency } from "../../shared-gen/Model/Utils/Currency";
import { toCurString } from "../payment/currencies";

/**
 * Formats amounts of money given an iovent-registered currency to a string
 * in default format for that currency/locale.
 *
 * Don't forget to register the required locals in the app module, by calling
 * registerLocales() from init.ts.
 */
@Pipe({
    name: 'iocurrency', // "io-" because Angular also has a "currency" pipe
    pure: true
})
export class CurrencyPipe implements PipeTransform {

    transform(value: number, currency: Currency): any {
        return toCurString(value, currency);
    }

}
