<div class="LocalizedString" [class.LocalizedString--expanded]="isExpanded">
  <div class="LocalizedString-row">
    <input 
      nbInput
      fullWidth 
      type="text"
      [name]="inputName"
      [(ngModel)]="value"
      (ngModelChange)="onValueChange()"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [status]="status"
    >
    <nb-icon [icon]="isExpanded ? 'chevron-up-outline' : 'chevron-down-outline'" pack="eva" class="LocalizedString-expandableIcon clickable" (click)="onExpandButtonClicked()"></nb-icon> 
    <nb-icon icon="globus" pack="custom-icons" class="LocalizedString-icon clickable" (click)="onExpandButtonClicked()"></nb-icon> 
    <!-- <div [style.background-image]="getFlagUrl(currentLanguageCode)" class="LocalizedString-icon clickable" (click)="onExpandButtonClicked()"></div> -->
    <!-- <button nbButton [style.background-image]="getFlagUrl(currentLanguageCode)" class="LocalizedString-icon clickable" (click)="onExpandButtonClicked()"></button>  -->
  </div>
  <span *ngIf="!isExpanded">
    <i>{{ translateWithTokens('Portal.LocalizedString.NumberTranslationsFound', ['{0}', getNumberOfFilledTranslations()]) }}</i>
  </span>
  <ng-container *ngIf="isExpanded">
    <ng-container *ngFor="let languageCode of allLanguages">
      <div class="LocalizedString-row" >
        <input
          nbInput
          fullWidth
          type="text"
          [(ngModel)]="localizedString[languageCode]"
          (ngModelChange)="onLocalizedStringChange()"
          [disabled]="disabled"
          [placeholder]="localizedPlaceholder"
          [status]="status"
        >
        <div [style.background-image]="getFlagUrl(languageCode)" class="LocalizedString-icon"></div> 
      </div>
    </ng-container>
  </ng-container>
</div>