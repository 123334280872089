/** Amount of money in cents, e.g. 520 = 5.20 EUR/USD/... */
export type Cents = number;

/** Value and number of coins or bills, e.g
 *  e.g. { 5: 10, 10: 20 } for 10 * 5ct and 20 * 10ct. */
export interface Coins {
    [cents: number]: number; // "cents: Cents", but not possible in TypeScript's type system
}

export interface Bills {
    [cents: number]: number; // "cents: Cents", but not possible in TypeScript's type system
}

/** UTC time in format "2019-02-03T23:34:09". */
export type UtcDate = string;

/** Color to symbolize the status of a device, location, hardware peripheral and so on. */
export type StatusColor = 'gray' | 'green' | 'yellow' | 'orange' | 'red' | 'greenyellow' | 'yellowred' | 'greenred';

/** Returns an empty ID. */
export function getDefaultID(): string {
    return '';
}

/** Object.entriesFrom() polyfill */
export function Object_fromEntries(entries: any[]) {
    if (!entries || !entries[Symbol.iterator]) { throw new Error('Object.fromEntries() requires a single iterable argument'); }
    let obj = {};
    for (let [key, value] of entries) {
        (obj as any)[key] = value;
    }
    return obj;
}
