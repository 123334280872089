import { Directive, EventEmitter, HostListener, Input, OnInit, Output, ContentChild, ElementRef } from '@angular/core';
import { DataService } from '../services/data.service';
import { StorageService } from '../services/storage.service';
import { NbWindowService } from '@nebular/theme';
import { LoadingComponent } from '../components/loading/loading.component';
import { UserStore } from "../@core/stores/user.store";
import { TranslationService } from "../../shared/services/translation.service";

// TODO: apply directly to <a href ...> to simplify html ?
@Directive({
    selector: '[download]'
})
export class DownloadDirective implements OnInit {

    @Output() onDownloadFinish = new EventEmitter();
    @Input() downloadType: 'excel' | 'license' = 'excel';
    @Input() downloadDataType: string = 'transactions';
    @Input() downloadTitle: string = 'Downloading...';
    @Input() downloadDirect: boolean = false;

    @Input() getDownloadOptions: () => object;

    /*
    @Input()
    set downloadOptions(options: any) {
        this.options = options;
        console.log(options);
    }
    options;
    */

    @ContentChild("excelLink") excelLink: ElementRef<HTMLAnchorElement>;

    constructor(private dataService: DataService,
        private storageService: StorageService,
        private windowService: NbWindowService,
        private translationService: TranslationService) {
    }

    ngOnInit() {
        switch (this.downloadType) {
            case 'excel': case 'license':
                break;
            default:
                throw Error('Unknown DownloadType!');
        }
    }

    @HostListener('click', ['$event'])
    clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();
        switch (this.downloadType) {
            case 'excel':
                this.excelDownload();
                break;
            case 'license':
                this.licenseDownload();
                break;
            default:
                throw Error('Unknown DownloadType!');
        }
    }

    async excelDownload() {

        let options = this.getDownloadOptions();
        let dataType = this.downloadDataType;

        let href = this.dataService.getExcelDownloadUrl(options, dataType, "filtered");
        console.log("Start Download: " + href);

        if (this.downloadDirect) {
            await this.startDirectDownload(href);
        }
        else {
            const uuid = await this.dataService.startExcelDownload(options, dataType, "filtered");
            this.windowService.open(
                LoadingComponent,
                {
                    context: {
                        title: this.downloadTitle,
                        dataType: dataType,
                        uuid: uuid,
                    },
                    closeOnBackdropClick: false,
                    closeOnEsc: false,
                });
        }
    }

    /**
     * Starts downloading a license key file for the currently filtered devices.
     */
    async licenseDownload() {
        let options = this.getDownloadOptions();
        let href = this.dataService.getLicenseFileUrl(options, "filtered",
            this.translationService.getLanguageCode());
        // Always direct download
        await this.startDirectDownload(href);
    }

    private async startDirectDownload(href: string) {
        console.log("Start Download: " + href);
        let key = await this.dataService.getDownloadKey();
        href = href + "&Key=" + key;
        const link = document.createElement('a');
        //link.setAttribute('target', '_blank');
        link.setAttribute('download', '');
        link.setAttribute('href', href);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

}
