import localeEnAU from "@angular/common/locales/en-AU";
import localeDeCH from "@angular/common/locales/de-CH";
import localeDa from "@angular/common/locales/da";
import localeDe from "@angular/common/locales/de";
import localeEnGB from "@angular/common/locales/en-GB";
import localeEnSG from "@angular/common/locales/en-SG";
import localeEn from "@angular/common/locales/en";
import localeNb from "@angular/common/locales/nb"
import localePL from "@angular/common/locales/pl"
import { registerLocaleData } from "@angular/common";

/**
 * Call these methods at the very beginning!
 */

export function registerLocales() {
    // Available locales: See https://github.com/angular/angular/tree/master/packages/common/locales (make sure to check the correct version tag)
    registerLocaleData(localeEnAU, 'en-AU');
    registerLocaleData(localeDeCH, 'de-CH');
    registerLocaleData(localeDa, 'da');
    registerLocaleData(localeDe, 'de');
    registerLocaleData(localeEnGB, 'en-GB');
    registerLocaleData(localeEnSG, 'en-SG');
    registerLocaleData(localeEn, 'en-US');
    registerLocaleData(localeNb, 'nb');
    registerLocaleData(localePL, 'pl');
}
