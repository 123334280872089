import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from "../../shared/date";
import * as moment from "moment";

/**
 * See [[toLocalDate]].
 */
@Pipe({
    name: 'zoneDate'
})
export class ZoneDatePipe implements PipeTransform {

    transform(value: Date | string, args?: any): any {
        return formatDate(moment(value));
    }

}
