import { VoucherRedemptionState } from "../../../shared-gen/Model/Vouchers/VoucherRedemptionState";
import { VoucherRedemption } from "../../../shared-gen/Model/Vouchers/VoucherRedemption";
import { toLocalDate } from "../../date";
import { ProductInstance } from "shared-gen/Model/Products/ProductInstance";

export class VoucherRedemptionFun {

    static getProductNameWithId(productInstance: ProductInstance): string {
        const BRACKETED_ID = "(" + productInstance.ID + ")"
        return productInstance != null && productInstance.Name ? productInstance.Name + " " + BRACKETED_ID : productInstance.ID ? BRACKETED_ID : "";
    }

    static getLabel(redemption: VoucherRedemption): string {
        var item = VoucherRedemptionFun.redemptionStates.find(it => it[0] == redemption.State)
        return item ? item[2] : "?";
    }

    static getText(redemption: VoucherRedemption): string {
        return redemption.Date ? '' + toLocalDate(redemption.Date) : "";
    }

    static getDescription(redemption: VoucherRedemption): string {
        var item = VoucherRedemptionFun.redemptionStates.find(it => it[0] == redemption.State);
        return item ? item[1] : "?";
    }

    private static redemptionStates: [VoucherRedemptionState, string, string][] = [
        [VoucherRedemptionState.Open, "QRVoucher.OpenDescription", "RedemptionState.Open"],
        [VoucherRedemptionState.Released, "QRVoucher.ReleasedDescription", "RedemptionState.Released"],
        [VoucherRedemptionState.Reserved, "QRVoucher.ReservedDescription", "RedemptionState.Reserved"],
        [VoucherRedemptionState.Redeemed, "QRVoucher.RedeemedDescription", "RedemptionState.Redeemed"],
        [VoucherRedemptionState.Revoked, "QRVoucher.RevokedDescription", "RedemptionState.Revoked"],
    ];
}
