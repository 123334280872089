// transforms a given array buffer to a base64 string. This is useful in case
// a service answers an image which needs to be displayed on the screen afterwards.
// Auth might block the usage urls directly in img tags
export function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array( buffer );
    const len = bytes.byteLength;
    for (const byte of bytes) {
        binary += String.fromCharCode( byte );
    }
    return window.btoa( binary );
}