import { AfterContentInit, AfterViewInit, Component, ContentChildren, Directive, EventEmitter, Host, Input, OnInit, Optional, Output, Pipe, PipeTransform, QueryList, Self } from "@angular/core";
import { NbOptionComponent, NbOptionGroupComponent, NbSelectComponent } from '@nebular/theme';
import { DataService } from 'app/services/data.service';
import { from } from 'rxjs';
import { filter, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { IDAndName } from '../../../shared-gen/Utils/Model/IDAndName';
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";
import { StorageService } from "../../services/storage.service";


@Pipe({
    name: 'matchesParent'
})
export class MatchesParentPipe implements PipeTransform {
    transform(items: Array<IDAndName>, parent: IDAndName): Array<IDAndName> {
        if (items == null) return items;
        return items.filter(x => x.ParentID === parent.ID);
    }
}

@Pipe({
    name: 'includedIn'
})
export class IncludesPipe implements PipeTransform {
    transform(items: Array<IDAndName>, ids: string[]): Array<IDAndName> {
        if (items == null) return items;
        if (ids == null || ids.length == 0) return items;
        console.log(ids);
        return items.filter(x => ids.includes(x.ID));
    }
}

@Directive({
    selector: '[searchable]',
})
export class SearchableDirective implements OnInit, AfterContentInit {

    @Input() placeholder: string = "";

    @Input() search: string = "";

    @ContentChildren(NbOptionGroupComponent, { descendants: true }) optionGroups: QueryList<NbOptionGroupComponent>;

    //@ContentChildren(NbOptionComponent, { descendants: true }) options: QueryList<NbOptionComponent>;

    get searchView(): string {
        if (this.search) return this.search + " | ";
        return "";
    }

    get selectionView(): string {
        if (this.hostSelect.selectionModel.length) return this.hostSelect.selectionView;
        return this.placeholder;
    }

    //@Input()
    set labelView(t: string) { this._labelView = t; this.labelViewChange.emit(this._labelView) }
    get labelView(): string { return this._labelView; }
    _labelView: string = "";
    @Output() labelViewChange = new EventEmitter<string>();

    rawHostSelect: any;

    constructor(@Host() @Self() @Optional() public hostSelect: NbSelectComponent) {
    }

    ngOnInit() {

        /* // NOTE: use a custom "nothing found" <nb-option [value]="undefined" disabled> NotFound </nb-option>
        const option = this.rawHostSelect.document.createElement('nb-option');
        option.disabled = true;
        option.appendChild(document.createTextNode("---"));
        this.rawHostSelect.hostRef.nativeElement.appendChild(option);
        */

        this.rawHostSelect = (<any>this.hostSelect);
        this.rawHostSelect.searchable = this;

        this.hostSelect.registerOnTouched(() => this.onHostTouched());
        this.hostSelect.registerOnChange(() => this.onHostChange());

        let hide = this.rawHostSelect.hide;
        this.rawHostSelect.hide = () => {
            hide.call(this.rawHostSelect);
            this.onHostHide();
        }

        this.rawHostSelect.subscribeOnOverlayKeys = () => {
            this.rawHostSelect.ref.keydownEvents()
                .pipe(
                    filter(() => this.rawHostSelect.isOpen),
                    takeUntil(this.rawHostSelect.destroy$),
                )
                .subscribe((event: KeyboardEvent) => {
                    if (event.key === "Escape") {
                        this.rawHostSelect.button.nativeElement.focus();
                        this.rawHostSelect.hide();
                    } // custom handlers:
                    else if (event.key.length == 1) {
                        this.search += event.key;
                    }
                    else if (event.key == "Backspace" || event.key == "Delete") {
                        this.search = this.search.slice(0, -1);
                    }
                    else if (event.key == "Clear" || event.key == "Escape") {
                        this.search = "";
                    }
                    // original handler:
                    else {
                        this.rawHostSelect.keyManager.onKeydown(event);
                    }
                    // update search:
                    this.updateSearchView();
                });
            this.rawHostSelect.keyManager.tabOut
                .pipe(takeUntil(this.rawHostSelect.destroy$))
                .subscribe(() => {
                    this.rawHostSelect.hide();
                    this.rawHostSelect.onTouched();
                });
        }

        Object.defineProperty(this.hostSelect, 'selectionView', {
            get() {
                const prototype = Object.getPrototypeOf(this);
                const propertyDescriptor = Object.getOwnPropertyDescriptor(prototype, 'selectionView');
                return this.searchable.searchView + propertyDescriptor.get.call(this);
            }
        });

    }

    ngAfterContentInit() {

        this.hostSelect.options.changes
            .pipe(
                startWith(this.hostSelect.options),
                switchMap((options: QueryList<NbOptionComponent>) => from(Promise.resolve(options))),
                takeUntil(this.rawHostSelect.destroy$),
            )
            .subscribe(() => this.onHostOptionsChanged());

        this.updateSearchView();
    }

    updateSearchView() {
        let i = 0;
        if (this.hostSelect.isOpen && this.search) {
            this.hostSelect.placeholder = this.searchView + this.placeholder;
            let searchEscaped = this.search.replace(/([(){}\[\].+*?|\\])/g, '\\$1');
            let searchRegExp = new RegExp(searchEscaped, 'i');
            this.hostSelect.options.forEach((option) => {
                if (option.value && option.content.match(searchRegExp)) {
                    if ((<any>option).originalDisabled !== undefined) { option.disabled = (<any>option).originalDisabled; (<any>option).originalDisabled = undefined; } // reset // TODO: check if this works well with dynamic options
                    (<any>option).elementRef.nativeElement.style.display = '';
                    i++;
                } else {
                    if ((<any>option).originalDisabled === undefined) (<any>option).originalDisabled = option.disabled;
                    option.disabled = true;
                    (<any>option).elementRef.nativeElement.style.display = 'none';
                }
            });
        } else { // reset all
            this.hostSelect.placeholder = this.placeholder;
            this.hostSelect.options.forEach((option) => {
                if ((<any>option).originalDisabled !== undefined) { option.disabled = (<any>option).originalDisabled; (<any>option).originalDisabled = undefined; } // reset // TODO: check if this works well with dynamic options
                (<any>option).elementRef.nativeElement.style.display = '';
                i++;
            });
        }

        if (i == 0) {
            this.hostSelect.options.forEach((option) => {
                if (option.value === undefined && option.disabled) { // show NotFound item
                    (<any>option).elementRef.nativeElement.style.display = '';
                }
            });
            this.optionGroups.forEach((group) => { // hide groups
                if (group.options.length > 0) {
                    let groupElement = (<any>(group.options.first)).elementRef.nativeElement.parentNode.firstChild;
                    groupElement.style.display = 'none';
                } else { // groups w/o entry
                    // TODO: get nativeElement of group
                    group.title = "";
                    group.disabled = true;
                }
            });
        } else {
            this.hostSelect.options.forEach((it) => {
                if (it.value === undefined && it.disabled) { // hide NotFound item
                    (<any>it).elementRef.nativeElement.style.display = 'none';
                }
            });
            this.optionGroups.forEach((group) => { // show groups with visible options
                if (group.options.length > 0) {
                    let gi = 0;
                    group.options.forEach((option) => {
                        if ((<any>option).elementRef.nativeElement.style.display != 'none') gi++;
                    })
                    let groupElement = (<any>(group.options.first)).elementRef.nativeElement.parentNode.firstChild;
                    if (gi == 0) {
                        groupElement.style.display = 'none';
                    } else {
                        groupElement.style.display = '';
                    }
                } else { // groups w/o entry
                    // TODO: get nativeElement of group
                }
            });
        }

        this.updateView();
    }

    updateView() {
        this.labelView = this.searchView + this.selectionView;
        //this.hostSelect.button.nativeElement.setAttribute('innerText', this.labelView);
        this.rawHostSelect.cd.markForCheck();
    }

    onHostTouched() {
        this.updateView();
    }

    onHostChange() {
        this.updateView();
    }

    onHostHide() {
        this.search = "";
        this.updateSearchView();
    }

    onHostOptionsChanged() {
        //this.options = this.hostSelect.options;
        this.updateSearchView();
    }

}


@Component({
    selector: "devicefilter",
    templateUrl: "./devicefilter.component.html",
    styleUrls: ["./devicefilter.component.scss"]
})
export class DeviceFilterComponent extends Localized implements OnInit, AfterViewInit {

    @Input() showLocations: boolean = true;
    @Input() showDevices: boolean = true;

    selectedLocationIDs: string[] = []; // null ||  [] = all
    selectableLocations: IDAndName[] = null;

    selectedDeviceIDs: string[] = []; // null || [] = all
    selectableDevices: IDAndName[] = null;

    constructor(
        private dataService: DataService,
        private storageService: StorageService,
        translationService: TranslationService) {
        super(translationService);
    }

    async ngOnInit() {
    }

    async ngAfterViewInit() {
        this.selectableLocations = await this.dataService.getLocationNames({ OrderBy: "Name", OrderAsc: true });
        this.changeLocations(this.storageService.getLocationFilterIDs(), this.storageService.getDeviceFilterIDs());
    }

    //@ViewChild('locations_select') locationsSelect: NbSelectComponent;
    //@ViewChild('devices_select') devicesSelect: NbSelectComponent;

    _test: string = "";
    get test(): string { return this._test; }
    set test(t: string) { this._test = t; }

    async changeLocations(locationIDs: string[], deviceIDs: string[] = null) {
        if (!this.selectableLocations) return;

        let tempSelectedLocationIDs = this.FilterBySelectable(locationIDs, this.selectableLocations);
        this.storageService.setSelectableLocationsIDs(this.selectableLocations.map(l => l.ID));

        let newSelectedLocations: string[] = [];
        if (this.selectedLocationIDs.length != 0) { // no new selected locations if all were selected
            newSelectedLocations = tempSelectedLocationIDs.filter(x => !this.selectedLocationIDs.includes(x));
        }

        tempSelectedLocationIDs = this.Normalize(tempSelectedLocationIDs, this.selectableLocations);

        this.storageService.setLocationFilterIDs(tempSelectedLocationIDs);
        // Without the timeout the dropdown does not show the initial selection :-( 23.07.2023
        // usage of timeout when setting selection due to nb-select bugs:
        // https://github.com/akveo/nebular/issues/2145
        // https://github.com/akveo/nebular/issues/1336
        // https://github.com/akveo/nebular/issues/1921
        // might be fixed in https://github.com/akveo/nebular/pull/2082
        setTimeout(() => { this.selectedLocationIDs = tempSelectedLocationIDs; }, 1);

        let tempSelectedDeviceIDs: string[] = [];
        if (deviceIDs) {
            tempSelectedDeviceIDs = deviceIDs;
        }
        else {
            tempSelectedDeviceIDs = this.selectedDeviceIDs;
            if (tempSelectedDeviceIDs.length != 0) { // no new selected devices if all were selected
                let newSelectedDevices = await this.dataService.getDeviceNames({ LocationID: newSelectedLocations });
                if (newSelectedDevices && newSelectedDevices.length > 0) {
                    let newSelectedDeviceIDs = newSelectedDevices.map(x => x.ID);
                    tempSelectedDeviceIDs.push(...newSelectedDeviceIDs);
                }
            }
        }

        this.selectableDevices = await this.dataService.getDeviceNames({ LocationID: tempSelectedLocationIDs, OrderBy: "Name", OrderAsc: true });
        this.changeDevices(tempSelectedDeviceIDs); // re-filter and re-set
    }

    async changeDevices(deviceIDs: string[]) {
        if (!this.selectableLocations || !this.selectableDevices) return;

        let tempSelectedDeviceIDs = this.FilterBySelectable(deviceIDs, this.selectableDevices);
        this.storageService.setSelectableDeviceIDs(this.selectableDevices.map(d => d.ID));
        tempSelectedDeviceIDs = this.Normalize(tempSelectedDeviceIDs, this.selectableDevices);

        this.storageService.setDeviceFilterIDs(tempSelectedDeviceIDs);
        setTimeout(() => { this.selectedDeviceIDs = tempSelectedDeviceIDs; }, 1);
    }

    private FilterBySelectable(selectedIDs: string[], selectable: IDAndName[]): string[] {
        if (selectedIDs && selectedIDs.length != 0) {
            const selectableIDs = selectable.map(x => x.ID);
            return selectedIDs.filter(x => selectableIDs.includes(x));
        }
        return [];
    }

    private Normalize(selectedIDs: string[], selectable: IDAndName[]): string[] {
        if (selectedIDs && selectedIDs.length != 0) {
            if (selectable.every(x => selectedIDs.includes(x.ID))) {
                return [];
            }
            return selectedIDs;
        }
        return [];
    }

    getLocation(locationID: string): IDAndName {
        return this.selectableLocations.find(x => x.ID === locationID);
    }

    getSelectedLocations(): IDAndName[] {
        if (this.selectedLocationIDs == null || this.selectedLocationIDs.length == 0) {
            return this.selectableLocations;
        }
        return this.selectableLocations.filter(x => this.selectedLocationIDs.includes(x.ID));
    }

    hasMatchingParent(items: Array<IDAndName>, parent: IDAndName): boolean {
        if (items == null) return false;
        return items.filter(x => x.ParentID === parent.ID).length > 0;
    }

}
