import { Pipe, PipeTransform } from '@angular/core';
import { toLocalDate } from "../../shared/date";

/**
 * See [[toLocalDate]].
 */
@Pipe({
    name: 'localDate'
})
export class LocalDatePipe implements PipeTransform {

    transform(value: Date | string, args?: any): any {
        return toLocalDate(value);
    }

}
