import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'ngx-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
