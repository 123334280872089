import { Component, OnInit } from "@angular/core";
import { Device } from "../../../shared-gen/Model/Devices/Device";

@Component({
    selector: "json-viewer",
    templateUrl: "./json-viewer.component.html",
    styleUrls: ["./json-viewer.component.scss"]
})
export class JsonViewerComponent implements OnInit {

    device: Device;

    constructor() {
    }

    ngOnInit() {

    }

}
