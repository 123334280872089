import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { LanguageCode } from 'shared-gen/Model/Localization/LanguageCode';
import { LocalizedString } from 'shared-gen/Model/Utils/LocalizedString';
import { Localized } from 'shared/localized';
import { Language } from 'shared/model/localization/language';
import { TranslationService } from 'shared/services/translation.service';

@Component({
  selector: 'localized-string-input',
  templateUrl: './localized-string-input.component.html',
  styleUrls: ['./localized-string-input.component.scss']
})
export class LocalizedStringInputComponent extends Localized implements OnInit, OnChanges {

  /**
   * Given "LocalizedString" object that is getting filled by this input.
   */
  @Input()
  localizedString: LocalizedString;
  @Output()
  localizedStringChange: EventEmitter<LocalizedString> = new EventEmitter<LocalizedString>();

  /**
   * The non-localized field value. E.g. Name vs LocalizedNames
   */
  @Input()
  value: string;
  @Output()
  valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input()
  disabled: boolean = false;
  @Input()
  localizedPlaceholder?: string;
  @Input()
  placeholder?: string;
  @Input()
  status?: string;

  /**
   * The HTML name for the value input which can be used to be referenced in labels.
   */
  @Input()
  inputName?: string;

  allLanguages: LanguageCode[] = Object.values(LanguageCode);

  isExpanded = false;

  constructor(translationService: TranslationService) {
    super(translationService);
  }

  ngOnInit(): void {
    if (!this.localizedString) {
      this.localizedString = {};
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.localizedString && !this.localizedString) {
      this.localizedString = {};
    }
  }

  onExpandButtonClicked() {
    this.isExpanded = !this.isExpanded;
  }

  getFlagUrl(languageCode: LanguageCode) {
    return 'url(/assets/images/flags/' + languageCode.toLowerCase() + '.png)';
  }

  getNumberOfFilledTranslations() {
    const validTranslations = this.allLanguages.map(languageCode => this.localizedString[languageCode]).filter(translationText => !!translationText);
    return validTranslations.length;
  }

  onValueChange() {
    this.valueChange.emit(this.value);
  }
  onLocalizedStringChange() {
    this.localizedStringChange.emit(this.localizedString);
  }

}
