/** The current redemption state of a voucher. */
export enum VoucherRedemptionState {
    /**
    * The voucher was not redeemed yet (default state). Should not appear
    *             in the Device database, but is used on the Backend database for storing
    *             newly created vouchers for example.
    */
    Open = "Open",
    /**
    * The voucher was reserved for redemption and may not be
    *             reserved by another vend, until the <see cref="!:VoucherRedemption.reservedTimeS" />
    *             is over. When synced with the server, an entry of this type
    *             must be removed again from the local database.
    */
    Reserved = "Reserved",
    /**
    * The voucher has been reserved for redemption but was then released again,
    *             so it can be used again. When synced with the server, an entry of this type
    *             must be removed again from the local database. Thus, this value should only be
    *             used on the Device side. If a voucher is released on the Backend side, store
    *             it as <see cref="F:Iovent.Model.Vouchers.VoucherRedemptionState.Open" /> again (or delete it, if not needed any more, e.g.
    *             third-party vouchers like SaniFair).
    */
    Released = "Released",
    /** The voucher was used for a successful vend and may not be used again. */
    Redeemed = "Redeemed",
    /** The voucher was cancelled by the provider and may not be used any more. */
    Revoked = "Revoked",
    /**
    * A voucher operation failed, e.g. non-existent voucher on third-party server.
    *             Used in internal logic and in voucher <see cref="T:Iovent.Events.ComponentEvent" />s, but usually
    *             not stored in <see cref="T:Iovent.Model.Vouchers.VoucherRedemption" /> history.
    */
    Failed = "Failed"
}
