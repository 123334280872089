<p class="caption status-danger" *ngIf="showMinLength"> {{("🌐Validation.MinLengthChars" | translate:lang)
    .replace("%label%", label)
    .replace("%min%", min)
    .replace("%min-length%", minLength)
    .replace("%max%", max)
    .replace("%max-length%", maxLength)
    }} </p>
<p class="caption status-danger" *ngIf="showMaxLength"> {{("🌐Validation.MaxLengthChars" | translate:lang)
    .replace("%label%", label)
    .replace("%min%", min)
    .replace("%min-length%", minLength)
    .replace("%max%", max)
    .replace("%max-length%", maxLength)
    }} </p>
<p class="caption status-danger" *ngIf="showPattern"> {{("🌐Validation.Fault" | translate:lang)
    .replace("%label%", label)
    .replace("%min%", min)
    .replace("%min-length%", minLength)
    .replace("%max%", max)
    .replace("%max-length%", maxLength)
    }} </p>
<p class="caption status-danger" *ngIf="showRequired"> {{("🌐Validation.RequiredField" | translate:lang)
    .replace("%label%", label)
    .replace("%min%", min)
    .replace("%min-length%", minLength)
    .replace("%max%", max)
    .replace("%max-length%", maxLength)
    }}</p>
<p class="caption status-danger" *ngIf="showMin">{{("🌐Validation.MinLength" | translate:lang)
    .replace("%label%", label)
    .replace("%min%", min)
    .replace("%min-length%", minLength)
    .replace("%max%", max)
    .replace("%max-length%", maxLength)
    }}</p>
<p class="caption status-danger" *ngIf="showMax">{{("🌐Validation.MaxLength" | translate:lang)
    .replace("%label%", label)
    .replace("%min%", min)
    .replace("%min-length%", minLength)
    .replace("%max%", max)
    .replace("%max-length%", maxLength)
    }}</p>
