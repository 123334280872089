<nb-card>
    <nb-card-header>{{"🌐General.PairDevice" | translate:lang}}</nb-card-header>
    <nb-card-body>

        <p>{{"🌐General.EnterDevicePairingCode" | translate:lang}}</p>

        <div class="row align-items-center my-3">
            <div class="col-3">
                <input type="text" [(ngModel)]="pairingCode1" placeholder="00" maxlength="2" (keyup)="update(1)"
                    (change)="update()" />
            </div>
            <div class="col-1">
                .
            </div>
            <div class="col-3">
                <input #input2 type="text" [(ngModel)]="pairingCode2" placeholder="00" maxlength="2" (keyup)="update(2)"
                    (change)="update()" />
            </div>
            <div class="col-1">
                .
            </div>
            <div class="col-3">
                <input #input3 type="text" [(ngModel)]="pairingCode3" placeholder="00" maxlength="2" (keyup)="update(3)"
                    (change)="update()" />
            </div>
            <div class="col-1">
            </div>
        </div>

    </nb-card-body>

    <nb-card-footer class="d-flex">

        <button nbButton status="success" (click)="save()" [disabled]="!isOK" class="w-50 mr-2">{{"🌐General.OK" |
            translate:lang}}</button>
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-50 ml-2">{{"🌐General.Cancel" |
            translate:lang}}</button>

    </nb-card-footer>
</nb-card>
