<ng-container *ngIf="locations">
    <ul>
        <li>
            <input [value]="true" type="checkbox" (change)="chooseAllLocations($event)"
                [checked]="locations.length === chosenLocations.length">
            {{"🌐Filter.Locations" | translate:lang}}
        </li>
        <li *ngFor="let location of locations" [class]="(quickLoc && quickLoc.ID == location.ID) ? 'disabled' : ''">
            <input type="checkbox" value="{{location}}" [checked]="isChosenLocation(location)"
                (click)="chooseLocation(location)"
                [disabled]="respectDeviceFilter && quickLoc && quickLoc.ID == location.ID" />
            {{location.Name}}
        </li>
    </ul>

    <ul *ngIf="devices">
        <li *ngIf="devices.length>1">
            <input [value]="true" type="checkbox" (change)="chooseAllDevices($event)"
                [checked]="devices.length === chosenDevices.length">
            {{"🌐Filter.Machines" | translate:lang}}
        </li>
        <li *ngFor="let device of devices" [class]="(quickDev && quickDev.ID == device.ID) ? 'disabled' : ''">
            <input type="checkbox" value="{{device}}" [checked]="isChosenDevice(device)" (click)="chooseDevice(device)"
                [disabled]="respectDeviceFilter && quickDev && quickDev.ID == device.ID" />
            {{device.Name}}
        </li>
    </ul>

    <div *ngIf="devices.length <=0">{{"🌐Filter.NoDevice" | translate:lang}}</div>


    <button nbButton (click)="emitOnFilter()">{{"🌐Filter.Button" | translate:lang}}</button>
</ng-container>
