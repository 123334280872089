<div class="row">
    <div *ngIf="showLocations" class="col-6 devicefilter-locations">
        <nb-select id="devicefilter" #locations_select multiple searchable [selected]="selectedLocationIDs"
            (selectedChange)="changeLocations($event)" status="primary" fullWidth
            placeholder="{{'🌐Filter.AllLocations' | translate:lang}}" [disabled]="disabled">
            <nb-option [value]="undefined" disabled>{{"🌐NotFound" | translate:lang}}</nb-option>
            <nb-option [value]="null">{{"🌐Filter.AllLocations" | translate:lang}}</nb-option>
            <nb-option *ngFor="let location of selectableLocations" [value]="location.ID">{{location.Name}} <span
                    class="ml-1" *ngIf="providerID!='witzig'">{{"[" + location.ID + "]"}}</span> </nb-option>
        </nb-select>
        <button *ngIf="selectedLocationIDs.length > 0" nbButton ghost shape="round" size="small" status="primary"
            style="background-color: white; position: absolute;  top: -1.2em; right: -1.2em;"
            (click)="changeLocations(null, null)">
            <nb-icon icon="backspace-outline"></nb-icon>
        </button>
    </div>
    <div *ngIf="showDevices" class="col-6 devicefilter-devices">
        <nb-select #devices_select multiple searchable [selected]="selectedDeviceIDs"
            (selectedChange)="changeDevices($event)" status="primary" fullWidth
            placeholder="{{'🌐Filter.AllMachines' | translate:lang}}" [disabled]="disabled || devicesDisabled">
            <nb-option [value]="undefined" disabled>{{"🌐NotFound" | translate:lang}}</nb-option>
            <nb-option [value]="null">{{"🌐Filter.AllMachines" | translate:lang}}</nb-option>
            <nb-option-group *ngFor="let location of selectableLocations | includedIn: selectedLocationIDs"
                [title]="location.Name">
                <nb-option *ngFor="let device of selectableDevices | matchesParent: location" [value]="device.ID">
                    {{device.Name}}<span class="ml-1" *ngIf="providerID!='witzig'">{{"[" + device.ID + "]"}}</span>
                </nb-option>
                <nb-option *ngIf="!hasMatchingParent(selectableDevices,location)" disabled>
                    <!--{{('🌐Filter.GroupEmpty' | translate:lang)}}-->
                </nb-option>
            </nb-option-group>
        </nb-select>
        <button *ngIf="selectedDeviceIDs.length > 0" nbButton ghost shape="round" size="small" status="primary"
            style="background: white; position: absolute;  top: -1.2em; right: -1.2em;"
            (click)="changeDevices(null)">
            <nb-icon icon="backspace-outline"></nb-icon>
        </button>
    </div>
</div>
