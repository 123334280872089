import { Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { IDAndName } from 'shared-gen/Utils/Model/IDAndName';
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";

@Component({
    selector: 'dependents-dialog',
    templateUrl: './dependents-dialog.component.html',
    styleUrls: ['./dependents-dialog.component.scss']
})
export class DependentsDialogComponent extends Localized {
    public items: IDAndName[];

    openDeviceDetail(item: IDAndName) {
        this.router.navigate(["pages/devices/detail/" + item.ID]);
        this.dialogRef.close();
    }

    constructor(public dialogRef: MatDialogRef<DependentsDialogComponent>, private router: Router, translationService: TranslationService) {
        super(translationService)
    }
}
