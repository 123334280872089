import { Component, Input } from "@angular/core";
import { Localized } from "shared/localized";
import { TranslationService } from "shared/services/translation.service";
import { DoorVerb } from "shared-gen/Model/Events/DoorVerb";
import { Door } from "shared-gen/Model/Events/Door";
import { PeripheralType } from "shared-gen/Payment/Peripherals/PeripheralType";
import { PeripheralStatus } from "shared-gen/Payment/PeripheralStatus";
import { PortalDeviceTableItemDeviceReport } from "shared-gen/Model/Devices/Views/PortalDeviceTableItemDeviceReport";

@Component({
    selector: "device-warnings",
    templateUrl: "./device-warnings.component.html",
    styleUrls: ["./device-warnings.component.scss"]
})
export class DeviceWarningsComponent extends Localized {

    @Input() deviceReport: PortalDeviceTableItemDeviceReport = null;

    constructor(translationService: TranslationService) {
        super(translationService);
    }

    getAllDoors(): Door[] {
        return Object.keys(this.deviceReport?.Doors ?? {}) as Door[];
    }

    isDoorOpen(door: Door): boolean {
        return (this.deviceReport?.Doors ?? {})[door] == DoorVerb.Opened;
    }

    isChangeLow(): boolean {
        return this.deviceReport?.IsChangeLow ?? false;
    }

    isOfflineVoucherAccepted(): boolean {
        return this.deviceReport?.IsOfflineVoucherAccepted ?? true;
    }

    getAllPeripherals(): PeripheralType[] {
        const peripherals = this.deviceReport?.Peripherals;
        return Object.keys(peripherals ?? {}) as PeripheralType[];
    }

    isPeripheralNotOnline(peripheral: PeripheralType): boolean {
        return (this.deviceReport?.Peripherals ?? {})[peripheral] != PeripheralStatus.Online ?? false;
    }

}
