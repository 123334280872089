<nb-card>

    <nb-card-header>{{"🌐General.DeviceUsage" | translate:lang}}</nb-card-header>

    <nb-card-body>
        <nb-list>
            <nb-list-item *ngFor="let item of items" (click)="openDeviceDetail(item)">
                {{ item.Name }}
            </nb-list-item>
        </nb-list>
    </nb-card-body>


    <nb-card-footer class="d-flex">
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-100 mr-2">{{"🌐General.Cancel" |
            translate:lang}}</button>
    </nb-card-footer>

</nb-card>
