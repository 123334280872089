<div [class]="'header-container justify-content-between w-100 ' + (!env.production ? 'staging' : 'prod')">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()" style="line-height: 0">
            <img src="assets/images/iovent-text-logo.png?v11.6" style="height: 55px; width: auto;" />
            <span *ngIf="env.testWarning" class="test-warning">{{env.testWarning}}</span>
        </a>
    </div>
    <nb-select *ngIf="showThemeSelection" [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
        <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
    </nb-select>
    <div class="d-flex align-items-center">
        <nb-select [attr.data-selectedLanguage]="selectedLanguage.toLowerCase()" [(selected)]="selectedLanguage" (selectedChange)="changeLanguage(selectedLanguage)"
            class="mr-2 language-select" status="primary">
            <nb-option *ngFor="let selectLanguage of selectLanguages" [value]="selectLanguage" class="language-select__option">
                <img [src]="'/assets/images/flags/'+selectLanguage.toLowerCase()+'.png'">{{selectLanguage}}
            </nb-option>
        </nb-select>
        <nb-actions size="small">

            <!--
      <nb-action class="control-item">
        <nb-search type="rotate-layout"></nb-search>
      </nb-action>
      <nb-action class="control-item" icon="email-outline"></nb-action>
      <nb-action class="control-item" icon="bell-outline"></nb-action>
      -->
            <nb-action class="user-action" *nbIsGranted="['view', 'current-user']">
                <nb-user id="nb-user" [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="user?.ioventUser?.ID" picture="assets/images/user-icon.png">
                    <!--ngx-admin original: [picture]="user?.picture | ngxAuthToken | async" -->
                </nb-user>
            </nb-action>
        </nb-actions>
    </div>
</div>