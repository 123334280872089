<div class="row">
    <div class="col-12 col-sm-12">
        <nb-card class="nb-nested-card">
            <nb-card-header>{{("🌐Reports.CashBalance.ArticleOverviewVAT" |
                translate:lang).replace("%vat%", products.Vat)}}</nb-card-header>
            <nb-card-body>
                <table class="simple-table">
                    <tr>
                        <ng-container *ngIf="provider == 'tur'">
                            <th>{{"🌐Reports.CashBalance.SAPArticleBarcode" | translate:lang}}</th>
                            <th>{{"🌐Reports.CashBalance.SAPArticleNumber" | translate:lang}}</th>
                        </ng-container>
                        <ng-container *ngIf="provider != 'tur'">
                            <th>{{"🌐Reports.CashBalance.ProductCustomID" | translate:lang}}</th>
                        </ng-container>
                        <th>{{"🌐Reports.CashBalance.Description" | translate:lang}}</th>
                        <th>{{"🌐Reports.CashBalance.UnitPrice" | translate:lang}}</th>
                        <th *ngIf="showDeposit">{{"🌐Reports.CashBalance.Deposit" | translate:lang}}
                        </th>
                        <th [innerHTML]="translate('🌐Reports.CashBalance.CountWithoutTokens')">
                        </th>
                        <th>{{"🌐Reports.CashBalance.CountInCash" | translate:lang}}</th>
                        <th>{{"🌐Reports.CashBalance.ShareInCash" | translate:lang}}</th>
                        <th>{{"🌐Reports.CashBalance.CountContactless" | translate:lang}}</th>
                        <th>{{"🌐Reports.CashBalance.ShareContactless" | translate:lang}}</th>

                        <th>{{"🌐Reports.CashBalance.CountOfVoucher" | translate:lang}}</th>
                        <th>{{"🌐Reports.CashBalance.VoucherValue" | translate:lang}}</th>

                        <ng-container *ngIf="false">
                            <!-- // Removed 2021-12 by request of TuR (#521; CR 0021)
											<th>{{"🌐Reports.CashBalance.CountSmallToken" | translate:lang}}</th>
											<th>{{"🌐Reports.CashBalance.CountMediumToken" | translate:lang}}</th>
											<th>{{"🌐Reports.CashBalance.CountLargeToken" | translate:lang}}</th>
											<th>{{"🌐Reports.CashBalance.ShareValueToken" | translate:lang}}</th>
											-->
                        </ng-container>

                        <th *ngIf="showDeposit">{{"🌐Reports.CashBalance.TotalDeposit" |
                            translate:lang}}</th>
                        <th [innerHTML]="translate('🌐Reports.CashBalance.SalesIncludingVAT' + (showDeposit ? 'IncludingDeposit' : ''))">
                        </th>
                    </tr>
                    <tr *ngFor="let product of products.Products">

                        <ng-container *ngIf="provider == 'tur'">
                            <td>
                                <span class="d-inline-block text-center" *ngIf="getTurProductExtensionData(product)?.EanCode">
                                    <img class="barcode" src="{{eanUrl}}/{{getTurProductExtensionData(product)?.EanCode}}" /><br />
                                    {{getTurProductExtensionData(product)?.EanCode}}
                                </span>
                            </td>
                            <td>{{ getTurProductExtensionData(product)?.SapNumber }}</td>
                        </ng-container>
                        <ng-container *ngIf="provider != 'tur'">
                            <td>{{ product.ProductCustomID }}</td>
                        </ng-container>

                        <td>{{ product.Name }}</td>
                        <td>{{ product.Price / 100 | iocurrency:currency }}</td>
                        <td *ngIf="showDeposit">{{ product.Deposit / 100 | iocurrency:currency }}
                        </td>
                        <td>{{ product.CountWithoutTokens }}</td>
                        <td>{{ product.HardCashCount }}</td>
                        <td>{{ product.HardCash / 100 | iocurrency:currency }}</td>
                        <td>{{ product.CashlessCount }}</td>
                        <td>{{ product.Cashless / 100 | iocurrency:currency }}</td>

                        <td>
                            <pre [innerHTML]="separateByValue(product.CustomVouchersByValue, 'Count')"></pre>
                        </td>
                        <td>
                            <pre [innerHTML]="separateByValue(product.CustomVouchersByValue, 'Total')"></pre>
                        </td>

                        <ng-container *ngIf="false">
                            <!-- // Removed 2021-12 by request of TuR (#521; CR 0021)
											<td>{{ product.TokensSmallCount }}</td>
											<td>{{ product.TokensMediumCount }}</td>
											<td>{{ product.TokensLargeCount }}</td>
											<td>{{ product.TokensTotal / 100 | iocurrency:currency }}</td>
											-->
                        </ng-container>

                        <td *ngIf="showDeposit">{{ product.TotalDeposit / 100 | iocurrency:currency
                            }}</td>
                        <td>{{ product.Sales / 100 | iocurrency:currency }}</td>
                    </tr>
                    <tr>

                        <th>{{"🌐Reports.CashBalance.Total" | translate:lang}}</th>
                        <ng-container *ngIf="provider == 'tur'">
                            <th></th>
                            <th></th>
                        </ng-container>
                        <ng-container *ngIf="provider != 'tur'">
                            <th></th>
                        </ng-container>
                        <th></th>
                        <th *ngIf="showDeposit"></th>

                        <th>{{ products.Total.CountWithoutTokens }}</th>
                        <th>{{ products.Total.HardCashCount }}</th>
                        <th>{{ products.Total.HardCash / 100 | iocurrency:currency }}</th>
                        <th>{{ products.Total.CashlessCount }}</th>
                        <th>{{ products.Total.Cashless / 100 | iocurrency:currency }}</th>

                        <th>
                            <pre [innerHTML]="separateByValue(products.Total.CustomVouchersByValue, 'Count')"></pre>
                        </th>
                        <th>
                            <pre [innerHTML]="separateByValue(products.Total.CustomVouchersByValue, 'Total')"></pre>
                        </th>

                        <ng-container *ngIf="false">
                            <!-- // Removed 2021-12 by request of TuR (#521; CR 0021)
											<th>{{ products.Total.TokensSmallCount }}</th>
											<th>{{ products.Total.TokensMediumCount }}</th>
											<th>{{ products.Total.TokensLargeCount }}</th>
											<th>{{ products.Total.TokensTotal / 100 | iocurrency:currency }}</th>
											-->
                        </ng-container>

                        <th *ngIf="showDeposit">{{ products.Total.TotalDeposit / 100 |
                            iocurrency:currency }}</th>
                        <th>{{ products.Total.Sales / 100 | iocurrency:currency }}</th>
                    </tr>
                </table>
            </nb-card-body>
        </nb-card>
    </div>
</div>