import { Component, Input } from '@angular/core';
import { UserStore } from 'app/@core/stores/user.store';
import { TurProductX } from 'shared-gen/Model/Products/TurProductX';
import { CustomVoucherInfoByValue } from 'shared-gen/Model/Reports/CustomVoucherInfoByValue';
import { ProductGroup } from 'shared-gen/Model/Reports/ProductGroup';
import { ProductsByVat } from 'shared-gen/Model/Reports/ProductsByVat';
import { Currency } from 'shared-gen/Model/Utils/Currency';
import { Localized } from 'shared/localized';
import { getExtensionData } from 'shared/model/extensions/extensions';
import { toCurString } from 'shared/payment/currencies';
import { TranslationService } from 'shared/services/translation.service';
import { environment } from "../../../../environments/runtime-environment";

@Component({
    selector: 'article-sales-table',
    templateUrl: './article-sales-table.component.html',
    styleUrls: ['./article-sales-table.component.scss']
})
export class ArticleSalesTableComponent extends Localized {
    @Input() products: ProductsByVat;
    @Input() showDeposit: boolean;
    @Input() currency: Currency;
    provider: string;
    eanUrl = environment().backendUrl + "/utils/barcode/ean";

    constructor(translationService: TranslationService, userStore: UserStore) {
        super(translationService);
        this.provider = userStore.getIoventUser().ProviderID;
    }

    separateByValue(valueList: Array<CustomVoucherInfoByValue>, property: string): string {
        const countOrTotal = property == 'Count'
        if (!valueList.length)
            return countOrTotal ? '0' : toCurString(0, this.currency)

        return valueList.sort((a, b) => a.Value - b.Value)
            .map(it => {
                const voucherValue = toCurString(it.Value / 100.0, this.currency)
                let propValue = it[property]
                propValue = countOrTotal ? propValue + 'x' : toCurString(propValue / 100.0, this.currency)
                return `${voucherValue}: ${propValue}<br/>`
            })
            .join('\n')
    }

    getTurProductExtensionData(productGroup :ProductGroup) {
        return getExtensionData<TurProductX>(productGroup, 'TurProductX');
    }

}
