/**
* A resource needed for a product. This can be an ingredient like
*             coffee and milk or a glass like a small or medium cup.
*             Flags so that Mix = BeansRight | BeansLeft
*/
export enum Resource {
    None = "None",
    /** Coffee beans from right/front hopper, usually  espresso beans */
    BeansRight = "BeansRight",
    /** Coffee beans from left/rear hopper, usually coffee creme beans */
    BeansLeft = "BeansLeft",
    /** Coffee/Chocolate/Chai from powder chute ? */
    PowderChute = "PowderChute",
    /** Milk from feed 1, usually regular milk */
    Milk = "Milk",
    /** Milk from feed 2, usually special milk */
    Milk2 = "Milk2",
    /** Milk from feed 3, usually special milk */
    Milk3 = "Milk3",
    /** Powder from powder dispenser 1, usually chocolate */
    Powder = "Powder",
    /** Powder from powder dispenser 2 */
    Powder2 = "Powder2",
    /** Cleaning detergent */
    Detergent = "Detergent",
    /** Cleaning atbs (everballs) */
    Tabs = "Tabs",
    /**
    * Espresso beans (in the TuR Coffeetime 2.0 setup).
    *             Will be removed later. Use <see cref="F:Iovent.Model.Products.Resource.BeansRight" /> instead.
    */
    Espresso = "Espresso",
    /**
    * Cafe creme beans (in the TuR Coffeetime 2.0 setup).
    *             Will be removed later. Use <see cref="F:Iovent.Model.Products.Resource.BeansLeft" /> instead.
    */
    CafeCreme = "CafeCreme",
    /**
    * Chocolate
    *             Will be removed later. Use <see cref="F:Iovent.Model.Products.Resource.Powder" /> instead.
    */
    Chocolate = "Chocolate"
}
