/** Enum for the <see cref="T:Iovent.Model.Utils.Entity" /> subtypes. */
export enum EntityType {
    Image = "Image",
    ProductBase = "ProductBase",
    Menu = "Menu",
    MobileMenu = "MobileMenu",
    Prices = "Prices",
    Selections = "Selections",
    DeviceConfig = "DeviceConfig",
    Location = "Location",
    Device = "Device",
    CashOnHand = "CashOnHand",
    FillLevels = "FillLevels",
    User = "User",
    Campaign = "Campaign"
}
