import { Component, Input } from '@angular/core';
import { UserStore } from 'app/@core/stores/user.store';
import { TransactionsReport } from 'shared-gen/Model/Reports/TransactionsReport';
import { Currency } from 'shared-gen/Model/Utils/Currency';
import { Localized } from 'shared/localized';
import { TranslationService } from 'shared/services/translation.service';

@Component({
    selector: 'article-sales-overview',
    templateUrl: './article-sales-overview.component.html',
    styleUrls: ['./article-sales-overview.component.scss']
})
export class ArticleSalesOverviewComponent extends Localized {

    @Input() report: TransactionsReport;
    @Input() showDeposit: boolean;
    @Input() currency: Currency;
    provider: string;

    constructor(translationService: TranslationService, userStore: UserStore) {
        super(translationService);
        this.provider = userStore.getIoventUser().ProviderID;
    }

    getAllVats(): string {
        return this.report.ProductsByVat.map(it => it.Vat + "%").join(
            " " + this.translate("🌐Reports.CashBalance.And") + " ") + " " +
            this.translate("🌐Reports.CashBalance.VAT");
    }

}
