import { Component, Input } from "@angular/core";
import { Access } from "shared-gen/Model/Auth/Access";
import { Feature } from "shared-gen/Model/Auth/Feature";
import { ProductInstance } from "shared-gen/Model/Products/ProductInstance";
import { WitzigEversysConstants } from "shared-gen/Model/WitzigEversysConstants";
import { ProductBaseFun } from "shared/model/products/product-base.fun";
import { environment } from "../../../../../../../environments/runtime-environment";
import { Choice } from "../../../../../../../shared-gen/Model/Products/Choice";
import { Option } from "../../../../../../../shared-gen/Model/Products/Option";
import { ProductBase } from "../../../../../../../shared-gen/Model/Products/ProductBase";
import { ProductType } from "../../../../../../../shared-gen/Model/Products/ProductType";
import { Resource } from "../../../../../../../shared-gen/Model/Products/Resource";
import { TurProductX } from "../../../../../../../shared-gen/Model/Products/TurProductX";
import { Localized } from "../../../../../../../shared/localized";
import { getExtensionData } from "../../../../../../../shared/model/extensions/extensions";
import { TranslationService } from "../../../../../../../shared/services/translation.service";
import { swapInArray } from "../../../../../../../shared/utils/core-utils";
import {ProductInstanceFun} from "../../../../../../../shared/model/products/product-instance.fun";
import {
    decodeEscapeCodesToEditor,
    encodeEscapeCodesFromEditor
} from "../../../../../../../shared/utils/text-escape-codes";
import { UserStore } from "app/@core/stores/user.store";
import { DataService } from "app/services/data.service";
import { arrayBufferToBase64 } from "../../../../../../../shared/utils/buffer-utils";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'ngx-product-instance',
    templateUrl: './product-instance.component.html',
    styleUrls: ['./product-instance.component.scss']
})
export class ProductInstanceComponent extends Localized {

    @Input() isEditable = false;

    projectName = environment().projectName;
    currentInstance: ProductInstance;
    currentBase: ProductBase;

    is2ndMilkVisible = false;
    resources: Resource[] = [];

    private instanceOptions: string[] = [];

    orderProductQrCodeImage: SafeResourceUrl | undefined = undefined;

    get isResource(): boolean { return this.currentBase.ProductType.toString().startsWith('Resource'); }



    @Input()
    set productInstance(product: ProductInstance) {
        this.currentInstance = product;
        this.init();
    }

    get productInstance(): ProductInstance {
        return this.currentInstance;
    }

    @Input()
    set productBase(productBase: ProductBase) {
        this.currentBase = productBase;
        this.init();
    }

    get productBase() {
        return this.currentBase;
    }

    constructor(translationService: TranslationService,
        private userStore: UserStore, private dataService: DataService, private sanitizer: DomSanitizer) {
        super(translationService);
    }

    private init() {
        if (this.currentInstance) {
            if (this.currentBase) {
                this.addMissingData();
            }
        }
        // 2nd milk sort
        this.is2ndMilkVisible = this.userStore.hasRights(Feature.SecondMilkSort, Access.Read);
        this.resources = [Resource.BeansLeft, Resource.BeansRight, Resource.Milk, Resource.Milk2, Resource.Chocolate]
            .filter(it => this.is2ndMilkVisible ? true : it != Resource.Milk2);

        
    }

    addMissingData() {
        if (this.currentInstance.Resources === null) {
            this.currentInstance.Resources = [];
        }
        this.instanceOptions = [];
        for (let option of this.currentBase.Options) {
            this.instanceOptions[option.ID] = this.getChoiceOfOptionFromID(option);
        }

        if (this.isResource) {
            if (!this.productInstance.MachineResourceFactor) this.productInstance.MachineResourceFactor =
                (this.isFluidResourceProduct ? WitzigEversysConstants.milk_ml_per_s : WitzigEversysConstants.coffee_g_per_mm);
            if (!this.productInstance.SingleMachineResourceAmount) this.productInstance.SingleMachineResourceAmount = 10;
            if (!this.productInstance.DoubleMachineResourceAmount) this.productInstance.DoubleMachineResourceAmount = 20;
        }
        
        this.orderProductQrCodeImage = undefined;
        
    }

    isValidID(id: string): boolean {
        return ProductInstanceFun.isValidID(id);
    }

    private isIdUsedByOtherInstance(id: string): boolean {
        return this.currentBase.Instances.findIndex(it => it.ID === id) !== -1 && this.currentInstance.ID !== id;
    }

    async triggerGenerateQrCommandOrderProduct() {
        const efectiveProductId = this.currentInstance.ID ?? this.currentBase.ID;
        const imageBuffer = await this.dataService.getQRCommandOrderProduct(efectiveProductId);
        this.orderProductQrCodeImage = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${arrayBufferToBase64(imageBuffer)}`)
    
    }

    getChoiceOfOptionFromID(option: Option) {
        const splitted = this.currentInstance.ID.split('-');
        const index = this.currentBase.Options.findIndex(it => {
            return option.ID === it.ID;
        });
        return splitted[index + 1];
    }

    changeChoiceInID(option: Option, choiceID: string) {
        const splitted = this.currentInstance.ID.split('-');
        const index = this.currentBase.Options.findIndex(it => {
            return option.ID === it.ID;
        });

        splitted[index + 1] = choiceID;

        return splitted.join('-');
    }

    choiceAvailable(option: Option, choice: Choice) {
        return !this.isIdUsedByOtherInstance(this.changeChoiceInID(option, choice.ID));
    }

    changeOption(option: Option, choiceID: string) {
        this.instanceOptions[option.ID] = choiceID;
        let newID = this.changeChoiceInID(option, choiceID);
        if (choiceID == "?" || !this.isIdUsedByOtherInstance(newID)) {
            this.currentInstance.ID = newID;
            return true;
        }
        return false;
    }

    changeResource(resource: Resource) {
        swapInArray(this.currentInstance.Resources, resource);
    }

    changeVat(vat) {
        this.currentInstance.Vat = vat;
    }

    get isAddMode(): boolean {
        // if we are creating a new product the hash is not yet filled
        return !!this.productBase.Hash;
    }

    get customID(): string | null {
        return this.productInstance?.CustomID;
    }

    set customID(customID: string) {
        if (this.productInstance)
            this.productInstance.CustomID = (customID.length == 0 ? null : customID);
    }

    getTurExtension(init = false): TurProductX | null {
        return getExtensionData<TurProductX>(this.currentInstance, "TurProductX", init);
    }

    get sapNumber(): string | null {
        return this.getTurExtension()?.SapNumber;
    }
    set sapNumber(value: string) {
        this.getTurExtension(true).SapNumber = value;
    }

    get eanCode(): string | null {
        // ean code is now available for all providers directly in the product instance.
        // if there is no ean code present we answer the ean code from tur extension
        return this.productInstance.EanCode ?? this.getTurExtension()?.EanCode;
    }
    set eanCode(value: string) {
        // ean code is now available for all providers
        this.productInstance.EanCode = value;
        // in case of tur we store in addition the value in the tur extension which became deprecated meanwhile.
        // But in order to stay backwards compatible we store the value twice.
        if (this.projectName == 'tur') {
            this.getTurExtension(true).EanCode = value;
        }
    }

    get currentInstanceTotalPrice() {
        const { Price, Deposit } = this.currentInstance;
        return Price + Deposit;
    }

    set currentInstanceTotalPrice(value: number) {
        const { Deposit } = this.currentInstance;
        this,this.currentInstance.Price = Math.max(0, value - Deposit);
    }
 


    get isFluidResourceProduct(): boolean {
        return ProductBaseFun.isFluidResourceProduct(this.productBase);
    }

    get machineResourceUnit(): string {
        return ProductBaseFun.machineResourceUnitFromProduct(this.productBase);
    }

    get smallResourceUnit(): string {
        return ProductBaseFun.smallResourceUnitFromProduct(this.productBase);
    }

    get largeResourceUnit(): string {
        return ProductBaseFun.largeResourceUnitFromProduct(this.productBase);
    }

    translateResource(resource: Resource): string {
        if (resource == Resource.Milk && false == this.is2ndMilkVisible) {
            // "1st milk" looks odd when there is only one milk sort available. Show only "milk" then.
            return this.translate('🌐General.Milk');
        } else {
            return this.translate("🌐Resource." + resource);
        }
    }

}
