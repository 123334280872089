import { ChangeDetectorRef, Directive, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NbSortDirection, NbSortRequest } from '@nebular/theme';
import { User } from "app/@core/interfaces/common/users";
import { UserStore } from "app/@core/stores/user.store";
import { IoventTableComponent } from "app/components/iovent-table/iovent-table.component";
import { Subscription } from "rxjs";
import { sleep } from "shared/utils/core-utils";
import { environment } from "../../environments/runtime-environment";
import { Localized } from "../../shared/localized";
import { TranslationService } from "../../shared/services/translation.service";
import { Suggestion } from "../components/iovent-search/iovent-search.component";
import { DataService, ListOptions, ListResponse } from '../services/data.service';
import { StorageService } from "../services/storage.service";
import { getUrl } from "./core";

@Directive()
export class TableHelper<T = any> extends Localized implements OnInit, OnDestroy {

    tableName = "";

    env = environment();

    result: ListResponse<T>;

    PageSize: number = 25;

    sortBy: string = "?";
    orderAsc: boolean = false;

    searchQuery: string = "";
    searchTerm: string = "";
    searchSuggestions: Suggestion[] = [];

    subscription: Subscription = new Subscription();

    initialLoadDone: boolean = false;

    user: User = undefined;

    @ViewChild(IoventTableComponent) table: IoventTableComponent<T>;

    constructor(
        protected userStore: UserStore,
        protected dataService: DataService,
        protected storageService: StorageService,
        protected route: ActivatedRoute,
        protected cdRef: ChangeDetectorRef,
        translationService: TranslationService,
    ) {
        super(translationService);
    }

    async ngOnInit() {

        await sleep(100); // Somehow TranslationService constructor does not initialize  .language

        let i = 0;
        while (true) {
            i++;
            if (i > 30) {
                location.reload();
                throw new Error("User not initialized");
            }
            console.log("Try get user data");
            this.user = this.userStore.getUser();
            if (this.user)
                break
            await sleep(1000);
        };

        this.updateColumns();

        const sortBy = this.storageService.getValue(this.getStorageKey("sortBy"));
        if (sortBy && sortBy != "unknown") {
            console.log("read stored sortBy: " + sortBy);
            if (sortBy == "Date")
                this.sortBy = "ZoneDate"; // to use indices
            this.sortBy = sortBy;
        }

        const orderAsc = this.storageService.getValue(this.getStorageKey("orderAsc"));
        if (orderAsc != null) {
            console.log("read stored orderAsc: " + orderAsc);
            this.orderAsc = orderAsc;
        }

        const searchterm = this.storageService.getValue(this.getStorageKey("searchterm"));
        if (searchterm && searchterm != "unknown") {
            console.log("read stored searchterm: " + searchterm);
            this.searchTerm = searchterm;
        }

        this.subscription.add(this.storageService.filter.subscribe(it => {
            if (it != null) {
                this.load(1);
            }
        }));

        // NOTE: some initializations are delayed and trigger a load via filter change.
        // This is a fallback if that does not happen:
        setTimeout(() => {
            if (!this.initialLoadDone)
                this.load();
        }, 0);
    }

    ngAfterViewInit() {
        this.updateColumns();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        if (this.subscription)
            this.subscription.unsubscribe();
    }

    get currentPageIndex() {
        return (this.result?.PageIndex ?? 0) + 1;
    }

    protected getStorageKey(type: string = "current_page") {
        const url = getUrl(this.route.snapshot);
        return url + "_" + type;
    }

    async load(page = 0) {
        this.initialLoadDone = true;
        const key = this.getStorageKey();

        if (page < 0) page = 0;

        if (page !== 0) {
            this.storageService.setValue(key, page);
        } else {
            const _page = this.storageService.getValue(key);
            if (_page) {
                page = _page;
            }
        }

        await this.core_load(page);

        // reset page if out of range
        if (page != 1 && this.result.TotalCount != -1 && this.result.TotalCount < this.result.PageIndex * this.result.PageSize) {
            await this.load(1);
        }
    }

    async core_load(page = 0) {
        this.result = await this.dataService.getPagedListOfDataType("events", this.getListOptions(page), {}, "filtered"); // sample
    }

    getListOptions(page: number, pagesLimit?: number): ListOptions {
        let options = {
            OrderAsc: this.orderAsc,
            OrderBy: encodeURIComponent(this.sortBy),
            PageIndex: Math.max(0, page - 1),
            PageSize: this.PageSize
        };

        if (pagesLimit != null) {
            options["PagesLimit"] = pagesLimit;
        }

        if (this.searchTerm && this.searchTerm != "") {
            options["Fulltext"] = encodeURIComponent(this.searchTerm);
        }

        if (this.searchQuery && this.searchQuery != "") {
            options["Search"] = encodeURIComponent(this.searchQuery);
        }
        return options;
    }

    // legacy? removeable?
    sortedBy(field) {
        if (field == this.sortBy) {
            return "sorted-" + (this.orderAsc ? "asc" : "desc");
        }
        return "";
    }

    async sort(field: string) {
        if (this.sortBy == field) {
            this.orderAsc = !this.orderAsc;
        } else {
            this.orderAsc = true;
        }
        this.sortBy = field;
        await this.sortCore();
    }

    async sortChanged(sortRequest: NbSortRequest) {
        this.sortBy = sortRequest.column;
        this.orderAsc = sortRequest.direction == NbSortDirection.ASCENDING;;
        await this.sortCore();
    }

    async sortCore() {
        this.storageService.setValue(this.getStorageKey("sortBy"), this.sortBy);
        this.storageService.setValue(this.getStorageKey("orderAsc"), this.orderAsc);
        console.log("sortBy " + this.sortBy + " " + (this.orderAsc ? "asc" : "desc"));
        await this.load(); // stay on page
    }

    async pageChanged(page) {
        if (page === 0) {
            this.storageService.deleteValue(this.getStorageKey());
        }
        await this.load(page);
    }

    async onPageSizeChange(pageSize: number) {
        this.PageSize = pageSize;
        await this.load(1);
    }

    async search(term) {
        this.searchTerm = term;
        this.storageService.setValue(this.getStorageKey("searchterm"), term);
        await this.load(1);
    }

    async query(query) {
        this.searchQuery = query;
        // this.storageService.setValue(this.getStorageKey("searchquery"), query); // TODO, per page ?
        await this.load(1);
    }

    async editColumns(event: { Column: string, Op: "hide" | "show" | "left" | "right" | "refresh" }) {
        if (event.Op != "refresh") {
            var newColumns = await this.dataService.saveUserColumns(this.tableName, event.Column, event.Op, this.getDefaultColumns());
        }
        //this.cdRef.detectChanges();
        //this.table.triggerChangdeDetection();
        this.updateColumns();
    }

    updateColumns() {
        this.defaultColumns = this.getDefaultColumns();
        this.extraColumns = this.getExtraColumns();

        this.user = this.userStore.getUser();
        if (this.user) {
            let userColumns: string[] = this.user.ioventUser.PortalSettings.Columns?.[this.tableName];
            //console.log(user.ioventUser.PortalSettings);
            console.log("userColumns: " + userColumns?.join(", "));
            if (userColumns && userColumns.length > 0)
                this.columns = userColumns;
            else
                this.columns = this.defaultColumns;

            //console.log("TH columns: " + this.columns?.join(", "));
            this.cdRef?.detectChanges();
        }
    }

    defaultColumns: string[];
    extraColumns: string[];
    columns: string[];

    getDefaultColumns(): string[] {
        let x = this.table?.availableColumnTemplates.map(x => x.Column);
        //console.log("TH GetDefaultColumns: " + x?.join(", "));
        return x;
    }

    getExtraColumns(): string[] {
        return [];
    }

}
