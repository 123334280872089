<nb-card>
    <nb-card-header>{{"🌐MenuSettings.Title" | translate:lang }}</nb-card-header>
    <nb-card-body>

        <div class="row align-items-center my-3">
            <div class="col-3">
                <label for="menu-id" class="mb-0">{{"🌐MenuSettings.ID" | translate:lang }}</label>
            </div>
            <div class="col-9">
                <input id="menu-id" type="text" [(ngModel)]="menu.ID" name="id" placeholder="ID" nbInput fullWidth
                    (keyup)="checkID()" [status]="'primary'" />
                <p *ngIf="!validID" class="error mb-0">{{"🌐MenuSettings.InvalidID" | translate:lang }}</p>
            </div>
        </div>

        <div class="row align-items-center my-3">
            <div class="col-3">
                <label for="menu-name" class="mb-0">{{"🌐MenuSettings.Name" | translate:lang }}</label>
            </div>

            <div class="col-9">
                <input id="menu-name" type="text" [(ngModel)]="menu.Name" name="name" placeholder="Name" nbInput
                    fullWidth [status]="'primary'" />
                <p *ngIf="!menu.Name" class="warning mb-0">{{"🌐MenuSettings.EnterName" | translate:lang }}</p>
            </div>
        </div>

        <hr />

        <div class="row align-items-center my-3">
            <div class="col-10">
                <label class="mb-0">{{"🌐MenuSettings.GeneratedPage" | translate:lang }}</label>
            </div>
            <div class="col-2 pl-0">
                <nb-checkbox status="info" [(ngModel)]="menu.AutoStartPage"></nb-checkbox>
            </div>
        </div>

        <div class="row align-items-center my-3">
            <div class="col-3">
                <label class="mb-0">{{"🌐Menu.Columns" | translate:lang}}</label>
            </div>

            <div class="col-9">
                <nb-select [(ngModel)]="menu.AutoStartPageColumns" [disabled]="!menu.AutoStartPage"
                    [status]="menu.AutoStartPage?'primary':'basic'">
                    <nb-option [value]="2">2</nb-option>
                    <nb-option [value]="3">3</nb-option>
                    <nb-option [value]="4">4</nb-option>
                    <nb-option [value]="5">5</nb-option>
                    <nb-option [value]="6">6</nb-option>
                    <nb-option [value]="7">7</nb-option>
                    <nb-option [value]="8">8</nb-option>
                </nb-select>
            </div>
        </div>

    </nb-card-body>
    <nb-card-footer class="d-flex">
        <button nbButton status="basic" (click)="dialogRef.close(false)" *ngIf="!disableClose"
            class="w-100 mr-2">{{"🌐General.Cancel" | translate:lang }}</button>
        <button nbButton status="success" (click)="save()" [disabled]="!validID"
            class="w-100 ml-2">{{"🌐General.Confirm" | translate:lang }}</button>
    </nb-card-footer>
</nb-card>
