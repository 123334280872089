import { Component, OnInit } from '@angular/core';
import { DataService } from 'app/services/data.service';
import { ioventVersion } from "../../../../shared/iovent";

@Component({
    selector: 'ngx-version-dialog',
    templateUrl: './version-dialog.component.html',
    styleUrls: ['./version-dialog.component.scss']
})
export class VersionDialogComponent implements OnInit {

    portalVersion = "";
    backendVersion = "";

    constructor(private dataService: DataService) { }

    async ngOnInit() {
        this.backendVersion = await this.dataService.getBackendVersion();
        this.portalVersion = ioventVersion;
    }

}
