import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TranslatePipe } from "../../shared/pipes/translate.pipe";
import { CurrencyPipe } from "../../shared/pipes/currency.pipe";
import { LocalDatePipe } from "../../shared/pipes/local-date.pipe";
import { ZoneDatePipe } from "../../shared/pipes/zone-date.pipe";

const PIPES = [
    CurrencyPipe,
    TranslatePipe,
    LocalDatePipe,
    ZoneDatePipe
];

@NgModule({
    declarations: [...PIPES],
    imports: [CommonModule],
    exports: [...PIPES]
})

export class PipesModule {
}
