<ng-container *ngIf="state">
    <span>
        <span (click)="openDetails()">
            <!-- VMC state -->
            <span class="vmcstate-circle-outer">
                <span [class]="'vmcstate-circle vmcstate-circle--'+state"></span>
                <span [class]="'vmcstate-circle vmcstate-circle--'+stateOnline"></span>
                <span [class]="'vmcstate-circle vmcstate-circle--'+stateActivated"></span>
                <span class="vmcstate-circle" [nbTooltip]="tooltip"></span>
            </span>
            <!-- Connectivity: Type -->
            <ng-container *ngIf="showConnectivity">
                <nb-icon [icon]="connectivityIcon" class="connectivity connectivity-type" pack="custom-icons" nbTooltip="{{ 'General.ConnectionType' | translate:lang }}:
            {{ ('ConnectivityType.' + connectivityType) | translate:lang }}"></nb-icon>
                <!-- Connectivity: Mobile signal strength -->
                <nb-icon *ngIf="isSignalStrengthVisible" [icon]="signalStrengthIcon"
                    class="connectivity connectivity-signal" pack="custom-icons" nbTooltip="{{ '🌐General.MobileCommunications' | translate:lang }}:
            {{ '🌐General.SignalStrength' | translate:lang }} {{ signalStrengthText }}"></nb-icon>
            </ng-container>
            <!-- Device ID / Name -->
            <a *ngIf="_deviceReport" [href]="'pages/devices/detail/' + _deviceReport?.DeviceID">
                <!-- (click)="$event.stopPropagation(); navigateToDevice(_device.ID);">-->
                <span class="device-id clickable text-nowrap" *ngIf="showID">{{_deviceReport.DeviceID}}</span>
                <span class="device-id clickable text-nowrap"
                    *ngIf="showName">{{_deviceReport.Name?_deviceReport.Name:"..."}}</span>
            </a>
        </span>
        <!-- Problems -->
        <ul *ngIf="showProblems" class="commalist">
            <li *ngFor="let prob of problems">
                <span class="clickable text-nowrap" (click)="showErrorDescription(prob, $event)">{{prob.ID}}</span>
            </li>
        </ul>
    </span>
</ng-container>
