import { Currency } from "../../shared-gen/Model/Utils/Currency";
import { formatCurrency } from "@angular/common";

/**
 * Formats the given amount of money to the given currency,
 * e.g. 2.5 and EUR to "2,50 €".
 * Pay attention to not pass cents (250), but full EUR (2.5) for example.
 */
export function toCurString(amount: number, currency: Currency): string {
    let settings = getCurStringSettings(currency);
    return formatCurrency(amount, settings[0], settings[1], settings[2], "1." + settings[3] + "-" + settings[3]);
}

export function toCurStringWithPrecision(amount: number, currency: Currency, precision: number): string {
    let settings = getCurStringSettings(currency);
    //let defaultPrecision = parseInt(settings[3],10)/2; // 1 for the expected 2 // TODO: handle currencies with other default-precisions than 2
    return formatCurrency(amount, settings[0], settings[1], settings[2], "1." + precision + "-" + precision);
}

export function toCurStringWithPrecisionShort(amount: number, currency: Currency, precision: number): string {
    let settings = getCurStringSettings(currency);
    //let defaultPrecision = parseInt(settings[3],10)/2; // 1 for the expected 2 // TODO: handle currencies with other default-precisions than 2
    return formatCurrency(amount, settings[0], "", settings[2], "1." + precision + "-" + precision);
}


export function getCurStringSettings(currency: Currency): string[] {
    switch (currency) {
        /** You need to register the locale data in the function registerLocale() found in src/shared/utils/init.ts */
        case Currency.AUD: return ['en-AU', '$', 'AUD', '2'];
        case Currency.CHF: return ['de-CH', 'CHF', 'CHF', '2'];
        case Currency.DKK: return ['da', 'kr', 'DKK', '2'];
        case Currency.EUR: return ['de', '€', 'EUR', '2'];
        case Currency.GBP: return ['en-GB', '£', 'GBP', '2'];
        case Currency.SGD: return ['en-SG', '$', 'SGD', '2'];
        case Currency.USD: return ['en', '$', 'USD', '2'];
        case Currency.NOK: return ['nb', 'kr', 'NOK', '0'];
        case Currency.PLN: return ['pl-PL', 'zł', 'PLN', '2'];
        default: return ['de', '€', 'EUR', '2'];
    }
}

/**
 * Returns the currency symbol for the given currency, e.g. "€" for "EUR".
 */
export function getCurSymbol(currency: Currency) {
    switch (currency) { // values as returned from C# System.Globalization.CultureInfo.CreateSpecificCulture("xx-XX").NumberFormat.CurrencySymbol
        case Currency.AUD: return '$';
        case Currency.CHF: return 'CHF';
        case Currency.DKK: return 'kr.';
        case Currency.EUR: return '€';
        case Currency.GBP: return '£';
        case Currency.USD: return '$';
        case Currency.NOK: return 'NOK';
        case Currency.PLN: return 'zł';
        default: return '€';
    }
}

