import { Component, EventEmitter, Input, OnInit, AfterViewInit, HostBinding, Output, ViewChild, ViewEncapsulation, OnDestroy } from "@angular/core";
import { JsonEditorComponent, JsonEditorOptions } from "ang-jsoneditor";
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";
import * as _ from "lodash";

@Component({
    selector: "ngx-json-editor",
    templateUrl: "./json-editor.component.html",
    styleUrls: ["./json-editor.component.scss"],
    encapsulation: ViewEncapsulation.None, // needed for styling the JsonEditorComponent in our CSS
})
export class JsonEditorComponentX extends Localized implements OnInit, AfterViewInit, OnDestroy {
    _data: any;
    @Input()
    get data(): any {
        return this._data;
    }
    set data(d: any) {
        this._data = d;
        if (this.initialized) {
            this.dataChange.emit(this._data);
        }
    }
    @Output() dataChange: EventEmitter<any> = new EventEmitter();

    @HostBinding("style.--editor-height") @Input() height = "500px";
    @Input() disabled = false;
    @Input() noButtons = false;

    @ViewChild("jsoneditor", { static: false }) editor: JsonEditorComponent;
    editorOptions: JsonEditorOptions = null;
    _editorData: any;

    get editorData(): any {
        return this._editorData;
    }
    set editorData(d: any) {
        if (JSON.stringify(this._editorData) != JSON.stringify(d)) {
            this._editorData = d;
        }
    }

    private changed: boolean = false;
    private initialized: boolean = false;

    constructor(translationService: TranslationService) {
        super(translationService);
    }

    ngOnInit() {
        // Create editor/viewer
        let options = new JsonEditorOptions();
        if (false == this.disabled) {
            options.modes = ["view", "code", "tree"]; // Set all allowed modes. Also available: 'text'
            options.mode = "code";
        } else {
            options.modes = ["view"];
            options.mode = "view";
        }
        // Update and format the editor when leaving focus.
        // Uses last **valid** json value and therefore overrides **invalid** json values.
        options.onBlur = () => {
            try {
                if (!this.initialized) {
                    return;
                }
                this.editor.update(this.editorData);
            } catch (error) {
                console.warn('jsoneditor could not update itself. This happens when jsoneditor processes some data debounced and the component gets destroyed in between. Seems to be a bug in jsoneditor.', error);
            }
        }
        this.editorOptions = options;
    }

    ngAfterViewInit() {
        // Wait for editor component initalization
        this._editorData = this.data;
        this.editor.update(this.editorData);
        this.initialized = true;
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        if (this.editorOptions) {
            this.editorOptions.onBlur = undefined;
        }
    }

    onEditorChanged(e) {
        if (e.bubbles && e.srcElement) return false; // for what is this needed ?
        this.editorData = e;
        this.changed = true;
        if (this.noButtons && this.editor.isValidJson()) {
            this.save();
        }
    }

    save() {
        this.changed = false;
        this.data = this.editorData; // 'set' fires ouput event
    }

    /**
     * If noButtons is true, this is needed to propagate
     * to the parent component if it's allowed
     * to save the current values. [see Parent: DeviceConfComponent]
     */
    canSave() {
        return !this.changed && this.editor.isValidJson();
    }

    discard() {
        this.changed = false;
        this.editorData = this.data;
        this.editor.data = this.editorData;
    }
}
