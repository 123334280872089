import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { environment } from "../../../../environments/runtime-environment";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";

@Component({
    selector: "ngx-contact-dialog",
    templateUrl: "./contact-dialog.component.html",
    styleUrls: ["./contact-dialog.component.scss"]
})
export class ContactDialogComponent extends Localized {

    env = environment();

    constructor(public dialogRef: MatDialogRef<ContactDialogComponent>, translationService: TranslationService) {
        super(translationService);
    }

}
