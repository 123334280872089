import { Component } from "@angular/core";
import { SettingsDialogComponent } from "../settings-dialog/settings-dialog.component";
import { DataService } from "../../../services/data.service";
import { MatDialogRef } from "@angular/material/dialog";
import { TranslationService } from "../../../../shared/services/translation.service";
import { UiSettings } from "../../../pages/iovent/configuration/pricelists/pricelist/pricelist.component.types";

@Component({
    selector: "ngx-pricelist-settings-dialog",
    templateUrl: "./pricelist-settings-dialog.component.html",
    styleUrls: ["./pricelist-settings-dialog.component.scss"]
})
export class PricelistSettingsDialogComponent extends SettingsDialogComponent {

    uiSettings: UiSettings = {
        showDeposit: false,
        showMdbId: false
    }

    constructor(public dialogRef: MatDialogRef<SettingsDialogComponent>,
                translationService: TranslationService) {
        super(dialogRef, translationService);
    }

    selectDeposit(showDeposit: boolean) {
        this.uiSettings.showDeposit = showDeposit;
    }

    selectMdbId(showMdbId: boolean) {
        this.uiSettings.showMdbId = showMdbId;
    }

    async save() {
        if (this.isIDOK && this.isNameSet) {
            this.dialogRef.close({ entity: this.entity, uiSettings: this.uiSettings });
        }
    }
}


