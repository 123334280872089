<div class="ProductImageEditor">
  <nb-card>
    <nb-card-header class="ProductImageEditor-header">
      <div>
        {{"🌐Products.Image" | translate:lang }}
      </div>
      <div>
        <nb-icon icon="cloud-upload-outline" (click)="selectFileToUpload()" class="cursor-pointer"></nb-icon>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="ProductImageEditor-body">
        <div>
          <input #fileInput type="file" (change)="onFileSelected($event)" accept="image/*" />

          <div *ngIf="!imgLoaded" (click)="selectFileToUpload()" class="cursor-pointer">
            <br><br>
            <span>{{ "🌐Products.Image.PleaseChoose" | translate:lang }}</span>&nbsp;
            <nb-icon icon="cloud-upload-outline"></nb-icon>
          </div>
          <div [style.display]="imgLoaded ? 'block' : 'none'">            
            <canvas 
              #canvasElement
              (pointerdown)="canvasMouseDown($event)"
              (pointerup)="canvasMouseUp($event)"
              (pointerout)="canvasMouseOut($event)"
              (pointermove)="canvasMouseMove($event)"
              (wheel)="canvasMouseWheel($event)"
            ></canvas>
          </div>
        </div>
        <div>
          <div class="ProductImageEditor-paragraph">
            <nb-icon icon="info" class="ProductImageEditor-paragraphIcon"></nb-icon>
            {{ "🌐Products.Image.Editor.Explanation" | translate:lang }}
          </div>
          <br><br>
          <div class="ProductImageEditor-paragraph">
            <nb-icon icon="move-outline" class="ProductImageEditor-paragraphIcon"></nb-icon>
            {{ "🌐Products.Image.Editor.ExplanationTranslation" | translate:lang }}
          </div>
          <br><br>
          <div class="ProductImageEditor-paragraph">
            <nb-icon icon="maximize-outline" class="ProductImageEditor-paragraphIcon"></nb-icon>
            {{ "🌐Products.Image.Editor.ExplanationScale" | translate:lang }}
          </div>
          <br><br>
          <div class="w-100 text-right">
            {{"🌐Portal.Example" | translate:lang}}:<br>
            <img width="100" src="assets/images/products/product-image-editor/product-image-editor-sample.png"/>
          </div>
          
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer class="ProductImageEditor-footer">
      <button nbButton (click)="abort()">{{ "🌐General.Cancel" | translate:lang }}</button>
      <button nbButton (click)="saveImage()" status="primary" [disabled]="!imgLoaded">{{"🌐General.Save" | translate:lang}}</button>
    </nb-card-footer>
  </nb-card>
</div>