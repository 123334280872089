import { LanguageCode } from "../../shared-gen/Model/Localization/LanguageCode";

/**
 * Interface for environment settings.
 * See environmentDefaults below for example values.
 */
export interface IEnvironment {

    // Project settings
    projectName: string,
    defaultPage: string,
    backendUrl: string,
    imageUrl: string,
    defaultLanguage: LanguageCode,
    contact: {
        name: string,
        mail: string,
        phone: string,
        phoneClean: string
    },
    priceMinStepCents: number, // Constraint for a single price property (price, deposit). If 0, any price is allowed. If e.g. 10, only 0.10, 0.20, ... 5.00, 5.10, ... is allowed.
    totalPriceMinStepCents: number, // Constraint for a total price (price + deposit). If 0, any price is allowed. If e.g. 10, only 0.10, 0.20, ... 5.00, 5.10, ... is allowed.

    // Build settings
    production: boolean,

    // Google Map settings
    mapSettings: {
        latitude: number,
        longitude: number,
        zoom: number
    },

    // Test settings
    testStage: "Dev" | "Staging" | "Production",
    testWarning: string | null, // Banner on title bar to warn users, e.g. "Staging" or "Sandbox", null on production

    // ngx-admin settings
    reports: {
        infiniteScroll: boolean
    }
    testUser: any;

    publicKey: string;

};

/**
 * Default values for the environment.
 */
export const environmentDefaults: IEnvironment = {

    // Project settings
    projectName: "template",
    defaultPage: "/pages/locations/list",
    backendUrl: "./api/1.0",
    imageUrl: "https://eude-shared-cdne-01.iovent.net/images/",
    defaultLanguage: LanguageCode.EN,
    contact: {
        name: "iovent GmbH",
        mail: "info@iovent.net",
        phone: "+49 8252 8986-44",
        phoneClean: "+498252898644"
    },
    priceMinStepCents: 0,
    totalPriceMinStepCents: 0,

    // Build settings
    production: false,

    // Google Map settings - Germany
    mapSettings: {
        latitude: 51.163361,
        longitude: 10.447683,
        zoom: 6
    },

    // Test settings
    testStage: "Production",
    testWarning: null,

    // ngx-admin settings
    reports: {
        infiniteScroll: true
    },
    testUser: null, /* {
    token: {
      expires_in: 3600000,
      access_token: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1laWRlbnRpZmllciI6IjIiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQFVzZXIiLCJyb2xlIjoidXNlciIsIm5iZiI6MTU2NDA2MTQ1NywiZXhwIjoxNTk1NjgzODU3LCJpc3MiOiJpc3N1ZXJfc2FtcGxlIiwiYXVkIjoiYXVkaWVuY2Vfc2FtcGxlIn0.xAAbQIOsw3ZXlIxDFnv5NynZy7OfzrvrJYWsy2NEBbA"
    },
    email: "user@user.user"
  }, //*/

    // This is a public key for "ShortPrivateKey.pem" on the backend.
    // To be used for a weak encryption to prevent casual information theft from unlocked vaults.
    // The real protection are the vaults themselves (access to DB, Backend, Git, ...).
    // CryptoHelper.ts helps with the client side encryption.
    // ATTENTION: keep the linebreaks and whitespace in the string intact!
    publicKey:
        `-----BEGIN RSA PUBLIC KEY-----
MCgCIQDAR7NKa+rPe8aaBxp+KExIg5MGViq2qOXAEWXkIqI5KQIDAQAB
-----END RSA PUBLIC KEY-----`

};
