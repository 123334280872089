<nb-card>

    <nb-card-header>{{ title }}</nb-card-header>

    <nb-card-body>
        <div [innerHTML]="confirmMessage"></div>


        <!--  <template [ngTemplateOutlet]="template" [ngOutletContext]="context"></template>-->

        <div class="row align-items-center my-3">
            <div class="col-4">
                <label class="mb-0">{{"🌐FromID" | translate:lang}}</label>
            </div>
            <div class="col-8">
                <input [(ngModel)]="fromID" nbInput fullWidth />
            </div>
        </div>
        <div class="row align-items-center my-3">
            <div class="col-4">
                <label class="mb-0">{{"🌐ToID" | translate:lang}}</label>
            </div>
            <div class="col-8">
                <input [(ngModel)]="toID" nbInput fullWidth />
            </div>
        </div>

    </nb-card-body>


    <nb-card-footer class="d-flex">
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-100 mr-2">{{"🌐General.Cancel" |
            translate:lang}}</button>
        <button nbButton status="success" (click)="dialogRef.close({fromID: fromID, toID: toID})"
            class="w-100 ml-2">{{"🌐General.Confirm" | translate:lang}}</button>
    </nb-card-footer>

</nb-card>
