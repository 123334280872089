/**
 * Some escape codes for allowing the user to
 * type things like forced line breaks or non-breaking spaces.
 */

// Unicode | Escaped code | HTML code
export const textEscapeCodes = [
    ["\u000A", "\\n", "<br/>"], // forced line break
    ["\u00A0", "\\ ", "&nbsp;"] // non-breaking space
];

/**
 * See textEscapeCodes .
 * E.g. line feed => \n (clear text)
 */
export function decodeEscapeCodesToEditor(value: string) {
    textEscapeCodes.forEach(a => value = value.replace(a[0], a[1]));
    return value;
}

/**
 * See textEscapeCodes .
 * E.g. line feed => <br/>
 */
export function decodeEscapeCodesToHtml(value: string) {
    textEscapeCodes.forEach(a => value = value.replace(a[0], a[2]));
    return value;
}

/**
 * See textEscapeCodes .
 * E.g. \n (clear text) => line feed
 */
export function encodeEscapeCodesFromEditor(value: string) {
    textEscapeCodes.forEach(a => value = value.replace(a[1], a[0]));
    return value;
}
