import { ProductBase } from "shared-gen/Model/Products/ProductBase";
import { getDefaultID } from "shared/core";
import { ProductFun } from "./product.fun";
import { EntityType } from "shared-gen/Model/Utils/EntityType";
import { ProductType } from "shared-gen/Model/Products/ProductType";
import { Resource } from "shared-gen/Model/Products/Resource";
import { IProductBaseData } from "shared-gen/Model/Products/IProductBaseData";
import { Product } from "shared-gen/Model/Products/Product";
import { ProductInstance } from "shared-gen/Model/Products/ProductInstance";

export class ProductBaseFun {

    static createNew(providerID: string): ProductBase {
        const productID = getDefaultID();
        return {
            Type: EntityType.ProductBase,
            ID: productID,
            ProviderID: providerID,
            ProductType: ProductType.Article,
            Hash: "",
            Name: '',
            Image: undefined,
            Details: undefined,
            Options: [],
            Instances: [ProductFun.createNew(productID)],
            LocalizedNames: undefined,
            LocalizedDetails: undefined
        };
    }

    static machineResourceUnit(isFluid: boolean): string {
        if (isFluid) return "s";
        return "mm";
    }

    static smallResourceUnit(isFluid: boolean): string {
        if (isFluid) return "ml";
        return "g";
    }

    static largeResourceUnit(isFluid: boolean): string {
        if (isFluid) return "l";
        return "kg";
    }


    static isFluidResource(resource: Resource): boolean {
        var conditions = [Resource.Milk, Resource.Milk2, Resource.Milk3];
        return conditions.includes(resource);
    }


    static machineResourceUnitFromResource(resource: Resource): string {
        return ProductBaseFun.machineResourceUnit(ProductBaseFun.isFluidResource(resource));
    }

    static smallResourceUnitFromResource(resource: Resource): string {
        return ProductBaseFun.smallResourceUnit(ProductBaseFun.isFluidResource(resource));
    }

    static largeResourceUnitFromResource(resource: Resource): string {
        return ProductBaseFun.largeResourceUnit(ProductBaseFun.isFluidResource(resource));
    }


    static isFluidResourceProduct(product: IProductBaseData): boolean {
        return product && product.ProductType == ProductType.Resource_Milk;
    }

    static machineResourceUnitFromProduct(product: IProductBaseData): string {
        return product ? ProductBaseFun.machineResourceUnit(ProductBaseFun.isFluidResourceProduct(product)) : "";
    }

    static smallResourceUnitFromProduct(product: IProductBaseData): string {
        return product ? ProductBaseFun.smallResourceUnit(ProductBaseFun.isFluidResourceProduct(product)) : "";
    }

    static largeResourceUnitFromProduct(product: IProductBaseData): string {
        return product ? ProductBaseFun.largeResourceUnit(ProductBaseFun.isFluidResourceProduct(product)) : "";
    }

    // TODO: sync with backend or create Product endpoints to directly get Product data from backend
    static toProduct(instance: ProductInstance, base: ProductBase): Product {
        let product = instance as Product;
        product.ProductType = base.ProductType;
        product.Image = base.Image;
        product.Details = base.Details;
        product.LocalizedNames = base.LocalizedNames;
        product.LocalizedDetails = base.LocalizedDetails;
        return product;
    }


}

