import { LanguageCode } from "../../shared-gen/Model/Localization/LanguageCode";
import { environmentDefaults, IEnvironment } from "./environment-defaults";

/**
 * Production environment for several smaller projects, starting in 2021-06 (portal.iovent.net).
 */
export const environment: IEnvironment = {
    ...environmentDefaults,

    projectName: 'shared',
    defaultLanguage: LanguageCode.DE,
    // Build settings
    production: true,
    testStage: "Production",
    testWarning: ""

};
