export interface EversysProductGeneratorTheme {
  id: string,
  name: string,
  productImages: Record<string, string>

}
export const productThemes: EversysProductGeneratorTheme[] = [
  {
    id: 'iovent',
    name: 'iovent',
    productImages: {
      Americano: "f18dd0a875cdff1985a9713dd3181dee64a5e84d41dc0f684d97b9e5986689f9.png",
      Cappuccino: "0630f27fbbfb101f7d44badab4c83df98471ab239cd9ab527fdae9a4b4799822.png",
      Chococino: "aa29c62de87610bd57065257061b82ac79c9cda2be348b8d50ba66d4e7a59f36.png",
      Espresso: "2a3eda60c5b05781cc63022c0b0c64bf9e8ae9d952fbdb3d238c06d76b651b21.png",
      EspressoMacchiato: "11477406137d87642b795060c4087ea460616e43562919826f58adf30c4ab808.png",
      Kaffee: "3ca992a8e6487493c172a3e4de82abdc85af7836bef43a94dce18cbcaf348ef1.png",
      KaffeemitMilch:  "86a5886df180559271abaa8a651fea8c3bdd39a08a3d4f108fbd826c0de23923.png",
      Kakao: "aa29c62de87610bd57065257061b82ac79c9cda2be348b8d50ba66d4e7a59f36.png",
      LatteMacchiato: "c2d3b67ab635719d42b27ace2fda49f0fe328008c1298f6f9c61b73889807c98.png",
      Milchkaffee: "a4be0bac2da64fd1343ca8c695d78ee12d530255224d1e7352ee15b63338f3cb.png",
    }
  },{
    id: 'lavazza',
    name: 'Lavazza',
    productImages: {
      Americano: "f043624242e8d2af8edcbe80aed5780222b03c4ae443db150f70899fb1da36f6.png",
      Cappuccino: "9dae70cea018f81b7e8f00bbeed60e0d25a78087191b743e09a0433e7486d389.png",
      Chococino: "9dae70cea018f81b7e8f00bbeed60e0d25a78087191b743e09a0433e7486d389.png",
      Espresso: "ca1d97114a8142b415d2de8704168fe21c8ada354966b9449f75c6799dfdde86.png",
      EspressoMacchiato: "716539f0a6f83e563ccc29647f933aa45252ab0f6b2779eea2b71d1abbcca2ee.png",
      Kaffee: "9bdf1447ae5ad75e8574367d257632fe6020fafbf7c341ff7ea5b6ee0b29d521.png",
      KaffeemitMilch:  "161eae4588d79ec55a9712846f2c7231281c12fc5d13c66193cfbd258295cb8c.png",
      Kakao: "04c645791d23fb5d38ed42f9859afb9de7b763f9a51677cd8ffe5d3ee1cc33b3.png",
      LatteMacchiato: "f1da5d5a69efd8c53afa6db94e5cb7fa4f4d7750860978f8f42791c04e235dbb.png",
      Milchkaffee: "161eae4588d79ec55a9712846f2c7231281c12fc5d13c66193cfbd258295cb8c.png",
    }
  }
]