export class EntityFun {

    /**
     * Checks, if the given ID is valid. It is valid, if it has at least one
     * character and if it contains only characters of A-Z, a-z, 0-9 and "-".
     * No special characters, punctation or Umlaute are allowed.
     */
    static isIDValid(id: string): boolean {
        return EntityFun.regexID.test(id);
    }

    private static regexID = /^[A-Za-z0-9\-_.]+$/; // TODO: automatically sync with c# code ... string constant ? Consider potential differences in escapes \\

}
