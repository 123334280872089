import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DataService } from "../../../services/data.service";
import { Menu } from "../../../../shared-gen/Model/Menus/Menu";
import { EntityFun } from "../../../../shared/model/utils/entity.fun";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";

@Component({
    selector: "ngx-menu-settings-dialog",
    templateUrl: "./menu-settings-dialog.component.html",
    styleUrls: ["./menu-settings-dialog.component.scss"]
})
export class MenuSettingsDialogComponent extends Localized {

    public menu: Menu;
    validID: boolean = true;
    disableClose: boolean = false;

    constructor(private dataService: DataService,
        public dialogRef: MatDialogRef<MenuSettingsDialogComponent>,
        translationService: TranslationService) {
        super(translationService);
    }

    async checkID() {
        this.validID = EntityFun.isIDValid(this.menu.ID);
        return this.validID;
    }

    async save() {
        if (await this.checkID() && this.menu.Name !== "") {
            this.dialogRef.close(this.menu);
        }
    }

}
