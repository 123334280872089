import { IEnvironment } from "./environment-defaults";
import * as baseEnvironment from "./environment.shared.dev"; // Change here dependent on the tested environment, e.g. "environment.tur"

/**
 * Development environment for local tests with a localhost Backend.
 */
export const environment: IEnvironment = {
    ...baseEnvironment.environment,

    // Project settings
    backendUrl: "http://localhost:5000",
    testWarning: "Local",
};

