<nb-card [nbSpinner]="devices==null || !initialized || (importing > 0 && importing < 1) " nbSpinnerSize="giant">

    <nb-card-header>{{"🌐ImportDevices" | translate:lang}}</nb-card-header>

    <nb-card-body>

        <iovent-table *ngIf="result" [data]="result" [columns]="getColumns()" (pageChanged)="pageChanged($event)"
            [PageSize]="PageSize" (PageSizeChange)="onPageSizeChange($event)" (sortChanged)="sortChanged($event)"
            [sortBy]="sortBy" [orderAsc]="orderAsc" (queryChanged)="query($event)" showFilter="['filter']"
            stickyFrame=true type='grid'>

            <ng-container filter>
                <div class="d-flex justify-content-between align-items-center">
                    <button nbButton status="basic" (click)="this.selectAll(true)" class="w-100 mr-2">{{"🌐SelectAll" |
                        translate:lang}}</button>
                    <label class="ml-2">{{"🌐ServiceLocation" | translate:lang}}</label>
                    <nb-checkbox status="info" [(checked)]="useServiceLocation"></nb-checkbox>
                    <label class="ml-2">{{"🌐GroupLocation" | translate:lang}}</label>
                    <nb-checkbox status="info" [(checked)]="useGroupLocation"></nb-checkbox>
                    <label class="ml-2">{{"🌐CustomerLocation" | translate:lang}}</label>
                    <nb-checkbox status="info" [(checked)]="useCustomerLocation"></nb-checkbox>
                    <label class="ml-2">{{"🌐IDPrefix" | translate:lang}}</label><input status="info"
                        [(ngModel)]="idPrefix" nbInput fullWidth />
                </div>
            </ng-container>

            <ng-template Column="$Selected" Header="🌐General.Selected" let-data="data">
                <nb-checkbox status="info" *ngIf="!data.ID" (change)="toggleSelect(data)" [checked]="isSelected(data)">
                </nb-checkbox>
            </ng-template>

            <ng-template Column="XID" Header="🌐General.XID" let-value="value" NoFilter>
                {{value}}
            </ng-template>
            <ng-template Column="ServiceCompany" Header="🌐General.ServiceCompany" let-value="value" NoFilter>
                {{value}}
            </ng-template>
            <ng-template Column="Group" Header="🌐General.Group" let-value="value" NoFilter>
                {{value}}
            </ng-template>
            <ng-template Column="Customer" Header="🌐General.Customer" let-value="value" NoFilter>
                {{value}}
            </ng-template>
            <ng-template Column="City" Header="🌐General.City" let-value="value" NoFilter>
                {{value}}
            </ng-template>
            <ng-template Column="Street" Header="🌐General.Street" let-value="value" NoFilter>
                {{value}}
            </ng-template>
            <ng-template Column="Remarks" Header="🌐General.Remarks" let-value="value" NoFilter>
                {{value}}
            </ng-template>
            <ng-template Column="Name" Header="🌐General.Name" let-value="value" NoFilter>
                {{value}}
            </ng-template>
            <ng-template Column="MachineType" Header="🌐General.MachineType" let-value="value" NoFilter>
                {{value}}
            </ng-template>
            <ng-template Column="MachineSerial" Header="🌐General.MachineSerial" let-value="value" NoFilter>
                {{value}}
            </ng-template>

        </iovent-table>

    </nb-card-body>


    <nb-card-footer class="d-flex">
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-100 mr-2">{{"🌐General.Cancel" |
            translate:lang}}</button>
        <button nbButton status="success" (click)="confirm()" class="w-100 ml-2">
            {{"🌐General.ImportSelectedDevices" | translate:lang}}</button>
        <!--[disabled]="selected.length == 0"-->
    </nb-card-footer>

</nb-card>
