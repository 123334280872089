/** The list of supported languages. */
export enum LanguageCode {
    /** German. */
    DE = "DE",
    /** English. */
    EN = "EN",
    /** Dutch. */
    NL = "NL",
    /** Polish. */
    PL = "PL",
    /** Spanish. */
    ES = "ES",
    /** French. */
    FR = "FR",
    /** Pseudolanguage for Loca checks */
    LOCA = "LOCA"
}
