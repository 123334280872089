import { Component, TemplateRef } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";

@Component({
    selector: "ngx-data-dialog",
    templateUrl: "./data-dialog.component.html",
    styleUrls: ["./data-dialog.component.scss"]
})
export class DataDialogComponent extends Localized {

    public title: string;
    public confirmMessage: string;

    public fromID: string;
    public toID: string;

    //public template : TemplateRef<any>;

    constructor(public dialogRef: MatDialogRef<DataDialogComponent>, translationService: TranslationService) {
        super(translationService);
    }
}
