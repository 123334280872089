import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";
import { DataService } from "../../services/data.service";
import { sleep } from "../../../shared/utils/core-utils";
import { hasRights } from "app/auth/iovent/rights";
import { Access } from "shared-gen/Model/Auth/Access";
import { Feature } from "shared-gen/Model/Auth/Feature";
import { UserStore } from "app/@core/stores/user.store";
import { ScreenResolution } from "shared-gen/Model/Devices/ScreenResolution";

type MonitorState = 'Loading' | 'Loaded' | 'Offline' | 'Error';

@Component({
    selector: "device-screenshot",
    templateUrl: "./device-screenshot.component.html",
    styleUrls: ["./device-screenshot.component.scss"]
})
export class DeviceScreenshotComponent extends Localized implements OnInit, OnDestroy {

    @Input() deviceID: string = null;
    @Output() onConnectionStateChanged = new EventEmitter<'Online' | 'Offline'>();

    state: MonitorState = 'Error';
    imageData = "";
    isStreaming = false;
    isRemoteControl = false;
    streamingProcess = "";
    frameStyle = "";
    hasReadRight = false;
    hasWriteRight = false;

    private readonly screenshotWidth = 640;
    private screenResolution?: ScreenResolution;

    constructor(private api: DataService,
        private userStore: UserStore,
        translationService: TranslationService,
        protected cd: ChangeDetectorRef) {
        super(translationService);
    }

    ngOnInit() {
        let user = this.userStore.getUser();
        this.hasReadRight = hasRights(Feature.DeviceRemoteControl, user, Access.Read);
        this.hasWriteRight = hasRights(Feature.DeviceRemoteControl, user, Access.Write);
        this.reload();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.isStreaming = false;
    }

    async reload() {
        const oldState = this.state;

        if(!this.deviceID){
            return;
        }
        if (!this.isStreaming){
            this.state = "Loading";
        }
        this.cd.detectChanges();

        try {
            const data = await this.api.getScreenshot(this.deviceID, this.screenshotWidth);
            this.createImageFromBlob(data);
            this.state = "Loaded";
        } catch (error) {
            switch(error.status){
                case 404: this.state = "Offline"; break;
                default: this.state = "Error"; break;
            }
        }
        this.cd.detectChanges();

        if (this.state != oldState) {
            this.onConnectionStateChanged.emit(this.state == "Loaded" ? "Online" : "Offline");
        }

        if (this.isStreaming) {
            await sleep({
                "Loaded": 200,
                "Offline": 5000,
                "Error": 5000
            }[this.state]);

            this.streamingProcess = this.streamingProcess.length == 3 ? "" : this.streamingProcess + ".";
            this.cd.detectChanges();

            await this.reload();
        }
    }

    changedStreaming() {
        if (this.isStreaming) {
            this.reload();
        }
    }

    async changedRemoteControl() {
        if (this.isRemoteControl) {
            await this.updateScreenResolution();
        }
    }

    private async updateScreenResolution() {
        this.screenResolution = await this.getScreenResolution();
    }

    private async getScreenResolution(): Promise<ScreenResolution> {
        try {
            this.screenResolution = await this.api.getScreenResolution(this.deviceID);
            if (!this.screenResolution?.Width || !this.screenResolution?.Height) {
                this.screenResolution = undefined;
                throw new Error('found invalid screen resolution');
            }
            return this.screenResolution;
        } catch (error) {
            // fallback to determine screen resolution based on our standard screen layouts.
            // if the screen is wider than tall then we have a classic HD output like the CB1
            // if the screen is taller than wide then it is likely the standard pickup layout
            // which has an vertical external screen centered on top of the horizontal menu app
            console.log('Cannot determine screen resolution by asking the device. Will assume our default screen setups. Error was: ', error);
            const imageScreenResolution = await this.getImageResolutionFromDataUrl(this.imageData);
            if ( imageScreenResolution.Width > imageScreenResolution.Height) {
                return {
                    Width: 1920,
                    Height: 1080
                }
            }
            return {
                 Width: 1920,
                 Height: 3000 // external vertical screen 1920 + horizontal menu app 1080 = 3000
            }
        }
    }

    private getImageResolutionFromDataUrl(imageDataUrl): Promise<ScreenResolution> {
        if (!imageDataUrl) {
            throw new Error('Cannot get screen resolution from data url if no data url exists');
        }
        return new Promise(resolve => {
            const loadedImage = new Image();
            loadedImage.onload = () => {
                resolve({ Width: loadedImage.width, Height: loadedImage.height });
            };
            loadedImage.src = imageDataUrl;
        })
    }

    private createImageFromBlob(image: Blob) {
        let reader = new FileReader();
        reader.addEventListener("load", () => {
            this.imageData = reader.result as string;
            this.cd.detectChanges();
        }, false);
        if (image) {
            reader.readAsDataURL(image);
        }
    }

    async clicked(evt: MouseEvent) {
        if (!this.isRemoteControl) {
            return;
        }
        if (!this.screenResolution) {
            console.log('No remote screen resolution known. Need to figure it out first.');
            await this.updateScreenResolution();
            if (!this.screenResolution) {
                console.log('Still no screen resolution available. Cannot trigger a click event on the device');
                return;
            }
        }
        const target = evt.target as HTMLImageElement;
        const x = Math.round(evt.offsetX / target.offsetWidth * this.screenResolution.Width);
        const y = Math.round(evt.offsetY / target.offsetHeight * this.screenResolution.Height);
        console.log('Trigger remote click on position ', x, y);
        await this.api.clickOnScreen(this.deviceID, x, y);
        this.cd.detectChanges();
    }

}
