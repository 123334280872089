import { Subscription } from 'rxjs';
import { OnDestroy, OnInit, Injectable } from '@angular/core';
import { Language } from "./model/localization/language";
import { LanguageFun } from "./model/localization/language.fun";
import { LanguageCode } from "../shared-gen/Model/Localization/LanguageCode";
import { TranslationService } from "./services/translation.service";
import { LocalizedString } from 'shared-gen/Model/Utils/LocalizedString';

/**
 * Base class for components which need translated texts.
 * The property [lang] can be used for the translate pipe
 * and the [translate] method can be used for translations within TypeScript code.
 *
 * Do not forget to forward ngOnInit and ngOnDestroy calls to this base class!
 * Otherwise the translations will not work.
 */
@Injectable()
export abstract class Localized implements OnDestroy {

    /** Use this property for the translate-pipe argument. */
    lang: Language = LanguageFun.createNew();

    private langListener: Subscription | null = null;

    constructor(protected translationService: TranslationService) {
        this.langListener = this.translationService.language.subscribe(it => {
            this.lang = it;
            this.onLanguageChanged();
        });
    }

    ngOnDestroy() {
        this.langListener?.unsubscribe();
    }

    /**
     * Returns the translation of the given vocable ID in the given language (or current language), or
     * in English or German if it can not be found.
     */
    translate(vocabID: string, languageCode?: LanguageCode): string {
        return this.translationService.translate(vocabID, languageCode, true);
    }

    /**
     * Returns the translation of the given vocable ID in the given language (or current language), or
     * in English or German if it can not be found. The given Token/value pairs are replaced, e.g.
     * ["%value%, 50, "%currency%", "EUR"].
     */
    translateWithTokens(vocabID: string, tokens: any[], languageCode?: LanguageCode): string {
        return this.translationService.translateWithTokens(vocabID, tokens, languageCode, true);
    }

    /**
     * Returns the translation texts of a given `LocalizedString` using the current language.
     * If there is no defined localized string available for the current language, this function
     * will return the given `defaultString`. Usage: `translateLocalizedString(product.LocalizedNames, product.Name)`
     */
    translateLocalizedString(localizedString: LocalizedString | undefined, defaultString: string, languageCode?: LanguageCode) {
        if (!localizedString) {
            return defaultString || '';
        }
        const effectiveLanguageCode = languageCode ?? this.lang.languageCode;
        return (localizedString as any)[effectiveLanguageCode] || defaultString || '';
    }

    /**
     * This method is called when the language was changed.
     * If overridden, do not forget to call super.onChanged(), so that all
     * implementations in the inheritance chain are called.
     */
    onLanguageChanged() {
        // By default no additional action.
    }

}
