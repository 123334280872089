import { Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from '../services/translation.service';
import { Language } from '../model/localization/language';

/**
 * Uses the [TranslationService] to translate the given vocable ID
 * into the current language.
 */
@Pipe({
    name: 'translate',
    pure: true
})
export class TranslatePipe implements PipeTransform {

    constructor(private translator: TranslationService) {
    }

    transform(value: any, language?: Language): any {
        const languageCode = language != undefined ? language.languageCode : undefined;
        if (typeof value == "string") {
            return this.translator.translate(value, languageCode, true);
        }
        if (Array.isArray(value)) {
            return this.translator.translateWithTokens(value[0], value.slice(1), languageCode, true);
        }
    }

}
