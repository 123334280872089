import { Feature } from "../../../shared-gen/Model/Auth/Feature";
import { User } from "../../@core/interfaces/common/users";
import { Access } from "../../../shared-gen/Model/Auth/Access";
import { returnLeft } from "ionicons/icons";

/**
 * We do not use the ngx-admin roles/rights system, but our own iovent implementation.
 * This method checks, if the current user has access rights on the given feature.
 */
export function hasRights(feature: Feature, user: User, access: Access = Access.Read): boolean {
    const right = user.ioventRights.find(it => it.Feature == feature);
    if (right == null)
        return false;
    return (access == Access.Read && (right.Access == Access.Read || right.Access == Access.Both))
        || (access == Access.Write && (right.Access == Access.Write || right.Access == Access.Both))
        || (access == Access.Both && right.Access == Access.Both);
}

/**
 * We do not use the ngx-admin roles/rights system, but our own iovent implementation.
 * This method checks, if the current user has read rights on at least one of the given features.
 */
export function hasRightsOnAny(features: Feature[], user: User): boolean {
    return features.some(feature => hasRights(feature, user));
}

/**
 * Returns the read-only properties of the given feature of the given user.
 */
export function getFeatureReadOnlyFields(feature: Feature, user: User): string[] {
    const right = user.ioventRights.find(it => it.Feature == feature);
    return right?.ReadOnlyFields ?? [];
}
