import { ExtensionData } from "shared-gen/Model/Utils/Extensions/ExtensionData";
import { StatusDataBase } from "shared-gen/Model/StatusDataBase";
import { IHasExtensionData } from "shared-gen/Model/Utils/Extensions/IHasExtensionData";


/**
 * Helper functions for extension data.
 */

/**
 * Returns the ExtensionData object from the given object with the given type,
 * or null, if it does not exist.
 * If init is true, the extension is created if it does not exist yet.
 * Call: getExtensionData<TurProductX>(myProduct, "TurProductX", false)
 */
export function getExtensionData<T extends ExtensionData>(obj: IHasExtensionData, type: string, init = false): T | null {
    // Check object
    if (obj == null)
        return null;
    // Get extension, if existing, and return it
    if (obj.Extensions != null) {
        const ret = (obj.Extensions as any)[type] as T;
        if (ret != null)
            return ret;
    }
    // Create extension, if requested
    if (init) {
        if (obj.Extensions == null)
            obj.Extensions = {};
        const ret = { Type: type } as any as T;
        (obj.Extensions as any)[type] = ret;
        return ret;
    }
    return null;
}

export function setExtensionData<T extends ExtensionData>(obj: IHasExtensionData, type: string, value: T = undefined as any): T | null {
    if (obj.Extensions == null)
        obj.Extensions = {};
    if (value !== undefined) {
        (obj.Extensions as any)[type] = value;
        return value;
    } else {
        const ret = { Type: type } as any as T;
        (obj.Extensions as any)[type] = ret;
        return ret;
    }
}


/**
 * Returns the StatusData object from the given object with the given type,
 * or null, if it does not exist.

 */
export function getStatusData<T extends StatusDataBase>(obj: any, type: string): T | null {
    // Check object
    if (obj == null)
        return null;
    // Get extension, if existing, and return it
    if (obj.Status != null) {
        const ret = obj.Status[type] as T;
        if (ret != null)
            return ret;
    }
    return null;
}


export function setStatusData<T extends StatusDataBase>(obj: any, type: string, value: T = undefined as any): T | null {
    if (obj.Status == null)
        obj.Status = {};
    if (value !== undefined) {
        obj.Status[type] = value;
        return value;
    } else {
        const ret = { Type: type } as any as T;
        obj.Status[type] = ret;
        return ret;
    }
}


