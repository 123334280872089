import { Component, Input } from '@angular/core';
import { StatusColor } from "shared/core";


@Component({
    selector: "coins",
    templateUrl: "./coins.component.html",
    styleUrls: ["./coins.component.scss"]
})
export class CoinsComponent {

    _coins: { [key in number]: number } = {};

    coinTypes = [5, 10, 20, 50, 100, 200];
    coinNames = {
        5: "5ct",
        10: "10ct",
        20: "20ct",
        50: "50ct",
        100: "1€",
        200: "2€",
    };

    coinStatus: (StatusColor | 'disabled')[] = [];
    coinCount: (number | null)[] = [];

    warning_level = 15;
    critical_level = 6;

    @Input()
    set coins(coins: { [key in number]: number }) {
        this._coins = coins;
        this.init();
    }

    constructor() {
        for (let coin of this.coinTypes) {
            this.coinStatus[coin] = "disabled";
            this.coinCount[coin] = null;
        }
    }

    init() {
        if (this._coins) {
            const c = this._coins;
            for (let coin in c) {
                this.coinCount[coin] = c[coin];
                if (c[coin] == null) {
                    this.coinStatus[coin] = "disabled";
                } else if (c[coin] == 0) {
                    this.coinStatus[coin] = "red";
                } else if (c[coin] < this.critical_level) {
                    this.coinStatus[coin] = "red";
                } else if (c[coin] < this.warning_level) {
                    this.coinStatus[coin] = "yellow";
                } else {
                    this.coinStatus[coin] = "green";
                }
            }
        }
    }

}
