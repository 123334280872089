import * as moment from "moment";

export function getLocalDate(value: Date | string) {
    let date: moment.Moment;
    if (typeof (value) == 'string') {
        let d = '' + value;
        if (false === d.endsWith('Z'))
            d += 'Z';
        date = moment(d).local();
    }
    else {
        date = moment(value);
    }
    return date;
}

export function formatDate(date: moment.Moment) {
    // TODO
    if (navigator.language.startsWith("de"))
        return date.format('DD.MM.YYYY HH:mm:ss');
    else
        return date.format('YYYY-MM-DD HH:mm:ss');
}

/**
 * Formats the given UTC date (in strg format or as JavaScript Date) to local time.
 * If the given date string does not end with a "Z" for the UTC timezone,
 * the "Z" is added to convert it correctly.
 */
export function toLocalDate(value: Date | string) {
    let date = getLocalDate(value);
    return formatDate(date);
}

