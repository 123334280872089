import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { NbComponentStatus, NbGlobalPhysicalPosition, NbToastrService, NbWindowRef } from "@nebular/theme";
import { UUID } from "angular2-uuid";
import { environment } from "../../../environments/runtime-environment";
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";
import { DataService } from "../../services/data.service";

@Component({
    selector: "ngx-loading",
    templateUrl: "./loading.component.html",
    styleUrls: ["./loading.component.scss"]
})
export class LoadingComponent extends Localized implements OnInit {

    title: string = "Component title!";
    uuid: UUID;
    dataType: string = "transactions";
    mockup: boolean = false;

    loading: boolean = true
    progress: number = 0;

    href: string = "";
    @ViewChild("downloadLink") excelLink: ElementRef<HTMLAnchorElement>;

    constructor(protected http: HttpClient,
        private dataService: DataService,
        protected windowRef: NbWindowRef,
        private toastrService: NbToastrService,
        translationService: TranslationService
    ) {
        super(translationService);
    }

    async ngOnInit() {
        this.loading = true;
        if (!this.mockup) {
            try {
                await this.dataService.downloadIsFinished(this.uuid, (progress) => {
                    console.log(progress);
                    this.progress = Math.round(progress.Progress);
                }, this.dataType);
                this.loading = false;
                this.excelLink.nativeElement.href = `${environment().backendUrl}/export/excel/${this.dataType}/download?ID=${this.uuid}`;
                console.log("Download: " + this.excelLink.nativeElement.href);
                this.excelLink.nativeElement.click();
                this.close();

            } catch (e) {
                console.log(e);
                this.close(e);
            }
        } else {
            this.loading = true;
            this.progress = (new Date().getMinutes() * 100 / 60);
        }

    }

    close(error: any = null) {
        let body = this.translate("🌐Messages.DownloadSuccessful"),
            title = "",
            status: NbComponentStatus = "success";

        if (error) {
            status = "danger";
            body = this.translate("🌐Messages.DownloadFailed");
        }

        let config = {
            status: status,
            destroyByClick: true,
            duration: 3000,
            hasIcon: true,
            position: NbGlobalPhysicalPosition.TOP_RIGHT,
            preventDuplicates: false
        };

        this.toastrService.show(
            body,
            title,
            config);

        this.windowRef.close();

    }

}
