import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DataService } from "../../../services/data.service";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";
import { UserStore } from "../../../@core/stores/user.store";

/**
 * In this dialog, the user can input a Device pairing code
 * in the format "XX.XX.XX" (X=0-9).
 */
@Component({
    selector: "iovent-pairingcode-dialog",
    templateUrl: "./pairingcode-dialog.component.html",
    styleUrls: ["./pairingcode-dialog.component.scss"]
})
export class PairingCodeDialogComponent extends Localized {

    // Internal
    pairingCode1 = "";
    pairingCode2 = "";
    pairingCode3 = "";
    pairingCode = "";
    isOK = false;

    @ViewChild("input2") input2: ElementRef<HTMLInputElement>;
    @ViewChild("input3") input3: ElementRef<HTMLInputElement>;

    constructor(private dataService: DataService,
        public dialogRef: MatDialogRef<PairingCodeDialogComponent>,
        private userStore: UserStore,
        translationService: TranslationService,
        private changeDetectorRef: ChangeDetectorRef) {
        super(translationService);
    }

    async update(pos?: number) {
        if (pos > 0) {
            if (pos == 1 && this.pairingCode1.length == 2)
                this.input2.nativeElement.select();
            if (pos == 2 && this.pairingCode2.length == 2)
                this.input3.nativeElement.select();
        }
        this.pairingCode = this.pairingCode1 + "." + this.pairingCode2 + "." + this.pairingCode3;
        this.isOK = this.pairingCode.match(/^[0-9][0-9]\.[0-9][0-9]\.[0-9][0-9]$/) != null;
    }

    async save() {
        if (this.isOK)
            this.dialogRef.close(this.pairingCode);
    }

    async cancel() {
        this.dialogRef.close(null);
    }

}
