<nb-card>

    <nb-card-header>{{ title }}</nb-card-header>

    <nb-card-body>
        <div class="row">
            <div class="col-3">
                <label for="conditionType">{{"🌐PriceList.Condition.Type" | translate:lang}}</label>
            </div>
            <div class="col-6">
                <nb-select name="conditionType" [selected]="condition.Type" (selectedChange)="changeConditionType($event)" status="primary" class="w-100">
                    <nb-option *ngFor="let type of conditionTypes" [value]="type">{{ "🌐PriceList.Condition.Type." + type | translate:lang }}</nb-option>
                </nb-select>
            </div>
        </div>
            
        <ng-container *ngIf="condition.Type == 'NextOrderCondition'">
            <div class="NextOrderCondition">
                <span>{{ "🌐PriceList.Condition.Type.NextOrderCondition.Description" | translate:lang }}</span>
                <qrcode *ngIf="nextOrderConditionQrCode" [qrdata]="nextOrderConditionQrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
            </div>
        </ng-container>
        <ng-container *ngIf="condition.Type == 'InitiatorCondition'">
            <span>{{ "🌐PriceList.Condition.Type.InitiatorCondition.Description" | translate:lang }}</span>
            <div class="InitiatorConditionList" #initiatorConditionList>
                <div *ngFor="let allowedInitiator of condition.AllowedInitiators; let index = index" class="InitiatorConditionList-element">
                    <qrcode *ngIf="initiatorConditionQQrCodes && initiatorConditionQQrCodes[index]" [qrdata]="initiatorConditionQQrCodes[index]" [width]="170" [errorCorrectionLevel]="'M'"></qrcode>
                    <nb-button-group size="small" status="primary">
                        <button nbButton size="small" status="primary" (click)="copyToClipboard(allowedInitiator)">{{ allowedInitiator }}</button>
                        <button nbButton size="small" status="primary" (click)="downloadInitiatorImage(allowedInitiator)"><nb-icon icon="download-outline"></nb-icon></button>
                        <button nbButton size="small" status="primary" (click)="removeInitiator(allowedInitiator, index)"><nb-icon icon="trash-2-outline"></nb-icon></button>
                      </nb-button-group>
                </div>
                <span *ngIf="!condition.AllowedInitiators?.length"><i>{{"🌐General.None" | translate:lang}}</i></span>
            </div>
            <br/><br/>
            <form (ngSubmit)="addInitiator($event)">
                <div class="row">
                <div class="col-6 d-flex align-items-center">
                        <input name="nextInitiator" placeholder="{{'🌐General.Name' | translate:lang}}" nbInput fullWidth />
                        <button nbButton type="submit" status="success">
                            {{"🌐General.Add" | translate:lang}}
                        </button>
                    </div>
                </div>
            </form>
        </ng-container>
    </nb-card-body>


    <nb-card-footer class="d-flex">
        <button nbButton (click)="abort()" class="w-50 ml-2">{{"🌐General.Cancel" | translate:lang}}</button>
        <button nbButton status="success" (click)="save()" class="w-50 ml-2">{{"🌐General.OK" | translate:lang}}</button>
    </nb-card-footer>

</nb-card>