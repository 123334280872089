<nb-card>
    <nb-card-header>{{"🌐Products.Option.Settings" | translate:lang}}</nb-card-header>
    <nb-card-body>

        <div>
            <div class="row align-items-center my-3">
                <div class="col-3">
                    <label for="option-id" class="mb-0">{{"🌐Products.Option.ID" | translate:lang}}</label>
                </div>
                <div class="col-9">
                    <input id="option-id" type="text" [(ngModel)]="obj.ID" name="id" placeholder="ID" nbInput fullWidth
                        (keyup)="checkID()" [status]="'primary'" />
                    <p *ngIf="!validID" class="error mb-0">{{"🌐Products.Option.ThisIDIsInvalidOrAlreadyAssigned" |
                        translate:lang}}</p>
                </div>
            </div>


            <div class="row my-3">
                <div class="col-3">
                    <label for="option-name" class="mb-0 mt-2">{{"Products.Option.Name" | translate:lang}}</label>
                </div>
                <div class="col-9">
                    
                    <localized-string-input 
                        [(value)]="obj.Name"
                        [(localizedString)]="obj.LocalizedNames" 
                        [localizedPlaceholder]="obj.Name" 
                        placeholder="{{'Products.Option.Name' | translate:lang }}" 
                        [status]="'primary'"
                    ></localized-string-input>
                    <p *ngIf="!obj.Name" class="warning  mb-0">{{"🌐Products.Option.PleaseEnterAName" | translate:lang}}
                    </p>
                </div>
            </div>
        </div>

        <hr>

        <div>

            <p>{{"🌐Products.Option.Possibilities" | translate:lang}}</p>
            <ul class="option-edit-list my-3" *ngIf="obj.Choices.length > 0">
                <li *ngFor="let choice of obj.Choices; let i = index" class="d-flex">
                    <input type="text" [(ngModel)]="choice.ID" (keyup)="sanitizePossibiltyIds()" [name]="'id_'+i"
                        placeholder="ID" nbInput fullWidth class="background--white" [status]="'primary'" />
                    <localized-string-input 
                        [(value)]="choice.Name"
                        [inputName]="'name_'+i"
                        [(localizedString)]="choice.LocalizedNames" 
                        [localizedPlaceholder]="choice.Name" 
                        placeholder="Name" 
                        [status]="'primary'"
                    ></localized-string-input>
                    <nb-icon pack="custom-icons" icon="loeschen" class="cursor-pointer" (click)="deleteChoice(choice)">
                    </nb-icon>
                </li>
            </ul>
            <button nbButton status="primary" size="small" (click)="addChoice()"
                class="w-50">{{"🌐Products.Option.AddOption" | translate:lang}}</button>

        </div>

    </nb-card-body>
    <nb-card-footer class="d-flex">
        <button nbButton status="basic" (click)="dialogRef.close(false)" *ngIf="!disableClose"
            class="w-100 mr-2">{{"🌐General.Cancel" | translate:lang}}</button>
        <button nbButton status="success" (click)="save()" [disabled]="!validID"
            class="w-100 ml-2">{{"🌐General.Confirm" | translate:lang}}</button>
    </nb-card-footer>
</nb-card>