import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { getDetailsPageRouteForEntityType } from "app/@core/entity-types";
import { DataService } from "app/services/data.service";
import { GuiService } from "app/services/gui.service";
import { ConnectivityType } from "shared-gen/Device/IO/Connectivity/ConnectivityType";
import { Problem } from "shared-gen/Machine/Problem";
import { VmcStateDeviceReport } from "shared-gen/Model/Devices/Views/VmcStateDeviceReport";
import { EntityType } from "shared-gen/Model/Utils/EntityType";
import { VmcState } from "shared-gen/Vending/Status/VmcState";
import { toLocalDate } from "shared/date";
import { Localized } from "shared/localized";
import { getDeviceStatusColor } from "shared/model/devices/device.fun";
import { TranslationService } from "shared/services/translation.service";

/**
 * Displays the current status of the Device in a very compact format:
 * Vending state, connectivity, current problems.
 * TIDY: For historical reasons this component is called "VMC state component",
 * but meanwhile it displays more. Rename it, also the VmcStateComponentData class.
 */
@Component({
    selector: 'vmcstate',
    templateUrl: './vmcstate.component.html',
    styleUrls: ['./vmcstate.component.scss']
})
export class VmcstateComponent extends Localized {

    _deviceReport: VmcStateDeviceReport;

    vmcState: string = 'N/A';
    problems: Problem[] = [];

    showConnectivity = false;
    connectivityType: ConnectivityType = ConnectivityType.None;
    connectivityIcon = "connection-none";
    isSignalStrengthVisible = false;
    signalStrengthIcon = "empty";
    signalStrengthText = "";


    @Input()
    set deviceReport(ds: VmcStateDeviceReport) {
        this._deviceReport = ds;
        this.update();
    }

    @Input() details: boolean = false;
    @Input() showProblems: boolean = false;
    @Input() showID: boolean = false;
    @Input() showName: boolean = false;
    @Input() detailsPage: "JsonReport" | "DevicePage" = "JsonReport";

    state: string = 'N/A';
    stateOnline: 'N/A' | 'online' | 'offline' = 'N/A';
    stateActivated: 'N/A' | 'activated' | 'error' | 'notactivated' = 'notactivated';
    tooltip = 'N/A';

    private lastTimeOnline = 0;


    constructor(private dataService: DataService,
        translationService: TranslationService,
        private guiService: GuiService,
        private router: Router) {
        super(translationService);
    }

    update() {

        //console.log("VMC", this._deviceReport);

        if (!this._deviceReport)
            return;

        const activated = this._deviceReport?.["Activated"] ?? true;  // TODO: migration, so that all devices use .Activated
        //if(!activated)  return;

        const vmcState_ = this._deviceReport?.LastVmcState ?? VmcState.Intervention;
        const problems_ = this._deviceReport?.Problems ?? [];

        this.vmcState = this.translate("🌐VmcState." + vmcState_);
        this.problems = problems_;
        this.state = getDeviceStatusColor(vmcState_, problems_);

        this.lastTimeOnline = Date.parse(this._deviceReport?.LastTimeOnline)

        let offlineThreshold = new Date().getTime() - 25 * 60 * 1000; // 25min

        this.stateOnline = 'online';
        if (!this.lastTimeOnline || this.lastTimeOnline < offlineThreshold) {
            this.vmcState += " \n\r& " + this.translate("🌐General.Offline");
            this.stateOnline = 'offline';
        }

        this.stateActivated = activated ? 'activated' : 'notactivated';

        // Connectivity
        this.showConnectivity = this._deviceReport?.Connectivity != null;
        if (this.showConnectivity) {
            this.connectivityType = this._deviceReport?.Connectivity?.Type ?? ConnectivityType.None;
            switch (this.connectivityType) {
                case ConnectivityType.None: this.connectivityIcon = "connection-none"; break;
                case ConnectivityType.Wired: this.connectivityIcon = "connection-wired"; break;
                case ConnectivityType.Mobile: this.connectivityIcon = "connection-mobile"; break;
            }
            if (this.connectivityType != ConnectivityType.None) {
                const signalQuality = this._deviceReport?.Connectivity?.SignalQuality ?? null;
                const signalStrengthLevel = signalQuality != null ? Math.min(5, Math.trunc(signalQuality * 6)) : 0;
                this.isSignalStrengthVisible = signalQuality != null;
                this.signalStrengthIcon = "connection-mobile-" + signalStrengthLevel;
                this.signalStrengthText = Math.round(signalQuality * 100) + "%";
            }
            else {
                this.signalStrengthIcon = "empty";
                this.signalStrengthText = "";
            }
        }

        // Tooltip
        this.tooltip = (this._deviceReport?.Name || this._deviceReport?.DeviceID)
            + (': ' + (this.vmcState))
            + (this.stateOnline == 'offline' ?
                (" " + this.translate('🌐General.Since') + " " + toLocalDate(this._deviceReport?.LastTimeOnline))
                : (" \n\r" + " " + toLocalDate(this._deviceReport?.LastTimeOnline)))
            + " \n\r" + (this.stateActivated == 'activated' ?
                (this.translate('🌐Device.Activated') + " " + this.translate('🌐General.Since') + " " + toLocalDate(this._deviceReport?.ActivationDate))
                : "");//(this.translate('🌐Device.NotActivated')));
    }

    getVmcProblemsStr() {
        return this.problems.map(x => x.ID).join()
    }

    async openDetails() {
        if (!this.details) return;
        if (this.detailsPage == "JsonReport") {
            try {
                const device = await this.dataService.getDeviceReportById(this._deviceReport?.DeviceID);
                await this.guiService.showDeviceReportJson(device);
            }
            catch {
            }
        }
        else if (this.detailsPage == "DevicePage") {
            await this.router.navigate(
                [getDetailsPageRouteForEntityType(EntityType.Device) + "/" + this._deviceReport?.DeviceID]);
        }
    }

    navigateToDevice(deviceID: string) {
        this.router.navigate(
            [getDetailsPageRouteForEntityType(EntityType.Device) + "/" + this._deviceReport?.DeviceID]);
    }

    async showErrorDescription(problem: Problem, event: Event) {
        event?.stopPropagation();
        const codesRaw = problem.ID || this.translate("🌐General.Unknown");
        const codes = codesRaw.split(",").map(it => it.trim());
        const descriptions = await this.dataService.getFailureCodeDescriptions(codes,
            this.translationService.getLanguageCode());
        if (descriptions) {
            const lines = Object.keys(descriptions).map(key => "<li>" + key + ": " +
                descriptions[key].replace("\n", "<br/>") + "</li>").join("");
            this.guiService.messageDialog(this.translate("🌐General.FailureCode"),
                `<ul>${lines}</ul>`, _ => { });
        }
    }

}
