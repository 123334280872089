import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

// https://stackoverflow.com/questions/43067354/how-to-iterate-a-string-literal-type-in-typescript
export const SelectableDateRanges = ['Custom', 'PreviousYear', 'CurrentYear', 'PreviousMonth', 'CurrentMonth', 'PreviousWeek', 'CurrentWeek', 'PreviousDay', 'CurrentDay'] as const;
export type SelectedDateRange = typeof SelectableDateRanges[number];
// export type SelectedDateRange = 'Custom' | 'CurrentYear' | 'CurrentMonth' | 'CurrentWeek' | 'CurrentDay'

export interface DateRange {
    start: Date;
    end: Date;
}

interface Filter {
    selectableLocations: string[],
    selectableDevices: string[],
    locations: string[],
    devices: string[],
    dateRange: DateRange,
}


@Injectable({
    providedIn: "root"
})
export class StorageService {

    filter: BehaviorSubject<Filter | null> = new BehaviorSubject<Filter | null>(null);

    constructor() {
        this.updateFilter();
    }

    clear() {
        const keysToBeKeptAlive: string[] = [this.STORAGE_LAST_OPENED_VERSION];
        const storageEntriesToBeKeptAlive = keysToBeKeptAlive.map(key => ({
            key,
            value: localStorage.getItem(key)
        }));
        localStorage.clear();
        storageEntriesToBeKeptAlive.forEach(({key, value}) => {
            if (value === undefined || value === null) {
                return;
            }
            localStorage.setItem(key, value);
        })
    }

    clearUserData() {
        localStorage.removeItem(this.STORAGE_LOCATIONS_KEY);
        localStorage.removeItem(this.STORAGE_DEVICES_KEY);
    }

    setStringifiedValue(key, strvalue) {
        localStorage.setItem(key, strvalue);
    }

    setValue(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    }


    getStringifiedValue(key) {
        return localStorage.getItem(key);
    }

    getValue(key) {
        let val = localStorage.getItem(key);
        if (val) {
            const value = JSON.parse(val);
            return value;
        }
        return undefined;
    }

    deleteValue(key) {
        localStorage.removeItem(key);
    }


    private setFilterIDs(ids: string[], key: string) {
        const val = localStorage.getItem(key);
        ids = ids.filter(x => x ? true : false); // prune falsies
        const newVal = JSON.stringify(ids);
        if (val !== newVal) {
            this.setStringifiedValue(key, newVal);
            this.updateFilter();
        }
    }

    private getFilterIDs(key: string): string[] {
        let ids = this.getValue(key);
        if (ids) {
            ids = ids.filter(x => x ? true : false); // prune falsies
            return ids;
        }
        return [];
    }


    STORAGE_LOCATIONS_KEY = 'locations';
    STORAGE_SELECTABLE_LOCATIONS_KEY = 'included_locations';
    STORAGE_DEVICES_KEY = 'devices';
    STORAGE_SELECTABLE_DEVICES_KEY = 'included_devices';

    // ids of locations included in the current query (locationFilterIds OR all location IDs if none selected)
    setSelectableLocationsIDs(locations: string[]) {
        this.setFilterIDs(locations, this.STORAGE_SELECTABLE_LOCATIONS_KEY);
    }

    // ids of devices included in the current query (deviceFilterIDs OR all device IDs if none selected OR all device IDs of current locationFilter)
    setSelectableDeviceIDs(devices: string[]) {
        this.setFilterIDs(devices, this.STORAGE_SELECTABLE_DEVICES_KEY);
    }

    setLocationFilterIDs(locations: string[]) {
        this.setFilterIDs(locations, this.STORAGE_LOCATIONS_KEY);
    }

    setDeviceFilterIDs(devices: string[]) {
        this.setFilterIDs(devices, this.STORAGE_DEVICES_KEY);
    }

    getSelectableLocationIds() {
        return this.getFilterIDs(this.STORAGE_SELECTABLE_LOCATIONS_KEY);
    }

    getSelectableDeviceIds() {
        return this.getFilterIDs(this.STORAGE_SELECTABLE_DEVICES_KEY);
    }

    getLocationFilterIDs(): string[] {
        return this.getFilterIDs(this.STORAGE_LOCATIONS_KEY);
    }

    getDeviceFilterIDs(): string[] {
        return this.getFilterIDs(this.STORAGE_DEVICES_KEY);
    }


    STORAGE_DATERANGE_KEY = 'daterange';

    setDateRange(dateRange: DateRange) {
        let oldVal = this.getStringifiedValue(this.STORAGE_DATERANGE_KEY);
        this.setValue(this.STORAGE_DATERANGE_KEY, dateRange);
        let newVal = this.getStringifiedValue(this.STORAGE_DATERANGE_KEY); // account for the storage formatting
        if (oldVal != newVal) {
            this.updateFilter();
        }
    }

    getDateRange(): DateRange {
        let value = this.getValue(this.STORAGE_DATERANGE_KEY);
        if (value) {
            let dateRange: DateRange = { start: new Date(value.start), end: new Date(value.end) };
            return dateRange;
        }
        return undefined;
    }

    STORAGE_SELECTEDDATERANGE_KEY = 'selecteddaterange';
    STORAGE_LAST_OPENED_VERSION = 'last_opened_version';

    setSelectedDateRange(selectedDateRange: SelectedDateRange) {
        this.setValue(this.STORAGE_SELECTEDDATERANGE_KEY, selectedDateRange);
    }

    getSelectedDateRange(): SelectedDateRange {
        let value = this.getValue(this.STORAGE_SELECTEDDATERANGE_KEY);
        return value;
    }

    getLastOpenedVersion(): string | null {
        return localStorage.getItem(this.STORAGE_LAST_OPENED_VERSION);
    }

    setLastOpenedVersion(version: string) {
        localStorage.setItem(this.STORAGE_LAST_OPENED_VERSION, version);
    }


    private updateFilter() {
       this.filter.next(
            {
                locations: this.getLocationFilterIDs(),
                devices: this.getDeviceFilterIDs(),
                dateRange: this.getDateRange(),
                selectableDevices: this.getSelectableDeviceIds(),
                selectableLocations: this.getSelectableLocationIds()
            }
        );
    }
}
