
import { Option } from 'shared-gen/Model/Products/Option';

export const defaultOptions: Option[] = [
  {
    ID: 'Size',
    Name: 'Größe',
    Choices: [
      {
        ID: 'S',
        Name: 'S',
      },
      {
        ID: 'M',
        Name: 'M',
      },
      {
        ID: 'L',
        Name: 'L',
      }
    ]
  },
  {
    ID: 'Milk',
    Name: 'Milch',
    Choices: [
      {
        ID: 'D',
        Name: 'Kuhmilch',
      },
      {
        ID: 'ND',
        Name: 'Hafermilch',
      }, 
    ]
  },
  {
    ID: 'Beans',
    Name: 'Bohne',
    Choices: [
      {
        ID: 'N',
        Name: 'Natural',
      },
      {
        ID: 'DC',
        Name: 'Entkoffeiniert',
      }, 
    ]
  }
];