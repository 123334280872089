import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";
import { DataService } from "../../services/data.service";
import { sleep } from "../../../shared/utils/core-utils";
import { ILogEntry } from "../../../shared-gen/Utils/Model/ILogEntry";
import { UserStore } from "app/@core/stores/user.store";
import { hasRights } from "app/auth/iovent/rights";
import { Access } from "shared-gen/Model/Auth/Access";
import { Feature } from "shared-gen/Model/Auth/Feature";

@Component({
    selector: "device-log",
    templateUrl: "./device-log.component.html",
    styleUrls: ["./device-log.component.scss"]
})
export class DeviceLogComponent extends Localized implements OnInit, OnDestroy {

    @Input() deviceID: string = null;

    state: 'Loading' | 'Loaded' | 'Error' = 'Error';
    isStreaming = false;
    streamingProcess = "";
    hasRights = false;

    @Input() count: number = 10;
    logEntries: ILogEntry[] = [];

    constructor(private api: DataService,
        private userStore: UserStore,
        translationService: TranslationService) {
        super(translationService);
    }

    ngOnInit() {
        this.hasRights = hasRights(Feature.DeviceLogs, this.userStore.getUser(), Access.Read);
        this.reload();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.isStreaming = false;
    }

    async reload() {
        if (!this.hasRights)
            return;
        if (this.deviceID) {
            if (false == this.isStreaming)
                this.state = "Loading";
            try {
                this.logEntries = (await this.api.getLog(this.deviceID, this.count)).reverse();
                this.state = "Loaded";
                if (this.isStreaming)
                    await sleep(3000);
            }
            catch {
                this.state = "Error";
                if (this.isStreaming)
                    await sleep(5000);
            }
            if (this.isStreaming) {
                this.streamingProcess = this.streamingProcess.length == 3 ? "" : this.streamingProcess + ".";
                await this.reload();
            }
        }
    }

    changedStreaming() {
        if (this.isStreaming)
            this.reload();
    }


    getIcon(entry: ILogEntry): string {
        if (entry.LogLevel == "Fatal") return "flash";
        if (entry.LogLevel == "Error") return "error-circle";
        if (entry.LogLevel == "Warning") return "error-triangle";
        return "info";
    }


    getStatus(entry: ILogEntry): string {
        if (entry.LogLevel == "Fatal") return "error";
        if (entry.LogLevel == "Error") return "error";
        if (entry.LogLevel == "Warning") return "warning";
        return "info";
    }

}
