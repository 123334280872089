import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Localized } from 'shared/localized';
import { TranslationService } from 'shared/services/translation.service';

@Component({
  selector: 'iovent-product-image-editor',
  templateUrl: './product-image-editor.component.html',
  styleUrls: ['./product-image-editor.component.scss']
})
export class ProductImageEditorComponent extends Localized implements OnInit, AfterViewInit {

  @Input()
  data: any; // Dialog Data that might provide a prefetched image

  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('fileInput') fileInput: ElementRef;

  imgLoaded = false;
  image: HTMLImageElement | null = null;
  
  scale = 1;
  translateX = 0;
  translateY = 0;
  
  zoomSpeed = 2000;
  canvasWidth = 636 / 2; // Fixed canvas size
  canvasHeight = 916 / 2; // Fixed canvas size

  isDragging = false;
  lastMouseX = 0;
  lastMouseY = 0;

  constructor(
    public dialogRef: MatDialogRef<ProductImageEditorComponent>,
    translationService: TranslationService, 
  ) {
    super(translationService);
  }

  ngOnInit() {
    const { initialImage } = this.data;
    console.log('initial image ', typeof(initialImage), initialImage);
    if (initialImage) {
      this.loadFile(initialImage);
    }
  }

  ngAfterViewInit() {
    if (!this.data.initialImage) {
      this.selectFileToUpload();
    }
  }

  selectFileToUpload() {
    this.fileInput.nativeElement.click();
  }
  abort() {
    this.dialogRef.close(null);
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.loadFile(file);
    }
  }

  async loadFile(file: File) {
    if (!file) {
      return;
    }
    const readFile = (fileToRead: File) => new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (err) => reject(err);
      reader.readAsDataURL(fileToRead);
    });
    const loadImage = (src: string) => new Promise<HTMLImageElement>((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = (err) => reject(err);
      image.src = src;
    });

    const imageDataUrl = await readFile(file);
    this.image = await loadImage(imageDataUrl);
    this.imgLoaded = true;
    this.initializeCanvas();
  }

  initializeCanvas() {
    const canvas = this.canvasElement.nativeElement;
    canvas.width = this.canvasWidth;
    canvas.height = this.canvasHeight;
    this.scale = Math.min(this.canvasWidth / this.image.width, this.canvasHeight / this.image.height);
    this.translateX = this.canvasWidth / 2;
    this.translateY = this.canvasHeight / 2;
    this.zoomSpeed = (this.image.width / this.canvasWidth) * 2000;
    
    this.render();
  }

  canvasMouseDown(e) {
    this.isDragging = true;
    this.lastMouseX = parseInt(e.clientX);
    this.lastMouseY = parseInt(e.clientY);

  }
  canvasMouseUp(e) {
    this.isDragging = false;
  }
  canvasMouseOut(e) {
    this.isDragging = false;
  }
  canvasMouseMove(e) {
    if (this.isDragging) {
      const newMouseX = parseInt(e.clientX);
      const newMouseY = parseInt(e.clientY);
      this.translateX += (newMouseX - this.lastMouseX);
      this.translateY += (newMouseY - this.lastMouseY);
      this.lastMouseX = newMouseX;
      this.lastMouseY = newMouseY;
      this.render();
    }
  }

  canvasMouseWheel(e) {
    e.preventDefault();
    this.scale += (e.deltaY / this.zoomSpeed);
    this.render();
  }

  render(options: { renderHelpLines: boolean, renderTransparencyHint: boolean } = { renderHelpLines: true, renderTransparencyHint: true }) {
    if (!this.image) return;

    const canvas = this.canvasElement.nativeElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    

    ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transformation

    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    //render checkerboard
    if (options.renderTransparencyHint) {
      const squareSize = 20;
      ctx.fillStyle = 'rgba(0,0,0,0.1)';
      for (let i = 0; i < canvas.width; i += 2 * squareSize){
        for(let j = 0; j < canvas.height; j += 2 * squareSize){
            ctx.fillRect(i, j, squareSize, squareSize);
        }
      }
      for (let i = squareSize; i < canvas.width; i += 2 * squareSize){
        for(let j = squareSize; j < canvas.height; j += 2 * squareSize){
            ctx.fillRect(i, j, squareSize, squareSize);
        }
      }
      }
    
    // position image
    ctx.translate(this.translateX, this.translateY);
    ctx.scale(this.scale, this.scale);
    ctx.translate(-this.image.width/2, -this.image.height/2);

    ctx.drawImage(this.image, 0, 0, this.image.width, this.image.height);

    ctx.setTransform(1, 0, 0, 1, 0, 0); // Reset transformation

    // helper-lines
    if (options.renderHelpLines) {
      // render helper-lines
      const verticalLineX = 292 / 636 * canvas.width;
      const horizontalLineY = 597 / 916 * canvas.height;
      this.renderLine(ctx, verticalLineX, 0, verticalLineX, canvas.height, 'purple');
      this.renderLine(ctx, 0, horizontalLineY, canvas.width, horizontalLineY, 'purple');
    }
  }

  renderLine(ctx: CanvasRenderingContext2D, x1: number, y1: number, x2: number, y2: number, color: string) {
      ctx.beginPath();
      ctx.strokeStyle = color;
      ctx.moveTo(x1, y1);
      ctx.lineTo(x2, y2);
      ctx.stroke();
  }

  async saveImage() {
    if (!this.image) return;

    this.render({ renderHelpLines: false, renderTransparencyHint: false });

    const canvas = this.canvasElement.nativeElement;
    const imageBlob = await new Promise<Blob>(resolve => canvas.toBlob(resolve, 'image/png'));
    const file = new File([imageBlob], "product-image.png", { type: 'image/png' });

    this.dialogRef.close(file);

  }
}