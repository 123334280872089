<div class="text-center d-flex align-items-end">
    <span *ngIf="_supplies" class="number">{{state.MilkCount}}</span>
    <nb-icon icon="milk" [class]="'milk milk--'+state.Milk" pack="custom-icons"
        [nbTooltip]="'Resource.Milk' | translate:lang"></nb-icon>
    <span *ngIf="_supplies" class="number">{{state.BeansLeftCount}}</span>
    <nb-icon icon="bean-left" [class]="'bean bean--left bean--'+state.BeansLeft" pack="custom-icons"
        [nbTooltip]="'Resource.BeansLeft' | translate:lang"></nb-icon>
    <span *ngIf="_supplies" class="number">{{state.BeansRightCount}}</span>
    <nb-icon icon="bean-right" [class]="'bean bean--right bean--'+state.BeansRight" pack="custom-icons"
        [nbTooltip]="'Resource.BeansRight' | translate:lang"></nb-icon>
</div>
