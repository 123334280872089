import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";

@Component({
    selector: "iovent-html-editor",
    templateUrl: "./html-editor.component.html",
    styleUrls: ["./html-editor.component.scss"],
})
export class HtmlEditorComponent implements AfterViewInit, OnDestroy {

    @Input()
    value: string;
    @Output()
    valueChange: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild("ckeditorValue")
    ckeditorRef: ElementRef<HTMLDivElement>;

    private editor: any; // actual ckeditor instance

    constructor() {}

    ngOnDestroy(): void {
      this.editor.destroy();
    }

    async ngAfterViewInit(): Promise<void> {
        // get the ckeditor plugins and components passed via a global window variable. This is initialized in index.html
        const { ClassicEditor, Essentials, Bold, Italic, Font, Paragraph, List, HorizontalLine, SourceEditing } = (window as any).IoventCkEditor;

        this.editor = await ClassicEditor.create(this.ckeditorRef.nativeElement, {
            plugins: [Essentials, Bold, Italic, Font, Paragraph, List, HorizontalLine, SourceEditing],
            toolbar: {
                items: ["bold", "italic", "|", "bulletedList", "numberedList", "|", "fontSize", "fontColor", "|", "horizontalLine", "|", "sourceEditing"],
            },
            initialData: this.value
        });
        this.editor.model.document.on("change:data", () => {
            const data = this.editor.getData();
            this.valueChange.emit(data);
        });
    }
}
