
export function cleanID(id: string): string {
    if (!id) return '';
    return id.replace(/[^a-zA-Z0-9_]/g, '');
}

export function isAllowedImageType(type: string): boolean {
    switch (type) {
        case 'image/jpeg':
        case 'image/png':
            return true;

    }
    return false;
}
