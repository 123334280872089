/**
* TODO: #78 Portal: Benutzer und Rollen
*             
*             See TuR Leistungsbeschreibung § 7.3.3.
*             The roles "Anlage von Rollen" (creation of roles) and
*             "Media-Content tausch (z.B. Videos, Popups)" (change media content) are
*             currently unsupported.
*/
export enum Feature {
    /** Access to <see cref="T:Iovent.Model.Products.ProductBase" /> and their <see cref="T:Iovent.Model.Products.Product" />s. */
    Products = "Products",
    /** Access to campaigns. */
    Campaigns = "Campaigns",
    /** Access to <see cref="T:Iovent.Model.Locations.Location" />s. */
    Locations = "Locations",
    /** Access to <see cref="T:Iovent.Model.Devices.Device" />s. */
    Devices = "Devices",
    /** Access to <see cref="T:Iovent.Model.Devices.Config.DeviceConfig" />s. */
    DeviceConfigs = "DeviceConfigs",
    /** Access to <see cref="T:Iovent.Model.Devices.DeviceReport" />s. */
    DeviceReports = "DeviceReports",
    /** Access to device logs */
    DeviceLogs = "DeviceLogs",
    /** Access to device remote control */
    DeviceRemoteControl = "DeviceRemoteControl",
    /** Access to restart Devices and controllers */
    DeviceRestart = "DeviceRestart",
    /**
    * Pairing of <see cref="N:Iovent.Device" />s, i.e. connecting raw Device templates
    *             to an existing Device instance on the Backend.
    *             Write access means that pairing may be performed. Read access has no meaning yet.
    */
    DevicePairing = "DevicePairing",
    /** Import/Clone devices from the same or other backend instance */
    DeviceImport = "DeviceImport",
    /** Access to <see cref="T:Iovent.Model.Users.User" /> profiles. */
    Users = "Users",
    /** Assignment of <see cref="T:Iovent.Model.Devices.Device" />s to <see cref="T:Iovent.Model.Locations.Location" />s. */
    LocationDevices = "LocationDevices",
    /** Access to <see cref="T:Iovent.Model.Menus.Menu" />s. */
    Menus = "Menus",
    /** Access to <see cref="T:Iovent.Model.Prices.Prices" />. */
    Prices = "Prices",
    /** Access to <see cref="T:Iovent.Model.Selections.Selections" />. */
    Selections = "Selections",
    /** Access to the <see cref="T:Iovent.Events.CashBalance" />s. */
    CashBalances = "CashBalances",
    /** Access to the <see cref="T:Iovent.Model.Reports.TransactionReport" />s. */
    Bills = "Bills",
    /**
    * Access to the <see cref="T:Iovent.Events.Transaction" />s, including journals,
    *             statistics charts and so on.
    */
    Transactions = "Transactions",
    /** Access to the <see cref="T:Iovent.Events.ComponentEvent" />s. */
    ComponentEvents = "ComponentEvents",
    /** Access to the auto mail settings of all users of the allowed locations. */
    LocationAutoMails = "LocationAutoMails",
    /**
    * Access to the auto mail settings of the current user of the allowed locations
    *             (more restrictive than <see cref="F:Iovent.Model.Auth.Feature.LocationAutoMails" />).
    */
    UserAutoMails = "UserAutoMails",
    /**
    * Access to <see cref="T:Iovent.Model.Vouchers.QR.QRVoucher" />s.
    *             <see cref="F:Iovent.Model.Auth.Access.Write" /> in combination with <see cref="!:Level.Own" />
    *             means, that only QR vouchers can created, which are valid for the locations of this user.
    */
    QRVouchers = "QRVouchers",
    /** Access to the <see cref="T:Iovent.Model.Devices.Config.PaymentConfig" />s. */
    PaymentConfigs = "PaymentConfigs",
    /** Define conditional prices, for now only QR-Activated Price liststra */
    ConditionalPrices = "ConditionalPrices",
    /** Sales Reports are like Cash Balances on custom timeframes */
    SalesReports = "SalesReports",
    /**
    * Access to the 2nd milk sort resource for an Eversys product.
    *             Only read access is relevant. Milk sort can be set whenever a role
    *             has the right to edit a product.
    */
    SecondMilkSort = "SecondMilkSort",
    /** Allows the usage of the Eversys Product Generator in Portal. Write Access for Products needed. */
    EversysProductGenerator = "EversysProductGenerator",
    ProblemStatistics = "ProblemStatistics",
    ResourceStatistics = "ResourceStatistics",
    ConfigurationCategoryView = "ConfigurationCategoryView",
    /** Access to the current <see cref="T:Iovent.Model.MobileOrders.MobileMenu" />s. */
    MobileMenus = "MobileMenus",
    /** TuR feature: Access to the SaniFair EANs of a <see cref="T:Iovent.Model.Locations.Location" />s. */
    SaniFairLocationEANs = "SaniFairLocationEANs",
    /** TuR feature: Access to the possible <see cref="!:Location.Mieteinheit" /> values. */
    Mieteinheit = "Mieteinheit",
    SupplyOrders = "SupplyOrders"
}
