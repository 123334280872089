<nb-card>

    <nb-card-header>{{ title }}</nb-card-header>

    <nb-card-body [innerHTML]="confirmMessage">
    </nb-card-body>


    <nb-card-footer class="d-flex">
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-100 mr-2">{{"🌐General.Cancel" |
            translate:lang}}</button>
        <button id="mat-dialog-0-confirm-btn" nbButton status="success" (click)="dialogRef.close(true)"
            class="w-100 ml-2">{{"🌐General.Confirm" | translate:lang}}</button>
    </nb-card-footer>

</nb-card>
