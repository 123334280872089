import { LanguageCode } from "../../shared-gen/Model/Localization/LanguageCode";
import { environmentDefaults, IEnvironment } from "./environment-defaults";

/**
 * Development environment for the "iovent tur" project (tur.iovent.net).
 */
export const environment: IEnvironment = {
    ...environmentDefaults,
    
    // Project settings
    projectName: "tur",
    //backendUrl: "https://tur.iovent.net/api/1.0",
    //backendUrl: "https://tur-cs.dev.iovent.net/api/1.0",
    defaultLanguage: LanguageCode.DE,
    contact: {
        name: "Coffema Service Center",
        mail: "service.tank.rast@coffema.de",
        phone: "+49 40 500 25 450",
        phoneClean: "+494050025450"
    },
    priceMinStepCents: 5,
    totalPriceMinStepCents: 10,

    // Test settings
    //testStage: "Dev",
    testWarning: "TuR",
    
};

