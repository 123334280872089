<nb-card class="nb-nested-card" *ngIf="hasReadRight">
    <nb-card-header class="d-flex justify-content-between flex-wrap">
        <span class="px-3 pb-2">{{"🌐General.Screenshot" | translate:lang}}</span>
        <div class="mt-1 px-3 d-flex justify-content-between flex-wrap">
            <nb-checkbox class="pr-3" status="info" [(ngModel)]="isStreaming" (change)="changedStreaming()">
                {{"🌐General.Streaming" | translate:lang}}</nb-checkbox>
            <nb-checkbox class="pr-3" *ngIf="isStreaming && hasWriteRight" [disabled]="!isStreaming"
                status="info" [(ngModel)]="isRemoteControl" (change)="changedRemoteControl()">
                {{"🌐General.RemoteControl" | translate:lang}}
            </nb-checkbox>
            <span class="streaming-process pr-3">{{streamingProcess}}</span>
            <button class="mx-3" nbButton size="small" status="info" [disabled]="isStreaming"
                (click)="reload()">{{"🌐General.Reload" | translate:lang}}</button>
        </div>
    </nb-card-header>
    <nb-card-body>
        <div class="screenshot-frame">
            <div *ngIf="state == 'Loading'">{{"🌐General.Loading" | translate:lang}}...</div>
            <div *ngIf="state == 'Offline'">{{"🌐General.Offline" | translate:lang}}</div>
            <div *ngIf="state == 'Error'">{{"🌐General.Error" | translate:lang}}</div>
            <img *ngIf="state == 'Loaded'" [src]="imageData" width="100%" [ngClass]="isRemoteControl ? 'crosshair' : ''"
                (click)="clicked($event)" />
        </div>
    </nb-card-body>
</nb-card>
