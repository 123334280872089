<nb-card>
    <nb-card-header>{{"🌐Settings.Title" | translate:lang}}</nb-card-header>
    <nb-card-body>

        <div class="row align-items-center my-3">
            <div class="col-3">
                <label for="id" class="mb-0">{{"🌐Settings.ID" | translate:lang}}</label>
            </div>
            <div class="col-9">
                <input type="text" id="input-price-list-id" [(ngModel)]="entity.ID" name="id" placeholder="ID" nbInput
                    fullWidth (keyup)="checkID()" (change)="checkID()" [status]="'primary'" />
                <p class="error mb-0" [innerHTML]="idErrorMessage || '&nbsp;'"></p> <!-- not ngIf to reserve space -->
            </div>
        </div>

        <div class="row align-items-center my-3">
            <div class="col-3">
                <label for="name" class="mb-0">Name:</label>
            </div>
            <div class="col-9">
                <input type="text" id="input-price-list-name" [(ngModel)]="entity.Name" name="name" placeholder="Name"
                    nbInput fullWidth (keyup)="checkName()" (change)="checkName()" [status]="'primary'" />
                <p class="error mb-0" [innerHTML]="nameErrorMessage || '&nbsp;'"></p> <!-- not ngIf to reserve space -->
            </div>
        </div>

    </nb-card-body>
    <nb-card-footer class="d-flex">
        <button nbButton id="button-price-list-confirm" status="success" (click)="save()"
            [disabled]="!isIDOK || !isNameSet" class="w-50 mr-2">{{"🌐General.Confirm" | translate:lang}}</button>
        <button nbButton status="basic" (click)="dialogRef.close(false)" *ngIf="!disableClose"
            class="w-50 ml-2">{{"🌐General.Cancel" | translate:lang}}</button>
    </nb-card-footer>
</nb-card>
