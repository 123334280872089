/**
* TODO #78
*             See TuR Leistungsbeschreibung § 7.3.3.
*/
export enum Access {
    /** No access to a feature. */
    None = "None",
    /** Read access to a feature. */
    Read = "Read",
    /** Write access to a feature. */
    Write = "Write",
    /** Read and write access to a feature. */
    Both = "Both"
}
