<button nbButton (click)="addSelection()">{{"🌐ProductSelection.Add" | translate:lang}}</button>
<p>TODO: Product.Selections was removed, use SelectionsConfig and Product.SelectionNumber</p>
<!--
<ng-container *ngFor="let selection of product.Selections; let i=index;">
	<div>

		<div class="row align-items-center my-3">
			<div class="col-7">
				<label class="mb-0" for="machine-nbr">Anwahlnummer:</label>
			</div>

			<div class="col-5">
				<input id="machine-nbr" [(ngModel)]="selection.Number" name="instServingTime" placeholder="Servierzeit" nbInput fullWidth class="text-right"/>
			</div>
		</div>

		<div class="row align-items-center my-3">
			<div class="col-7">
				<label class="mb-0" for="machine-cap">Kapazität:</label>
			</div>

			<div class="col-5">
				<input id="machine-cap" [(ngModel)]="selection.Capacity" name="instCapacity" placeholder="Kapazität" nbInput fullWidth class="text-right"/>
			</div>
		</div>

		<div class="icon-card-header-outer cursor-pointer" (click)="deleteSelection(i)" *ngIf="product.Selections.length>1">
			<nb-icon pack="custom-icons" icon="loeschen"></nb-icon>
		</div>
		<hr/>
	</div>

</ng-container>
-->
