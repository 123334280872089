<nb-card>

    <nb-card-header>{{"🌐General.SetDate" | translate:lang}}) </nb-card-header>

    <nb-card-body>

        <ng-container>
            <div class="row align-items-center my-3">
                <label class="col-6">{{"🌐General.Date" | translate:lang}}</label>
                <input class="col-6" fullWidth nbInput [nbDatepicker]="dateTimePicker" [(ngModel)]="date">
                <!---->
                <nb-date-timepicker #dateTimePicker format="yyyy-MM-dd HH:mm" [min]="minDate" [max]="now">
                </nb-date-timepicker><!-- -->
            </div>
        </ng-container>

    </nb-card-body>

    <nb-card-footer class="d-flex">
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-100 mr-2">{{"🌐General.Cancel" |
            translate:lang}}</button>
        <button nbButton status="success" (click)="confirm()" class="w-100 ml-2">{{"🌐General.Confirm" |
            translate:lang}}</button>
    </nb-card-footer>

</nb-card>
