import { Currency } from "../../../shared-gen/Model/Utils/Currency";

// TODO: fetch Provider in Portal and read from provider! #
export function getCurrencyByProviderID(providerID: string): Currency {
    switch (providerID) {
        case "tur": return Currency.EUR;
        case "pay": return Currency.EUR;
        case "storm": return Currency.DKK;
        case "coles": return Currency.AUD;
        case "cmcr": return Currency.SGD;
        case "witzig": return Currency.CHF;
        case "dunkinus": return Currency.USD;
        case "kaffeknappen": return Currency.NOK;
        case "coffemapl": return Currency.PLN;
        default: return Currency.EUR;
    }
}
