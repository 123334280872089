/** Operational status of a peripheral. */
export enum PeripheralStatus {
    /** There is no such device on the bus. */
    Removed = "Removed",
    /**
    * The peripheral should be on the bus, but the
    *             VMC can currently not establish a connection.
    */
    Offline = "Offline",
    /**
    * The peripheral was just reset and is now within
    *             its setup process, which could take some seconds.
    */
    Setup = "Setup",
    /** The peripheral is operational and can receive commands. */
    Online = "Online",
    /** The peripheral is operational and busy */
    Busy = "Busy"
}
