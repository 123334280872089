<div *ngIf="showFilter.length > 0" [ngClass]="{'sticky-frame' : stickyFrame}" class="iovent-table-header">
    <div class="row mb-1">
        <div *ngIf="isFilterSet('devicefilter', 'locationfilter')" [class]="'col-12'">
            <devicefilter [showLocations]="isFilterSet('locationfilter')" [showDevices]="isFilterSet('devicefilter')">
            </devicefilter>
        </div>
    </div>
    <div class="row mb-1">
        <div *ngIf="isFilterSet('datefilter')" [class]="'col-6 col-xl-3'">
            <datefilter></datefilter>
        </div>
        <div *ngIf="isFilterSet('filter')" [class]="'col-3 col-xl-3'">
            <ng-content select="[filter]"></ng-content>
        </div>
        <div *ngIf="isFilterSet('search')" [class]="'col d-flex justify-content-end'">
            <ng-content select="[search]"></ng-content>
        </div>
    </div>
</div>

<ng-container *ngIf="type=='grid'||type=='tree'">

    <ng-template #defaultHeader let-value="value" let-colsize="colsize" let-column="column" let-colindex="index"
        let-maxcolindex="maxindex">

        <!-- When editing of columns is active -->
        <div *ngIf="editColumns" class="column-header" [class]="colsize ? 'column-header-' + colsize : ''">
            <div class="column-title-container">
                <div class="column-filter-header">
                    <span class="breakword column-title" [lang]="lang.languageCode">{{value | translate:lang}}</span>
                </div>
                <ng-container *ngIf="columnNames.includes(column)">
                    <div class="delete-button-container">
                        <button nbButton ghost shape="round" size="medium" status="danger"
                            (click)="editColumn(column,'hide')">
                            <nb-icon icon="close-circle"></nb-icon>
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngIf="!columnNames.includes(column)">
                    <div class="delete-button-container">
                        <button nbButton ghost shape="round" size="medium" status="success"
                            (click)="editColumn(column,'show')">
                            <nb-icon icon="plus-circle"></nb-icon>
                        </button>
                    </div>
                </ng-container>
            </div>

            <div class="column-title-container">
                <div>
                    <button *ngIf="colindex>0" nbButton ghost shape="round" size="medium" class="leftright-button"
                        (click)="editColumn(column,'left')">
                        <nb-icon icon="arrow-left"></nb-icon>
                    </button>
                </div>
                <div *ngIf="colindex<maxcolindex-1" class="column-right">
                    <button nbButton ghost shape="round" size="medium" class="leftright-button"
                        (click)="editColumn(column,'right')">
                        <nb-icon icon="arrow-right"></nb-icon>
                    </button>
                </div>
            </div>
        </div>

        <!-- When editing of columns is inactive -->
        <div *ngIf="!editColumns" [class]="(colsize ? 'column-header-' + colsize : '') + ' column-header-non-edit'">
            <div class="column-title-container">
                <div [ngClass]="{'sortable text-link' : (!column.startsWith('$') && !getColumnTemplateDirective(column).NoSort) }"
                    class="column-filter-header" (click)="toogleSort(column)">
                    <div class="mr-1 column-title breakword" [lang]="lang.languageCode">{{value | translate:lang}}</div>
                    <div class="sort-chevron">
                        <nb-icon *ngIf="sortBy == column || sortBy == getColumnTemplateDirective(column).SortBy"
                            [icon]="orderAsc?'chevron-up-outline':'chevron-down-outline'"></nb-icon>
                    </div>
                </div>

                <button
                    *ngIf="!(column.startsWith('$') || getColumnTemplateDirective(column).NoFilter || (filter[column] && filter[column].filterParts.length > 0))"
                    class="float-left" nbButton ghost shape="round" size="medium" class="filter-button"
                    (click)="addFilterPart(column);">
                    <nb-icon icon="funnel-outline"></nb-icon>
                </button>

            </div>


            <div class="mt-auto">
                <div class="d-flex flex-column position-relative">

                    <ngcontainer *ngIf="!column.startsWith('$') && !getColumnTemplateDirective(column).NoFilter">

                        <ngcontainer *ngIf="filter[column] && filter[column].filterParts.length > 0">

                            <ngcontainer
                                *ngFor="let part of filter[column].filterParts; let index = index; trackBy:trackByIndex;">

                                <div class="filter pt-1">

                                    <nb-select class="value-op pr-1" shape="semi-round" size="tiny"
                                        [(selected)]="part.valueOp"
                                        (selectedChange)="changeFilterPartValueOp(column, index, $event)">
                                        <nb-option *ngFor="let op of valueOps" [value]="op">{{op}}</nb-option>
                                    </nb-select>

                                    <div class="filter-value">
                                        <input *ngIf="!getColumnHasFilterValues(column)" nbInput class="filter-value"
                                            shape="semi-round" fieldSize="tiny" type="text"
                                            placeholder="{{'🌐General.Value' | translate:lang}}" [value]="part.value"
                                            (input)="part.value=$any($event.target).value" (keyup.enter)="query()"
                                            (blur)="startAutoQueryTimeout()" (keyup)="startAutoQueryTimeout()">
                                        <nb-select *ngIf="getColumnHasFilterValues(column)" class="filter-value"
                                            shape="semi-round" size="tiny" [(selected)]="part.value"
                                            (selectedChange)="query()">
                                            <nb-option *ngFor="let v of getColumnTemplateDirective(column).Values"
                                                [value]="v">
                                                {{'🌐General.'+v | translate:lang}}
                                            </nb-option>
                                        </nb-select>
                                    </div>

                                    <div class="filter-remove">
                                        <button class="button" shape="round" size="medium" nbButton ghost
                                            status="danger" (click)="removeFilterPart(column, index)">
                                            <nb-icon icon="close-circle-outline"></nb-icon>
                                        </button>
                                    </div>

                                </div>

                            </ngcontainer>

                        </ngcontainer>

                        <div class="filter-footer" *ngIf="filter[column] && filter[column].filterParts.length > 0">
                            <button *ngIf="filter[column] && filter[column].filterParts.length > 0" class="float-left"
                                nbButton ghost shape="round" size="medium" status="success"
                                (click)="addFilterPart(column);">
                                <nb-icon icon="plus-circle-outline"></nb-icon>
                            </button>
                            <button class="button float-right" nbButton
                                [style.visibility]="(filter[column] && filter[column].filterParts.length > 1) ? 'visible' : 'hidden'"
                                shape="semi-round" status="info" (click)="toggleColumnOp(column);">
                                {{filter[column] ? filter[column].columnOp : ""}}
                            </button>
                        </div>

                    </ngcontainer>

                </div>
            </div>

        </div>

    </ng-template>

    <div class="table-container">
    <!--{{tableName}}-->
        <table *ngIf="columns.length>0" [nbTreeGrid]="_treedata" [ngClass]="{'sticky-frame' : stickyFrame}">

            <tr nbTreeGridHeaderRow *nbTreeGridHeaderRowDef="columns"></tr>
            <tr nbTreeGridRow [clickToToggle]="false" *nbTreeGridRowDef="let row; let even = even; columns: columns"
                [class]="'tr-data-row ' + (even ? 'even': 'odd')"></tr>

            <ng-container *ngFor="let column of columns; let index = index" [nbTreeGridColumnDef]="column">

                <th nbTreeGridHeaderCell *nbTreeGridHeaderCellDef id="tableheader" [ngClass]="{
            'edit' : (column=='$Actions' && !getColumnTemplateDirective(column)), 'column-hidden' : !columnNames.includes(column) && column!='$Actions'
            }" [class]="getColumnTemplateDirective(column)?.Classes + ' column-header-' + ((filter[column] && filter[column].filterParts.length > 0) ? 'xxl' : (getColumnTemplateDirective(column).Size || 'xxl'))">
                    <ng-container *ngIf="column!='$Actions' || !tableName" [ngTemplateOutlet]="defaultHeader"
                        [ngTemplateOutletContext]="{column:column,index:index,value:getColumnTemplateDirective(column).Header,maxindex:columns.length-1}">
                    </ng-container>
                    <ng-container *ngIf="column=='$Actions' && tableName">
                        <button nbButton ghost shape="round" size="medium" (click)="toggleColumnEdit()">
                            <nb-icon class="edit-button" [status]="editColumns ? 'success' : '' "
                                [icon]="editColumns ? 'done-all' : 'edit-outline'"></nb-icon>
                        </button>
                        <button nbButton ghost shape="round" size="medium" (click)="openTableHelpDialog()">
                            <nb-icon icon="question-mark-circle-outline"></nb-icon>
                        </button>

                    </ng-container>
                    <!--{{column}}-->
                </th>

                <td nbTreeGridCell *nbTreeGridCellDef="let row"
                    (click)="getColumnTemplateDirective(column)?.forwardClick(row.data.inner)"
                    [attr.colspan]="!isDetails(row)?1:columns.length" [hidden]="isDetails(row)&&index>0"
                    [class.expanded]="row.expanded"
                    [ngClass]="{ 'expanded' : row.expanded, 'details' : isDetails(row), 'column-hidden' : !columnNames.includes(column) }"
                    [class]="getColumnTemplateDirective(column)?.Classes">
                    <!-- TODO: correct cursor for clickable cells -->
                    <!-- TODO:  detail rows:  [ngStyle]="{ 'top.px': row.expanded ? getTdTop() : 0 }" -->
                    <ng-container *ngIf="index==0">
                        <span [ngStyle]="{'padding-left':+(row.data.depth).toString()+'em'}"></span>
                        <span *ngIf="isLeaf(row)" class="pl-2"></span>
                        <nb-tree-grid-row-toggle *ngIf="!isLeaf(row)" [expanded]="expanded"></nb-tree-grid-row-toggle>
                    </ng-container>
                    <ng-container>
                        <!--*ngIf="getColumnTemplateDirective(column)"-->
                        <ng-container *ngFor="let value of [ getPropertyByPath(row.data.inner, column) ]">
                            <ng-container
                                *ngIf="!isDetails(row) && row.data.inner && (column.startsWith('$') || (column.startsWith('@') || value !== undefined) )">
                                <ng-container [ngTemplateOutlet]="getColumnTemplate(column)"
                                    [ngTemplateOutletContext]="{row:row,column:column,index:index,leaf:isLeaf(row),depth:row.data.depth,data:row.data.inner,value:value}">
                                </ng-container>
                            </ng-container>
                            <!--{{value==undefined ? "empty" : value}}-->
                        </ng-container>
                        <!--{{column}}-->
                    </ng-container>

                    <ng-container *ngIf="isDetails(row)" [ngTemplateOutlet]="detailTemplate"
                        [ngTemplateOutletContext]="{row:row,column:column,index:index,leaf:isLeaf(row),depth:row.data.depth,data:row.data.inner}">
                    </ng-container>
                    <!--{{json(row.data)}}
            {{getColumnTemplateDirective(column)}}
            {{isDetails(row)}}
            {{row.data.inner[column]||'-'}}
            -->
                </td>

            </ng-container>

            <ng-template #ActionsColumnTemplate Size="s" Column="$Actions" NoFilter NoSort Header="🌐General.Actions">
            </ng-template>
            <ng-content></ng-content>

        </table>
    </div>

</ng-container>


<ng-container *ngIf="type=='table'">
    <table [ngClass]="{'sticky-frame' : stickyFrame}">
        <ng-content></ng-content>
    </table>
</ng-container>


<div class="tablespacer"></div>


<div *ngIf="!infiniteScroll || (infiniteScroll && _data?.PageIndex+1 < totalPages)"
    [ngClass]="{'sticky-frame' : stickyFrame}"
    class="iovent-table-footer mt-1 d-block d-sm-flex flex-row align-items-center">

    <ng-container *ngIf="!infiniteScroll">

        <div class="col-12 col-sm-3 text-center text-sm-left mb-3 mb-sm-0">
            <div class="iovent-table-entries">
                {{"🌐General.Entries" | translate:lang}}: {{firstIndex}} − {{lastIndex}} / {{totalCount}}{{isIncomplete
                ? "+" : ""}}
            </div>
        </div>

        <div class="col-12 col-sm-6 text-center text-sm-left mb-3 mb-sm-0">
            <ul *ngIf="totalPages > 1" class="pagination justify-content-center mb-0">

                <li (click)="openPage(1)" class="page-item" [class.disabled]="_data.PageIndex < 1">
                    <a class="page-link">
                        <span aria-hidden="true">&laquo;</span>
                        <span class="sr-only">{{"🌐Table.First" | translate:lang}}</span>
                    </a>
                </li>

                <li (click)="openPage(_data.PageIndex+1-1)" class="page-item" [class.disabled]="_data.PageIndex < 1">
                    <a class="page-link">
                        <span aria-hidden="true">&lt;</span>
                        <span class="sr-only">{{("🌐Table.Back" | translate:lang)}}</span>
                    </a>
                </li>

                <li *ngFor="let page of pages" (click)="openPage(page)"
                    [class]="'page-item nav-pagination__navigation__item ' + (_data.PageIndex+1 == page ? 'active' : '')">
                    <a class="page-link">{{page}}</a>
                </li>

                <li (click)="openPage(_data.PageIndex+1+1)" class="page-item"
                    [class.disabled]="totalPages == _data.PageIndex+1">
                    <a class="page-link">
                        <span aria-hidden="true">&gt;</span>
                        <span class="sr-only">{{("🌐Table.Further" | translate:lang)}}</span>
                    </a>
                </li>

                <li *ngIf="!isIncomplete" (click)="openPage(totalPages)" class="page-item"
                    [class.disabled]="totalPages == _data.PageIndex+1">
                    <a class="page-link">
                        <span aria-hidden="true">&raquo;</span>
                        <span class="sr-only">{{("🌐Table.Latest" | translate:lang)}}</span>
                    </a>
                </li>

            </ul>
        </div>

        <div class="col-12 col-sm-3 text-center text-sm-right mb-3 mb-sm-0">
            <div class="iovent-table-entries" *ngIf="pageSizes[0] < totalCount">
                {{"🌐Table.Show" | translate:lang}}&nbsp;
                <nb-select [(ngModel)]="PageSize" (selectedChange)="changePageSize();" name="PageSize" class="mx-2"
                    [status]="'primary'">
                    <nb-option [value]="size" *ngFor="let size of pageSizes">{{size}}</nb-option>
                </nb-select>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="infiniteScroll && (_data.PageIndex+1 < totalPages)">
        <div class="col-12 col-sm-3 text-center text-sm-left mb-3 mb-sm-0">
            <button nbButton (click)="openPage(_data.PageIndex+1)">{{("🌐Table.NextPage" | translate:lang)
                .replace("%page-size%", PageSize)}}</button>
        </div>
    </ng-container>

</div>
