import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit } from '@angular/core'; "@angular/core";
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";
import { DataService } from "../../services/data.service";
import { cloneDeep, sleep } from "../../../shared/utils/core-utils";
import { ILogEntry } from "../../../shared-gen/Utils/Model/ILogEntry";
import { UserStore } from "app/@core/stores/user.store";
import { hasRights } from "app/auth/iovent/rights";
import { Access } from "shared-gen/Model/Auth/Access";
import { Feature } from "shared-gen/Model/Auth/Feature";
import { TimedData } from "shared-gen/Model/TimedData";
import { NbDateService } from "@nebular/theme";

@Component({
    selector: "timed-data",
    templateUrl: "./timed-data.component.html",
    styleUrls: ["./timed-data.component.scss"]
})
export class TimedDataComponent<T extends TimedData> extends Localized
    implements OnInit, OnDestroy, AfterViewInit {

    private _data: { Timed: T[] } = undefined;
    @Input()
    set data(value: { Timed: T[] }) {
        this._data = value;
        if (this.initialized) {
            if (!this.data.Timed || this.data.Timed.length == 0) {
                this.addTimedEntry();
            }
            this.changeSelectedTimedEntry(0); // first entry should be the newest  // TODO: select current ?
        }
    }
    get data(): { Timed: T[] } {
        return this._data;
    }

    @Input() isEditable: boolean;
    @Input() isAlwaysUnlocked: boolean = false;

    @Input() addMissingDataToTimedEntry; // : (timedData: T) => boolean; = undefined;


    get selectedTimedEntry(): T {
        if (this.data?.Timed) {
            return this.data.Timed[this.selectedTimedEntryIndex];
        }
        return null;
    }
    @Output() SelectedTimedEntryEvent = new EventEmitter<T>();

    initialized: boolean = false;
    selectedTimedEntryIndex: number;

    changeSelectedTimedEntry(index: number) {
        this.selectedTimedEntryIndex = index;
        if (this.addMissingDataToTimedEntry) {
            this.addMissingDataToTimedEntry(this.selectedTimedEntry);
        }
        this.selectedTimedEntryStartZoneDate = new Date(this.selectedTimedEntry.StartZoneDate);
        this.referenceDate = this.dateService.addDay(this.dateService.today(), 1) // TODO: timezone instead of +1
        this.selectedTimedEntryIsLocked = this.selectedTimedEntryStartZoneDate < this.referenceDate;
        this.SelectedTimedEntryEvent.emit(this.selectedTimedEntry);
    }

    selectedTimedEntryStartZoneDate: Date;  // TODO: consistent string <=> Date mapping
    referenceDate: Date;
    selectedTimedEntryIsLocked: boolean

    // TODO: rename
    static readonly minDate = new Date("2021-09-30");
    @Input() externalMinStartDate: Date = TimedDataComponent.minDate; // e.g. this.asDate(this.device.StartZoneDate);

    // TODO: use simpler and project consistent date formating

    dateToDayString(date: Date) {
        return date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0')
    }

    dateToString(date: Date) {
        let dateStr = date.getFullYear() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0')
            + " " + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0'); //+ "." + date.getMilliseconds().toString().padStart(3, '0')
        return dateStr;
    }

    asDate(dateStr: string) {
        let date = new Date(dateStr);
        return date;
    }

    get minTimedStartZoneDate(): Date {
        let minDate = TimedDataComponent.minDate;
        //let currentdate = this.dateService.getMonthStart(this.dateService.today()); // TODO: quartals ?
        // TODO: newer than existing date
        let date = minDate;
        // = minDate // TODO:  allow past dates for highlevel admins
        if (date < this.externalMinStartDate) {
            date = this.externalMinStartDate;
        }
        if (date < minDate) {
            date = minDate;
        }
        return date;
    }

    constructor(private api: DataService,
        private userStore: UserStore,
        protected dateService: NbDateService<Date>,
        translationService: TranslationService) {
        super(translationService);
    }

    ngOnInit() {
        this.initialized = true;
        this.data = this.data; // run setter code when initialized
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    ngAfterViewInit() {
        //super.ngAfterViewInit();
    }

    startZoneDateFilter(date: Date) {
        if (date.getDate() != 1)
            return false; // 1st of month
        if (this.minTimedStartZoneDate && date < this.minTimedStartZoneDate)
            return false; // min value
        if (this.data?.Timed && this.data.Timed.find(it => it.StartZoneDate == this.dateToDayString(date)))
            return false; // no duplicates
        if (this.data?.Timed && this.data.Timed.find(it => it.StartZoneDate >= this.dateToDayString(date)))
            return false; // not earlier than existing entries
        return true;
    }

    isTimedEntryEditable(): boolean {
        return this.isEditable && this.selectedTimedEntry && !this.selectedTimedEntry.Closed
            && (this.isAlwaysUnlocked || !this.selectedTimedEntryIsLocked);
        // TODO:  allow high level admins to always edit
        // TODO: prevent editing closed entries
        //&& this.selectedTimedEntryStartZoneDate >= this.dateService.getMonthStart(this.dateService.today());
    }

    addTimedEntry() {
        let newEntry: T;
        if (!this.data.Timed || this.data.Timed.length == 0) {
            this.data.Timed = [];
            newEntry = {
                StartZoneDate: this.dateToDayString(this.minTimedStartZoneDate),
            } as any; // missing data is added when selected
        } else {
            newEntry = cloneDeep(this.data.Timed[0]); // first entry should be the newest
            newEntry.StartZoneDate = this.dateToDayString(this.dateService.addDay(this.dateService.getMonthEnd(this.dateService.today()), 1));
        }
        newEntry.Closed = false;
        this.data.Timed.unshift(newEntry);
        this.changeSelectedTimedEntry(0);
    }

    selectedTimedEntryStartZoneDateChanged(date: Date) {
        this.selectedTimedEntry.StartZoneDate = this.dateToDayString(date);
    }

    unlock() {
        this.selectedTimedEntryIsLocked = false;
    }

}
