import { Component, Input, OnInit } from "@angular/core";
import { Product } from "../../../shared-gen/Model/Products/Product";
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";

@Component({
    selector: "ngx-product-selections",
    templateUrl: "./product-selections.component.html",
    styleUrls: ["./product-selections.component.scss"]
})
export class ProductSelectionsComponent extends Localized {

    product: Product;

    constructor(translationService: TranslationService) {
        super(translationService);
    }

    @Input()
    set instance(inst: Product) {
        this.product = inst;
    }

    get instance() {
        return this.product;
    }

    addSelection() {
        /* TODO: now only one SelectionNumber per product
        this.product.Selections.push({
            Number:0,
            Capacity:0
        }); */
    }

    deleteSelection(index: number) {
        /* TODO: now only one SelectionNumber per product
        if(this.product.Selections.length>1) {
            this.product.Selections.splice(index, 1);
        }*/
    }
}
