import { Component, Input } from '@angular/core';
import { VoucherRedemption } from "../../../shared-gen/Model/Vouchers/VoucherRedemption";
import { VoucherRedemptionFun } from "../../../shared/model/vouchers/voucher-redemption.fun";

@Component({
    selector: 'redemptionstate',
    templateUrl: './redemptionstate.component.html',
    styleUrls: ['./redemptionstate.component.scss']
})
export class RedemptionStateComponent {

    color: 'green' | 'yellow' | 'red' | 'gray' = 'green';
    statusText = '';

    @Input()
    set redemption(redemption: VoucherRedemption) {
        this.statusText = VoucherRedemptionFun.getText(redemption);
        if (redemption.State == "Open" || redemption.State == "Released")
            this.color = 'green';
        else if (redemption.State == "Reserved")
            this.color = 'yellow';
        else if (redemption.State == "Redeemed")
            this.color = 'gray';
        else if (redemption.State == "Revoked")
            this.color = 'red';
    }

}
