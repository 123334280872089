<nb-card>
    <nb-card-header>{{"🌐Contact.Pls" | translate:lang }}</nb-card-header>
    <nb-card-body>

        <p><b>{{env.contact.name}}</b><br />
            <a href="mailto:{{env.contact.mail}}">{{env.contact.mail}}</a><br />
            <a href="tel:{{env.contact.phoneClean}}">{{env.contact.phone}}</a>
        </p>
    </nb-card-body>


    <nb-card-footer class="d-flex">
        <button nbButton status="basic" (click)="dialogRef.close()" class="w-100 mr-2">{{"🌐Contact.Close" |
            translate:lang }}</button>
    </nb-card-footer>
</nb-card>
