import { Product } from "../../../shared-gen/Model/Products/Product";
import { getDefaultID } from "../../core";
import { ProductType } from "../../../shared-gen/Model/Products/ProductType";
import { ProductBase } from "shared-gen/Model/Products/ProductBase";

export class ProductFun {

    static createNew(productID?: string): Product {
        productID = productID || getDefaultID();
        return {
            ID: productID,
            ProviderID: "",
            ProductType: ProductType.Article,
            Name: "",
            OptionChoices: null as any,
            Sirup: [],
            Vat: 19,
            Price: 0,
            Deposit: 0,
            SelectionNumber: undefined,
            Resources: [],
            Image: null as any,
            Enabled: true
        };
    }

    static getBaseID(productID: string): string {
        if (productID) {
            if (productID.indexOf('-') > -1) {
                return productID.split('-')[0];
            }
            return productID;
        }
        return '';
    }

    /**
     * Returns the given product ID, where the base product ID
     * is replaced by the given one,
     * e.g. old ID "Cappu-M-Soy" and new base ID "Cappuccino" results
     * in "Cappuccino-M-Soy".
     */
    static replaceBaseProductID(oldID: string, newBaseID: string) {
        const firstMinusPos = oldID.indexOf('-');
        if (firstMinusPos > -1)
            return newBaseID + oldID.substring(firstMinusPos);
        else
            return newBaseID;
    }

    /**
     * Returns true, if the given object is null or a ProductBase instead of a Product.
     * Otherwise false. That means, this method does not check the given object in detail
     * but is just used to differentiate Product from ProductBase (and null).
     */
    static isNotNullOrProductBase(p: Product | ProductBase | null): boolean {
        return !(p == null || (p as ProductBase).Instances);
    }

}
