<div class="row">
    <div class="col-12 col-sm-12 mb-3">
        <h4 class="font-weight-normal mb-0">{{"🌐Reports.CashBalance.ArticleOverview" | translate:lang}}
        </h4>
    </div>
</div>

<!-- Products with the given kinds of VAT -->
<ng-container *ngFor="let products of report.ProductsByVat">
    <article-sales-table [products]="products" [currency]="currency" [showDeposit]="showDeposit"></article-sales-table>
</ng-container>


<!-- Sum of all products (only needed when two or more VAT tables or when no products at all) -->
<ng-container *ngIf="report.ProductsByVat.length > 1">
    <div class="row">
        <div class="col-12 col-sm-12">
            <nb-card class="nb-nested-card">
                <nb-card-header>{{("🌐Reports.CashBalance.TotalItems" |
                    translate:lang).replace("%all-vats%", getAllVats())}}</nb-card-header>
                <nb-card-body>
                    <table class="simple-table">
                        <tr>
                            <th></th>
                            <th [innerHTML]="translate('🌐Reports.CashBalance.CountWithoutTokens')">
                            </th>
                            <th>{{"🌐Reports.CashBalance.CountInCash" | translate:lang}}</th>
                            <th>{{"🌐Reports.CashBalance.ShareInCash" | translate:lang}}</th>
                            <th>{{"🌐Reports.CashBalance.CountContactless" | translate:lang}}</th>
                            <th>{{"🌐Reports.CashBalance.ShareContactless" | translate:lang}}</th>
                            <th>{{"🌐Reports.CashBalance.CountOfVoucher" | translate:lang}}</th>
                            <th>{{"🌐Reports.CashBalance.VoucherValue" | translate:lang}}</th>
                            <ng-container *ngIf="false">
                                <!-- // Removed 2021-12 by request of TuR (#521; CR 0021)
											<th>{{"🌐Reports.CashBalance.CountSmallToken" | translate:lang}}</th>
											<th>{{"🌐Reports.CashBalance.CountMediumToken" | translate:lang}}</th>
											<th>{{"🌐Reports.CashBalance.CountLargeToken" | translate:lang}}</th>
											<th>{{"🌐Reports.CashBalance.ShareValueToken" | translate:lang}}</th>
											-->
                            </ng-container>
                            <th *ngIf="showDeposit">{{"🌐Reports.CashBalance.TotalDeposit" |
                                translate:lang}}</th>
                            <th [innerHTML]="translate('🌐Reports.CashBalance.SalesIncludingVAT' + (showDeposit ? 'IncludingDeposit' : ''))">
                            </th>
                        </tr>
                        <tr>
                            <th>{{("🌐Reports.CashBalance.TotalItems" |
                                translate:lang).replace("%all-vats%", getAllVats())}}</th>
                            <th>{{ report.ProductsTotal.CountWithoutTokens }}</th>
                            <th>{{ report.ProductsTotal.HardCashCount }}</th>
                            <th>{{ report.ProductsTotal.HardCash / 100 | iocurrency:currency }}</th>
                            <th>{{ report.ProductsTotal.CashlessCount }}</th>
                            <th>{{ report.ProductsTotal.Cashless / 100 | iocurrency:currency }}</th>
                            <th>{{ report.ProductsTotal.CustomVoucherCount }}</th>
                            <th>{{ report.ProductsTotal.CustomVoucherTotal / 100 | iocurrency:currency
                                }}</th>
                            <ng-container *ngIf="false">
                                <!-- // Removed 2021-12 by request of TuR (#521; CR 0021)
											<th>{{ report.ProductsTotal.TokensSmallCount }}</th>
											<th>{{ report.ProductsTotal.TokensMediumCount }}</th>
											<th>{{ report.ProductsTotal.TokensLargeCount }}</th>
											<th>{{ report.ProductsTotal.TokensTotal / 100 | iocurrency:currency }}</th>
											-->
                            </ng-container>
                            <th *ngIf="showDeposit">{{ report.ProductsTotal.TotalDeposit / 100 |
                                iocurrency:currency }}</th>
                            <th>{{ report.ProductsTotal.Sales / 100 | iocurrency:currency }}</th>
                        </tr>
                    </table>
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</ng-container>

<ng-container *ngIf="report.ProductsByVat.length == 0">
    <div class="row">
        <div class="col-12 col-sm-12">
            <nb-card class="nb-nested-card">
                <nb-card-header>{{"🌐Reports.CashBalance.NoSales" | translate:lang}}</nb-card-header>
                <nb-card-body>
                    {{"🌐Reports.CashBalance.NoSalesWereMadeDuringThisPeriod" | translate:lang}}
                </nb-card-body>
            </nb-card>
        </div>
    </div>
</ng-container>