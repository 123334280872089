import { Dependents } from "../../../shared-gen/Model/Utils/Dependents";

export class DependentsFun {

    /**
     * Returns a new instance with no items.
     */
    static none(): Dependents {
        return {
            Entities: {} as any
        }
    }

    /**
     * Returns true, iff this dependents contains at least one item.
     */
    static hasAny(_this: Dependents, id: string): boolean {
        return Object.entries(_this.Entities).some(it => (it[1][id]?.length || 0) > 0);
    }

}
