/** Supported currencies. */
export enum Currency {
    /** When unknown or variable (like in the backend), this general currency is used. */
    Unit = "Unit",
    /** Australian dollar. */
    AUD = "AUD",
    /** Swiss franc. */
    CHF = "CHF",
    /** Danish krone. */
    DKK = "DKK",
    /** Euro. */
    EUR = "EUR",
    /** Pound sterling. */
    GBP = "GBP",
    /** Singapore dollar. */
    SGD = "SGD",
    /** United States dollar. */
    USD = "USD",
    /** Norwegian krone. */
    NOK = "NOK",
    /** Polish Zloty. */
    PLN = "PLN"
}
