import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { Entity } from "../../../shared-gen/Model/Utils/Entity";
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";
import { IRestrictedToLocations } from "../../../shared-gen/Model/Locations/IRestrictedToLocations";
import { GuiService } from "../../services/gui.service";
import { UsersService } from "../../@core/backend/common/services/users.service";
import { UserStore } from "../../@core/stores/user.store";

/**
 * Label which is shows HTML content when the given Entity is a IRestrictedToLocations entity
 * whose locations are restricted, or when not. See [style].
 */
@Component({
    selector: "iovent-local-configuration-info",
    templateUrl: "./iovent-local-configuration-info.component.html",
    styleUrls: ["./iovent-local-configuration-info.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class IoventLocalConfigurationInfoComponent extends Localized implements OnDestroy, OnChanges {

    @Input() entity: Entity = null;
    @Input() style: 'tablecell' | 'locallabel' | null = null; // See HTML template for formatting
    @Input() isEditable = false;

    isLocal = false;
    locIDs: string[] = [];

    constructor(private guiService: GuiService,
        private userStore: UserStore,
        translationService: TranslationService) {
        super(translationService);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    ngOnChanges(changes: SimpleChanges) {
        const locEntity = this.entity as IRestrictedToLocations;
        const locIDs = locEntity?.RestrictedToLocationIDs;
        this.isLocal = locIDs != null;
        this.locIDs = locIDs ?? [];
    }

    showLocations() {
        const lines = this.locIDs.map(key => "<li>" + key + "</li>").join("");
        const saveGlobalMessage = this.isEditable && this.userStore.getIoventUser().RestrictedToLocationIDs == null
            ? this.translate("🌐Portal.RestrictedEntitySaveGlobal") : "";
        this.guiService.messageDialog(this.translate("🌐General.Locations"),
            `<ul>${lines}</ul>${saveGlobalMessage}`, _ => { });
    }
}

