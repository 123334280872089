<nb-card *ngIf="hasRemoteControlRight" class="nb-nested-card">
    <nb-card-header class="d-flex flex-column flex-wrap">
        <span class="px-3 pb-2">{{"🌐General.RemoteControl" | translate:lang}}</span>
        <div class="d-flex justify-content-center flex-wrap">
            <div *ngIf="hasRestartRight" class="d-flex flex-column">
                <button class="m-2 DeviceRemoteControlComponent-width-auto" nbButton size="small" status="info"
                        (click)="restart('Device')" [disabled]="!isDeviceOnline">{{"🌐General.RestartDevice" | translate:lang}}</button>
                <button *ngIf="hasRestartRight" class="m-2 DeviceRemoteControlComponent-width-auto" nbButton size="small" status="info" (click)="restart('OS')"
                        [disabled]="!isDeviceOnline">{{"🌐General.RestartOS" | translate:lang}}</button>
            </div>
            <div class="d-flex flex-column">
                <button class="m-2 DeviceRemoteControlComponent-width-auto" nbButton size="small" status="info" (click)="openMaintenanceScreen()" [disabled]="!isDeviceOnline">
                    {{"🌐General.OpenMaintenanceScreen" | translate:lang}}
                </button>
                <button class="m-2 DeviceRemoteControlComponent-width-auto" nbButton size="small" status="info" (click)="applyDataUpdate()" [disabled]="!isDeviceOnline">
                    {{"🌐ConfigurationPanel.Update" | translate:lang}}
                </button>
            </div>
        </div>
    </nb-card-header>
</nb-card>
