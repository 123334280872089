import { ProductBase } from "shared-gen/Model/Products/ProductBase";
import { ProductType } from "shared-gen/Model/Products/ProductType";
import { Resource } from "shared-gen/Model/Products/Resource";

export interface EversysProductGeneratorProduct {
    ID: string;
    Name: string;
    Image: string;
    SelectionNumber: number;
    ServingTime: number;
    ServingTimeIncrement: number;
    Price: number;
    PriceIncrement: number;
}

// exported from mue (Müller Egererer) directly via service response of /products on 2024-07-10
export const supportedProducts: ProductBase[] = [
    {
      "ID": "Americano",
      "Name": "Americano",
      "LastChangeDate": "2024-02-28T15:36:20Z",
      "Hash": "3043864ad04a8c0dabfe6566e171ada2be02ba03515c5bdfefe34143ece7e0fe",
      "ProductType": ProductType.Eversys,
      "Image": "f18dd0a875cdff1985a9713dd3181dee64a5e84d41dc0f684d97b9e5986689f9.png",
      "Type": "","Details": "",
      "LocalizedNames": {
        "DE": "Americano"
      },
      "Options": [
        {
          "ID": "Size",
          "Name": "Größe",
          "Choices": [
            {
              "ID": "S",
              "Name": "S"
            },
            {
              "ID": "M",
              "Name": "M"
            }
          ]
        }
      ],
      "Instances": [
        {
          "ProductType": ProductType.Eversys,
          "ID": "Americano-S",
          "Name": "Americano S",
          "LocalizedNames": {
            "DE": "Americano S"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 3,
          "ServingTime": 50,
          "Resources": [
            Resource.BeansRight
          ],
          "CustomID": "19820",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Americano-M",
          "Name": "Americano M",
          "LocalizedNames": {
            "DE": "Americano M"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 2,
          "ServingTime": 62,
          "Resources": [
            Resource.BeansRight
          ],
          "CustomID": "19821",
          "Sirup": []
        }
      ],
      "ProviderID": "mue"
    },
    {
      "ID": "Cappuccino",
      "Name": "Cappuccino",
      "LastChangeDate": "2024-05-14T20:11:50Z",
      "Hash": "454a26f6b082807dcc6b43debeeef21a566c90851cc7a66863943a751e321c2e",
      "ProductType": ProductType.Eversys,
      "Image": "0630f27fbbfb101f7d44badab4c83df98471ab239cd9ab527fdae9a4b4799822.png",
      "Type": "","Details": "",
      "LocalizedNames": {
        "DE": "Cappuccino"
      },
      "Options": [
        {
          "ID": "Size",
          "Name": "Größe",
          "Choices": [
            {
              "ID": "S",
              "Name": "S"
            },
            {
              "ID": "M",
              "Name": "M"
            },
            {
              "ID": "L",
              "Name": "L"
            }
          ]
        },
        {
          "ID": "Milk",
          "Name": "Milch",
          "Choices": [
            {
              "ID": "D",
              "Name": "Kuhmilch"
            },
            {
              "ID": "ND",
              "Name": "Hafer"
            }
          ]
        }
      ],
      "Instances": [
        {
          "ProductType": ProductType.Eversys,
          "ID": "Cappuccino-S-D",
          "Name": "Cappuccino S",
          "LocalizedNames": {
            "DE": "Cappuccino S"
          },
          "Vat": 7,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 15,
          "ServingTime": 34,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk
          ],
          "CustomID": "19970",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Cappuccino-M-D",
          "Name": "Cappuccino M",
          "LocalizedNames": {
            "DE": "Cappuccino M"
          },
          "Vat": 7,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 14,
          "ServingTime": 35,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk
          ],
          "CustomID": "19971",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Cappuccino-L-D",
          "Name": "Cappuccino L",
          "LocalizedNames": {
            "DE": "Cappuccino L"
          },
          "Vat": 7,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 13,
          "ServingTime": 64,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk
          ],
          "CustomID": "19980",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Cappuccino-S-ND",
          "Name": "Cappuccino S Hafer",
          "LocalizedNames": {
            "DE": "Cappuccino S Hafer"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 31,
          "ServingTime": 34,
          "Resources": [
            Resource.Milk2,
            Resource.BeansRight
          ],
          "CustomID": "19970",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Cappuccino-M-ND",
          "Name": "Cappuccino M Hafer",
          "LocalizedNames": {
            "DE": "Cappuccino M Hafer"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 30,
          "ServingTime": 35,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk2
          ],
          "CustomID": "19971",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Cappuccino-L-ND",
          "Name": "Cappuccino L Hafer",
          "LocalizedNames": {
            "DE": "Cappuccino L Hafer"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 29,
          "ServingTime": 64,
          "Resources": [
            Resource.Milk2,
            Resource.BeansRight
          ],
          "CustomID": "19980",
          "Sirup": []
        }
      ],
      "ProviderID": "mue"
    },
    {
      "ID": "Chococino",
      "Name": "Chococino",
      "LastChangeDate": "2024-02-28T15:34:14Z",
      "Hash": "3b767164618e63b495a0cd6756c89d1c74691e8dc913b6a4728be986f8f3e114",
      "ProductType": ProductType.Eversys,
      "Image": "aa29c62de87610bd57065257061b82ac79c9cda2be348b8d50ba66d4e7a59f36.png",
      "Type": "","Details": "",
      "LocalizedNames": {
        "DE": "Chococino"
      },
      "Options": [
        {
          "ID": "Size",
          "Name": "Größe",
          "Choices": [
            {
              "ID": "M",
              "Name": "M"
            },
            {
              "ID": "L",
              "Name": "L"
            }
          ]
        }
      ],
      "Instances": [
        {
          "ProductType": ProductType.Eversys,
          "ID": "Chococino-M",
          "Name": "Chococino M",
          "LocalizedNames": {
            "DE": "Chococino M"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 41,
          "ServingTime": 35,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk,
            Resource.Chocolate
          ],
          "CustomID": "19890",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Chococino-L",
          "Name": "Chococino L",
          "LocalizedNames": {
            "DE": "Chococino L"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 42,
          "ServingTime": 64,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk,
            Resource.Chocolate
          ],
          "CustomID": "19891",
          "Sirup": []
        }
      ],
      "ProviderID": "mue"
    },
    {
      "ID": "Espresso",
      "Name": "Espresso",
      "LastChangeDate": "2024-02-28T15:37:13Z",
      "Hash": "6f1ccacbb3f4f9d8c0a4572b73d2fdac991493bcbcd833e1c81c3f6677272455",
      "ProductType": ProductType.Eversys,
      "Image": "2a3eda60c5b05781cc63022c0b0c64bf9e8ae9d952fbdb3d238c06d76b651b21.png",
      "Type": "","Details": "",
      "LocalizedNames": {
        "DE": "Espresso"
      },
      "Options": [
        {
          "ID": "Size",
          "Name": "Größe",
          "Choices": [
            {
              "ID": "E",
              "Name": "E"
            },
            {
              "ID": "D",
              "Name": "Doppio"
            }
          ]
        }
      ],
      "Instances": [
        {
          "ProductType": ProductType.Eversys,
          "ID": "Espresso-E",
          "Name": "Espresso E",
          "LocalizedNames": {
            "DE": "Espresso E"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 6,
          "ServingTime": 33,
          "Resources": [
            Resource.BeansRight
          ],
          "CustomID": "19950",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Espresso-D",
          "Name": "Espresso Doppio",
          "LocalizedNames": {
            "DE": "Espresso Doppio"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 5,
          "ServingTime": 38,
          "Resources": [
            Resource.BeansRight
          ],
          "CustomID": "19951",
          "Sirup": []
        }
      ],
      "ProviderID": "mue"
    },
    {
      "ID": "EspressoMacchiato",
      "Name": "Espresso Macchiato",
      "LastChangeDate": "2024-02-28T15:38:32Z",
      "Hash": "532aa87d82cb68f7d1d83121f7056ad531e9cf8652b981693a13a0d787849e63",
      "ProductType": ProductType.Eversys,
      "Image": "11477406137d87642b795060c4087ea460616e43562919826f58adf30c4ab808.png",
      "Type": "","Details": "",
      "LocalizedNames": {
        "DE": "Espresso Macchiato"
      },
      "Options": [
        {
          "ID": "Size",
          "Name": "Größe",
          "Choices": [
            {
              "ID": "E",
              "Name": "E"
            },
            {
              "ID": "D",
              "Name": "Doppio"
            }
          ]
        }
      ],
      "Instances": [
        {
          "ProductType": ProductType.Eversys,
          "ID": "EspressoMacchiato-E",
          "Name": "Espresso Macchiato E",
          "LocalizedNames": {
            "DE": "Espresso Macchiato E"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 18,
          "ServingTime": 40,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk
          ],
          "CustomID": "19952",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "EspressoMacchiato-D",
          "Name": "Espresso Macchiato Doppio",
          "LocalizedNames": {
            "DE": "Espresso Macchiato Doppio"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 17,
          "ServingTime": 45,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk
          ],
          "CustomID": "19953",
          "Sirup": []
        }
      ],
      "ProviderID": "mue"
    },
    {
      "ID": "Kaffee",
      "Name": "Kaffee",
      "LastChangeDate": "2024-02-28T15:39:39Z",
      "Hash": "0f9b12bcb96287ffa440d332216a2d6cb4b11e353b22181bfaacf5fbc09531ee",
      "ProductType": ProductType.Eversys,
      "Image": "3ca992a8e6487493c172a3e4de82abdc85af7836bef43a94dce18cbcaf348ef1.png",
      "Type": "","Details": "",
      "LocalizedNames": {
        "DE": "Kaffee"
      },
      "Options": [
        {
          "ID": "Size",
          "Name": "Größe",
          "Choices": [
            {
              "ID": "S",
              "Name": "S"
            },
            {
              "ID": "M",
              "Name": "M"
            },
            {
              "ID": "L",
              "Name": "L"
            }
          ]
        }
      ],
      "Instances": [
        {
          "ProductType": ProductType.Eversys,
          "ID": "Kaffee-S",
          "Name": "Kaffee S",
          "LocalizedNames": {
            "DE": "Kaffee S"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 12,
          "ServingTime": 40,
          "Resources": [
            Resource.BeansLeft
          ],
          "CustomID": "19810",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Kaffee-M",
          "Name": "Kaffee M",
          "LocalizedNames": {
            "DE": "Kaffee M"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 11,
          "ServingTime": 43,
          "Resources": [
            Resource.BeansLeft
          ],
          "CustomID": "19811",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Kaffee-L",
          "Name": "Kaffee L",
          "LocalizedNames": {
            "DE": "Kaffee L"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 10,
          "ServingTime": 63,
          "Resources": [
            Resource.BeansLeft
          ],
          "CustomID": "19812",
          "Sirup": []
        }
      ],
      "ProviderID": "mue"
    },
    {
      "ID": "KaffeemitMilch",
      "Name": "Kaffee mit Milch",
      "LastChangeDate": "2024-02-29T14:05:13Z",
      "Hash": "4527ffa79fbdec56676d3d9347cb51dc83ed2c0a2e01faf2356518c3b29d6b78",
      "ProductType": ProductType.Eversys,
      "Image": "86a5886df180559271abaa8a651fea8c3bdd39a08a3d4f108fbd826c0de23923.png",
      "Type": "","Details": "",
      "LocalizedNames": {
        "DE": "Kaffee mit Milch"
      },
      "Options": [
        {
          "ID": "Size",
          "Name": "Größe",
          "Choices": [
            {
              "ID": "S",
              "Name": "S"
            },
            {
              "ID": "M",
              "Name": "M"
            },
            {
              "ID": "L",
              "Name": "L"
            }
          ]
        }
      ],
      "Instances": [
        {
          "ProductType": ProductType.Eversys,
          "ID": "KaffeemitMilch-S",
          "Name": "Kaffee mit Milch S",
          "LocalizedNames": {
            "DE": "Kaffee mit Milch S"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 45,
          "ServingTime": 35,
          "Resources": [
            Resource.BeansLeft,
            Resource.Milk
          ],
          "CustomID": "19815",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "KaffeemitMilch-M",
          "Name": "Kaffee mit Milch M",
          "LocalizedNames": {
            "DE": "Kaffee mit Milch M"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 44,
          "ServingTime": 38,
          "Resources": [
            Resource.BeansLeft,
            Resource.Milk
          ],
          "CustomID": "19816",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "KaffeemitMilch-L",
          "Name": "Kaffee mit Milch L",
          "LocalizedNames": {
            "DE": "Kaffee mit Milch L"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 43,
          "ServingTime": 41,
          "Resources": [
            Resource.BeansLeft,
            Resource.Milk
          ],
          "CustomID": "19817",
          "Sirup": []
        }
      ],
      "ProviderID": "mue"
    },
    {
      "ID": "Kakao",
      "Name": "Kakao",
      "LastChangeDate": "2024-05-17T06:59:37Z",
      "Hash": "0e63e4649a8fa5c1377a42202f2b6a7a88c626a47b84b7bae6fe26f4f7d26217",
      "ProductType": ProductType.Eversys,
      "Image": "aa29c62de87610bd57065257061b82ac79c9cda2be348b8d50ba66d4e7a59f36.png",
      "Type": "","Details": "",
      "LocalizedNames": {
        "DE": "Kakao"
      },
      "Options": [
        {
          "ID": "Size",
          "Name": "Größe",
          "Choices": [
            {
              "ID": "M",
              "Name": "M"
            },
            {
              "ID": "L",
              "Name": "L"
            }
          ]
        },
        {
          "ID": "Milk",
          "Name": "Milch",
          "Choices": [
            {
              "ID": "D",
              "Name": "Kuhmilch"
            },
            {
              "ID": "ND",
              "Name": "Hafer"
            }
          ]
        }
      ],
      "Instances": [
        {
          "ProductType": ProductType.Eversys,
          "ID": "Kakao-M-D",
          "Name": "Kakao M",
          "LocalizedNames": {
            "DE": "Kakao M"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 19,
          "ServingTime": 27,
          "Resources": [
            Resource.Milk,
            Resource.Chocolate
          ],
          "CustomID": "19880",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Kakao-L-D",
          "Name": "Kakao L",
          "LocalizedNames": {
            "DE": "Kakao L"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 20,
          "ServingTime": 33,
          "Resources": [
            Resource.Milk,
            Resource.Chocolate
          ],
          "CustomID": "19881",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Kakao-M-ND",
          "Name": "Kakao M Hafer",
          "LocalizedNames": {
            "DE": "Kakao M Hafer"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 36,
          "ServingTime": 27,
          "Resources": [
            Resource.Chocolate,
            Resource.Milk2
          ],
          "CustomID": "19880",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Kakao-L-ND",
          "Name": "Kakao L Hafer",
          "LocalizedNames": {
            "DE": "Kakao L Hafer"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 32,
          "ServingTime": 33,
          "Resources": [
            Resource.Milk2,
            Resource.Chocolate
          ],
          "CustomID": "19881",
          "Sirup": []
        }
      ],
      "ProviderID": "mue"
    },
    {
      "ID": "LatteMacchiato",
      "Name": "Latte Macchiato",
      "LastChangeDate": "2024-05-14T20:14:21Z",
      "Hash": "a19d5a4834c3d8c152b4fc37b51a354f95a0b0671138e17490b9ac8e2024e430",
      "ProductType": ProductType.Eversys,
      "Image": "c2d3b67ab635719d42b27ace2fda49f0fe328008c1298f6f9c61b73889807c98.png",
      "Type": "","Details": "",
      "LocalizedNames": {
        "DE": "Latte Macchiato"
      },
      "Options": [
        {
          "ID": "Size",
          "Name": "Größe",
          "Choices": [
            {
              "ID": "M",
              "Name": "M"
            },
            {
              "ID": "L",
              "Name": "L"
            }
          ]
        },
        {
          "ID": "Milk",
          "Name": "Milch",
          "Choices": [
            {
              "ID": "D",
              "Name": "Kuhmilch"
            },
            {
              "ID": "ND",
              "Name": "Hafer"
            }
          ]
        }
      ],
      "Instances": [
        {
          "ProductType": ProductType.Eversys,
          "ID": "LatteMacchiato-M-D",
          "Name": "Latte Macchiato M",
          "LocalizedNames": {
            "DE": "Latte Macchiato M"
          },
          "Vat": 7,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 23,
          "ServingTime": 43,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk
          ],
          "CustomID": "19920",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "LatteMacchiato-L-D",
          "Name": "Latte Macchiato L",
          "LocalizedNames": {
            "DE": "Latte Macchiato L"
          },
          "Vat": 7,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 22,
          "ServingTime": 51,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk
          ],
          "CustomID": "19921",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "LatteMacchiato-M-ND",
          "Name": "Latte Macchiato M Hafer",
          "LocalizedNames": {
            "DE": "Latte Macchiato M Hafer"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 34,
          "ServingTime": 43,
          "Resources": [
            Resource.BeansRight,
            Resource.Milk2
          ],
          "CustomID": "19920",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "LatteMacchiato-L-ND",
          "Name": "Latte Macchiato L Hafer",
          "LocalizedNames": {
            "DE": "Latte Macchiato L Hafer"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 33,
          "ServingTime": 51,
          "Resources": [
            Resource.Milk2,
            Resource.BeansRight
          ],
          "CustomID": "19921",
          "Sirup": []
        }
      ],
      "ProviderID": "mue"
    },
    {
      "ID": "Milchkaffee",
      "Name": "Milchkaffee",
      "LastChangeDate": "2024-05-14T20:16:17Z",
      "Hash": "88e8bb1d204cdc179a5c11c787779a146a4d229cdd263f88259053cbe1245e51",
      "ProductType": ProductType.Eversys,
      "Image": "a4be0bac2da64fd1343ca8c695d78ee12d530255224d1e7352ee15b63338f3cb.png",
      "Type": "","Details": "",
      "LocalizedNames": {
        "DE": "Milchkaffee"
      },
      "Options": [
        {
          "ID": "Size",
          "Name": "Größe",
          "Choices": [
            {
              "ID": "M",
              "Name": "M"
            }
          ]
        },
        {
          "ID": "Milk",
          "Name": "Milch",
          "Choices": [
            {
              "ID": "D",
              "Name": "Kuhmilch"
            },
            {
              "ID": "ND",
              "Name": "Hafer"
            }
          ]
        }
      ],
      "Instances": [
        {
          "ProductType": ProductType.Eversys,
          "ID": "Milchkaffee-M-D",
          "Name": "Milchkaffee M",
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 26,
          "ServingTime": 38,
          "Resources": [
            Resource.BeansLeft,
            Resource.Milk
          ],
          "CustomID": "19910",
          "Sirup": []
        },
        {
          "ProductType": ProductType.Eversys,
          "ID": "Milchkaffee-M-ND",
          "Name": "Milchkaffee M Hafer",
          "LocalizedNames": {
            "DE": "Milchkaffee M Hafer"
          },
          "Vat": 19,
          "Price": 0,
          "Deposit": 0,
          "SelectionNumber": 35,
          "ServingTime": 38,
          "Resources": [
            Resource.Milk2,
            Resource.BeansLeft
          ],
          "Sirup": []
        }
      ],
      "ProviderID": "mue"
    }
  ];
