import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";
import { Location } from 'shared-gen/Model/Locations/Location';
import { Product } from "shared-gen/Model/Products/Product";
import { TableHelper } from "app/@helper/TableHelper";
import { hasRights } from "app/auth/iovent/rights";
import { DataService } from "app/services/data.service";
import { StorageService } from "app/services/storage.service";
import { Access } from "shared-gen/Model/Auth/Access";
import { Feature } from "shared-gen/Model/Auth/Feature";
import { Device } from "shared-gen/Model/Devices/Device";
import { XDeviceInfo } from "shared-gen/Model/Devices/XDeviceInfo";
import { ActivatedRoute } from "@angular/router";
import { UserStore } from "app/@core/stores/user.store";
import { AnyPtrRecord } from "dns";

@Component({
    selector: "ngx-eversys-device-import-dialog",
    templateUrl: "./eversys-device-import-dialog.component.html",
    styleUrls: ["./eversys-device-import-dialog.component.scss"]
})
export class EversysDeviceImportDialogComponent extends TableHelper<XDeviceInfo> implements OnInit {

    devices: XDeviceInfo[] = null;

    useServiceLocation: boolean = false;
    useGroupLocation: boolean = false;
    useCustomerLocation: boolean = true;
    idPrefix: string = "";

    initialized = false;
    importing = 0;

    constructor(public dialogRef: MatDialogRef<EversysDeviceImportDialogComponent>,
        translationService: TranslationService,
        cdRef: ChangeDetectorRef,
        protected route: ActivatedRoute,
        protected dataService: DataService,
        protected userStore: UserStore,
        protected storageService: StorageService) {
        super(userStore, dataService, storageService, route, cdRef, translationService);
    }

    async ngOnInit() {
        this.sortBy = "ServiceCompany";
        this.PageSize = 9999;
        super.ngOnInit();
    }

    async core_load(page = 0) {
        this.result = await this.dataService.getPagedList<XDeviceInfo>(
            '/x/devices/eversys/info',
            this.getListOptions(page),
            {},
            "unfiltered");
        this.devices = this.result.Items;
        this.initialized = true;
        console.log("devices: " + this.devices.length);
    }

    getColumns(): string[] {
        return []; // all...
    }

    toggleSelect(device: XDeviceInfo) {
        (device as any).Selected = !this.isSelected(device);
    }

    isSelected(device: XDeviceInfo): boolean {
        return (device as any).Selected === true;
    }

    selectAll(select: boolean) {
        this.devices.forEach(it => (it as any).selected = true);
    }

    getSelected(): string[] {
        let selected: string[] = [];
        this.devices.forEach(it => { if (this.isSelected(it)) selected.push(it.XID) });
        return selected;
    }

    async confirm() {
        this.importing = 0.01;
        let selected = this.getSelected();
        for (let xID of selected) {
            let result = await this.dataService.postbase("x/devices/import/eversys/" + xID as any,
                { UseServiceLocation: this.useServiceLocation, UseGroupLocation: this.useGroupLocation, UseCustomerLocation: this.useCustomerLocation, IDPrefix: this.idPrefix });
            if (result) console.log(result);
        }
        this.dialogRef.close({ selected: selected });
    }
}
