import { Choice } from "shared-gen/Model/Products/Choice";
import { Option } from "../../../shared-gen/Model/Products/Option";
import { getDefaultID } from "../../core";
import { OptionChoice } from "shared-gen/Model/Products/OptionChoice";

export class OptionFun {

    static createNew(): Option {
        return {
            ID: getDefaultID(),
            Name: '',
            Choices: []
        };
    }

    static createOptionChoice(option: Option, choice: Choice, optionIndex?: number): OptionChoice {
        return {
            OptionID: option.ID,
            OptionName: option.Name,
            ChoiceID: choice?.ID ?? '',
            ChoiceName: choice?.Name ?? '',
            OptionIndex: optionIndex
        }
    }



}
