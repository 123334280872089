import { LanguageCode } from "../../shared-gen/Model/Localization/LanguageCode";
import { environmentDefaults, IEnvironment } from "./environment-defaults";

/**
 * Development environment for the "iovent demo" project (demo.portal.iovent.net).
 */
export const environment: IEnvironment = {
    ...environmentDefaults,

    // Project settings
    projectName: "demo",
    defaultLanguage: LanguageCode.DE,
    priceMinStepCents: 10,

    // Test settings
    testWarning: "Demo",
};

