<div class="text-center d-flex">

    <ng-container *ngFor="let coin of coinTypes">
        <div class="coin-single-outer-wrapper">
            <div class="coin-single-wrapper">
                <nb-icon icon="coin" [class]="'coin coin--'+coinStatus[coin]" pack="custom-icons"></nb-icon>
                <span class="coin-single-count">{{coinCount[coin]}}</span>
            </div>
            <span class="coin-value">{{coinNames[coin]}}</span>
        </div>
    </ng-container>

</div>
