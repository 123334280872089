<json-editor #jsoneditor [style.min-height]="height" style="height: 800px;" [options]="editorOptions"
    (change)="onEditorChanged($event)"></json-editor>
<!-- [data]="editorData" -->
<!-- ngIf: Wait for initialization -->
<!--*ngIf="editorOptions"-->
<div *ngIf="!disabled && !noButtons">
    <button nbButton (click)="save()" [disabled]="!changed" class="mt-2">{{"🌐General.Apply" | translate:lang}}</button>
    <button nbButton (click)="discard()" [disabled]="!changed" class="mt-2">{{"🌐General.Discard" |
        translate:lang}}</button>
</div>
