import { Component, OnInit, Input } from "@angular/core";
import { Localized } from "../../../shared/localized";
import { TranslationService } from "../../../shared/services/translation.service";
import { DataService } from "../../services/data.service";
import { hasRights } from "app/auth/iovent/rights";
import { Access } from "shared-gen/Model/Auth/Access";
import { Feature } from "shared-gen/Model/Auth/Feature";
import { RestartComponent } from "shared-gen/Model/Devices/Config/RestartComponent";
import { UserStore } from "app/@core/stores/user.store";
import { ConfirmDialogComponent } from "../@dialogs/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { EntityType } from "../../../shared-gen/Model/Utils/EntityType";
import { Device } from "../../../shared-gen/Model/Devices/Device";
import { GuiService } from "../../services/gui.service";
import { DeviceFun } from "../../../shared/model/devices/device.fun";

@Component({
    selector: "device-remotecontrol",
    templateUrl: "./device-remotecontrol.component.html",
    styleUrls: ["./device-remotecontrol.component.scss"]
})
export class DeviceRemoteControlComponent extends Localized implements OnInit {

    @Input() device: Device = null;
    @Input() isDeviceOnline: boolean = false;

    hasRemoteControlRight = false;
    hasRestartRight = false;

    constructor(private api: DataService,
                private userStore: UserStore,
                translationService: TranslationService,
                private dialog: MatDialog,
                private guiService: GuiService) {
        super(translationService);
    }

    ngOnInit() {
        let user = this.userStore.getUser();
        this.hasRemoteControlRight = hasRights(Feature.DeviceRemoteControl, user, Access.Write);
        this.hasRestartRight = hasRights(Feature.DeviceRestart, user, Access.Write)
    }

    async restart(component: RestartComponent) {
        if (!this.device?.ID) {
            console.error('Cannot restart device: device id is not set!');
            return;
        }
        await this.api.restartDevice(this.device.ID, component);
    }

    async openMaintenanceScreen() {
        if (!this.device?.ID) {
            console.error('Cannot open Maintenance screen: device id is not set!');
            return;
        }
        await this.api.openMaintenanceScreen(this.device.ID);
    }

    async applyDataUpdate() {
        // Normally this component is only rendered with a set deviceID.
        if (!this.device?.ID) {
            console.error('Cannot apply configuration: device id is not set!');
            return;
        }

        // Ask the user before doing anything to prevent wrong clicks.
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: this.translate("🌐ConfigurationPanel.Update"),
                confirmMessage: this.translate("🌐Questions.ConfirmDataUpdateMessage")
            },
            disableClose: false
        });

        // Handle the closing dialog.
        dialogRef.afterClosed().subscribe(async (isConfirmed: boolean) => {
            dialogRef = null;
            // User canceled.
            if (!isConfirmed) {
                return;
            }
            const errorToast = (message: string, title: string) => this.guiService.showToast(message, title, "danger", 5000);
            // Check if the device can be saved.
            if (!DeviceFun.canBeSaved(this.device)) {
                errorToast(this.translate("🌐Messages.ErrorWhileSaving"), this.translate("🌐Save.Title"));
                return;
            }
            // Device has no dependents so propagation is not required. Try to save the current device.
            try {
                await this.api.save(this.device, EntityType.Device, false);
            } catch (error) {
                console.error(error);
                errorToast(error.message, this.translate("🌐Messages.ErrorWhileSaving"));
                return;
            }
            // Trigger device data update.
            try {
                await this.api.applyDataUpdate(this.device.ID);
            } catch (error) {
                console.error(error);
                const message = error.status == 503 ? this.translate("General.Offline") : error.message;
                errorToast(message, this.translate("🌐Messages.Error.ApplyDataUpdate"));
                return;
            }
            // Inform the user that the progress should be monitored via the device streaming.
            this.guiService.showToast(this.translate("🌐Messages.MonitorDataUpdate"), this.translate("🌐ConfigurationPanel.Update"), "warning", 5000);
        });
    }

}
