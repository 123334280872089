import { ChangeDetectorRef, Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";
import { ICondition } from "shared-gen/Model/Prices/Conditions/ICondition";
import { NextOrderCondition } from "shared-gen/Model/Prices/Conditions/NextOrderCondition";
import { InitiatorCondition } from "shared-gen/Model/Prices/Conditions/InitiatorCondition"
import { DataService } from "app/services/data.service";
import { GuiService } from "app/services/gui.service";
import fileSaver from "file-saver";
import { cloneDeep } from "shared/utils/core-utils";

@Component({
    selector: "iovent-pricelist-condition-dialog",
    templateUrl: "./pricelist-condition-dialog.html",
    styleUrls: ["./pricelist-condition-dialog.scss"]
})
export class PricelistConditionDialogComponent extends Localized {

    title: string;
    pricelistID: string;
    condition: ICondition;

    nextOrderConditionQrCode: string;
    initiatorConditionQQrCodes: string[];

    callbackSaveCondition: (condition: ICondition) => Promise<void>

    conditionTypes = [
        'NextOrderCondition',
        'InitiatorCondition',
    ];

    constructor(public dialogRef: MatDialogRef<PricelistConditionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            title: string,
            pricelistID: string,
            condition?: ICondition,
            saveCondition: (condition: ICondition) => Promise<void>
        },
        translationService: TranslationService,
        private dataService: DataService,
        private guiService: GuiService,
        protected cd: ChangeDetectorRef) {
        super(translationService);
        this.title = data.title;
        this.pricelistID = data.pricelistID;
        this.condition = data.condition ? cloneDeep(data.condition) : this.createCondition('NextOrderCondition');
        this.callbackSaveCondition = data.saveCondition;
        this.calculateAdditionalConditionData();
    }

    async changeConditionType(conditionType: string) {
        console.log('evebt ', conditionType);

        if (this.condition.Type === conditionType) {
            return;
        }
        this.condition = this.createCondition(conditionType);
        await this.calculateAdditionalConditionData();
    }

    private createCondition(conditionType: string) {
        switch (conditionType) {
            case 'NextOrderCondition':
                const nextOrderCondition: NextOrderCondition = {
                    Type: 'NextOrderCondition'
                };
                return nextOrderCondition;
            case 'InitiatorCondition': 
                const initiatorCondition: InitiatorCondition = {
                    Type: 'InitiatorCondition',
                    AllowedInitiators: []
                }
                return initiatorCondition;
        }
        return undefined;
    }

    private async calculateAdditionalConditionData() {
        switch (this.condition.Type) {
            case 'NextOrderCondition':
                this.nextOrderConditionQrCode = await this.dataService.getPriceListQRCode(this.pricelistID);
                this.refresh();
                return;
            case 'InitiatorCondition': 
                const initiatorCondition = this.condition as InitiatorCondition;
                this.initiatorConditionQQrCodes = await Promise.all(
                    initiatorCondition.AllowedInitiators.map(allowedInitiator => this.dataService.getInitiatorQRCode(allowedInitiator))
                );
                return;
        }
    }

    async addInitiator(event) {
        event.preventDefault();
        const form = event.target;
        const data = new FormData(form);
        const nextInitiator = data.get('nextInitiator')?.toString();
        if (!nextInitiator || this.condition.Type !== 'InitiatorCondition') {
            return;
        }
        const initiatorCondition = this.condition as InitiatorCondition;
        initiatorCondition.AllowedInitiators.push(nextInitiator);
        this.initiatorConditionQQrCodes.push(await this.dataService.getInitiatorQRCode(nextInitiator));
        form.reset();
    }


    refresh() {
        this.cd.detectChanges();
    }

    abort() {
        this.dialogRef.close(true);
    }

    async save() {
        await this.callbackSaveCondition(this.condition);
        this.dialogRef.close(true);
    }

    copyToClipboard(value: string) {
        navigator.clipboard.writeText(value);
        this.guiService.showToast(null, this.translate('Portal.CopiedToClipboard'));
    }

    async downloadInitiatorImage(initiator) {
        const imageBuffer: ArrayBuffer = await this.dataService.getInitiatorQRCodeImage(initiator);
        const imageBlob = new Blob([imageBuffer]);
        fileSaver.saveAs(imageBlob, `QR-Code_${initiator}.png`);
    }

    removeInitiator(initiator, index) {
        const initiatorCondition = this.condition as InitiatorCondition;
        let nextAllowedInitiators = [...initiatorCondition.AllowedInitiators];
        nextAllowedInitiators.splice(index, 1);
        initiatorCondition.AllowedInitiators = nextAllowedInitiators;
    }

}
