<ng-container>
    <div class="datefilter">
        <div class="datefilter-inner">
            <input nbInput [placeholder]="'🌐General.Date' | translate:lang" [nbDatepicker]="rangepicker"
                [status]="'primary'">
            <nb-rangepicker #rangepicker (rangeChange)="changeDateRange($event)"></nb-rangepicker>
            <!--[(range)]="dateRange"-->
            <div class="datefilter-icon">
                <nb-icon icon="calendar-outline"></nb-icon>
            </div>
        </div>
        <nb-select #daterange_select [selected]="selectedDateRange" (selectedChange)="changeSelectedDateRange($event)"
            status="primary">
            <nb-option *ngFor="let selectableDateRange of selectableDateRanges" [value]="selectableDateRange">
                {{"🌐Filter." + selectableDateRange | translate:lang}}</nb-option>
        </nb-select>
    </div>
</ng-container>
