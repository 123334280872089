import { ChangeDetectorRef, Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";

@Component({
    selector: "ngx-message-dialog",
    templateUrl: "./message-dialog.component.html",
    styleUrls: ["./message-dialog.component.scss"]
})
export class MessageDialogComponent extends Localized {

    public title: string;
    public message: string;

    // TODO: inject data via config like in https://blog.angular-university.io/angular-material-dialog/

    constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
        translationService: TranslationService,
        protected cd: ChangeDetectorRef) {
        super(translationService);
    }

    refresh() {
        this.cd.detectChanges();
    }

}
