<nb-card style="max-height: 90vh; overflow-y: scroll;">
    <nb-card-header>{{"🌐Save.Title" | translate:lang}}</nb-card-header>
    <nb-card-body>

        <ng-container *ngIf="overwriteOption == 'IsNew'">
            <p>{{translateWithEntity("🌐Save.Saved")}}</p>
        </ng-container>
        <ng-container *ngIf="overwriteOption == 'MayOverwrite'">
            <p>{{translateWithEntity("🌐Save.Overwritten")}}</p>
            <p>{{"🌐Save.CreateNew" | translate:lang}}</p>
        </ng-container>
        <ng-container *ngIf="overwriteOption == 'NewIDRequired'">
            <p>{{translateWithEntity("🌐Save.NewIDRequired")}}</p>
        </ng-container>

        <div *ngIf="overwriteOption == 'MayOverwrite'">
            <nb-checkbox status="info" [(checked)]="saveAsNew" name="replace" (change)="changedSaveAsNew()">
                {{"🌐Save.SaveAsNewEntry" | translate:lang}}</nb-checkbox>
        </div>

        <ng-container *ngIf="saveAsNew">
            <p>
                <label for="id">{{"🌐Save.NewID" | translate:lang}}</label>&nbsp;
                <input type="text" [(ngModel)]="newID" id="id" placeholder="ID" (keyup)="update()"
                    (change)="update()" />
            </p>
        </ng-container>

        <p *ngIf="!isNewIDValid" class="error">{{"🌐Save.InvalidId" | translate:lang}}</p>
        <p *ngIf="newIDChangedButExists" class="error">{{"🌐Save.IDAlreadyUsed" | translate:lang}}</p>

        <div [hidden]="false == (hasDependents && overwriteOption == 'MayOverwrite')">
            <!-- hidden attribute instead ngIf to preserve state (propagationEnabled) -->
            <hr />
            <h3>{{"🌐Save.Caution" | translate:lang}}</h3>
            <p>{{("🌐Save.AlreadyInUse" | translate:lang)
                .replace("%entity-type%", '🌐EntityType-Nominative.' + entityType | translate:lang)
                .replace("%entity-id%", entity.ID)
                .replace("%new-id%", newID)
                }}</p>
            <p>
                <nb-checkbox status="info" [(checked)]="propagationEnabled" name="replace">{{"🌐Save.DependentEntries" |
                    translate:lang}}</nb-checkbox>
            </p>

            <div>
                <ng-container *ngIf="hasDependents">
                    <ng-container *ngFor="let deps of dependents.Entities | keyvalue">
                        <ng-container *ngIf="deps.value[newID]?.length > 0">
                            <h4>{{"🌐EntityType." + deps.key | translate:lang}}</h4>
                            <ul>
                                <li *ngFor="let ent of deps.value[newID]">{{ent.Name}} ({{ent.ID}})</li>
                            </ul>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </div>


        </div>


    </nb-card-body>

    <nb-card-footer class="d-flex">

        <button nbButton id="button-dialog-success" status="success" (click)="save()"
            [disabled]="!isNewIDValid || newIDChangedButExists" class="w-100 mr-2">{{"🌐General.Save" |
            translate:lang}}</button>
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-100 ml-2">{{"🌐General.Cancel" |
            translate:lang}}</button>

    </nb-card-footer>
</nb-card>