<nb-card>
    <nb-card-header>{{"🌐ChangePassword.Title" | translate:lang}}</nb-card-header>
    <nb-card-body>

        <p>{{"🌐PasswordRules.Description" | translate:lang}}</p>

        <div class="row align-items-center my-3">
            <div class="col-4">
                <label for="password1">{{"🌐ChangePassword.NewPassword" | translate:lang}}</label>&nbsp;
            </div>
            <div class="col-8">
                <input type="password" [(ngModel)]="password1" id="password1" placeholder="" (keyup)="update()"
                    (change)="update()" />
            </div>
        </div>

        <div class="row align-items-center my-3">
            <div class="col-4">
                <label for="password2">{{"🌐ChangePassword.ConfirmPassword" | translate:lang}}</label>&nbsp;
            </div>
            <div class="col-8">
                <input type="password" [(ngModel)]="password2" id="password2" placeholder="" (keyup)="update()"
                    (change)="update()" />
            </div>
        </div>

        <p class="error" [innerHTML]="errorMessage || '&nbsp;'"></p> <!-- not ngIf to reserve space -->

    </nb-card-body>

    <nb-card-footer class="d-flex">

        <button nbButton status="success" (click)="save()" [disabled]="!isOK" class="w-50 mr-2">{{"🌐General.OK" |
            translate:lang}}</button>
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-50 ml-2">{{"🌐General.Cancel" |
            translate:lang}}</button>

    </nb-card-footer>
</nb-card>
