<div class="LocalizedHtml" [class.LocalizedHtml--expanded]="isExpanded">
  <div class="LocalizedHtml-row">
    <div class="LocalizedHtml-flagBox">
      <nb-icon icon="globus" pack="custom-icons" class="LocalizedHtml-icon clickable" (click)="onExpandButtonClicked()"></nb-icon> 
      <nb-icon [icon]="isExpanded ? 'chevron-up-outline' : 'chevron-down-outline'" pack="eva" class="LocalizedHtml-icon clickable" (click)="onExpandButtonClicked()"></nb-icon> 
    </div>
    <div class="LocalizedHtml-editor">
      <iovent-html-editor [(value)]="value"></iovent-html-editor>
      <span *ngIf="!isExpanded">
        <i>{{ translateWithTokens('Portal.LocalizedString.NumberTranslationsFound', ['{0}', getNumberOfFilledTranslations()]) }}</i>
      </span>
    </div>
  </div>

  <ng-container *ngIf="isExpanded">
    <ng-container *ngFor="let languageCode of allLanguages">
      <div class="LocalizedHtml-row" >
        <div class="LocalizedHtml-flagBox">
          <div [style.background-image]="getFlagUrl(languageCode)" class="LocalizedHtml-icon"></div> 
        </div>
        <iovent-html-editor [(value)]="localizedHtml[languageCode]"></iovent-html-editor>
      </div>
    </ng-container>
  </ng-container>

    
</div>