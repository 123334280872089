import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";
import { Location } from 'shared-gen/Model/Locations/Location';
import { Product } from "shared-gen/Model/Products/Product";
import { TableHelper } from "app/@helper/TableHelper";
import { hasRights } from "app/auth/iovent/rights";
import { DataService } from "app/services/data.service";
import { StorageService } from "app/services/storage.service";
import { Access } from "shared-gen/Model/Auth/Access";
import { Feature } from "shared-gen/Model/Auth/Feature";
import { Device } from "shared-gen/Model/Devices/Device";
import { XDeviceInfo } from "shared-gen/Model/Devices/XDeviceInfo";
import { ActivatedRoute } from "@angular/router";
import { UserStore } from "app/@core/stores/user.store";
import { AnyPtrRecord } from "dns";
import { catchError } from 'rxjs/operators';
import { GuiService } from "app/services/gui.service";

@Component({
    selector: "ngx-device-clone-dialog",
    templateUrl: "./device-clone-dialog.component.html",
    styleUrls: ["./device-clone-dialog.component.scss"]
})
export class DeviceCloneDialogComponent extends Localized {

    _sourceID: string = "";
    get sourceID(): string { return this._sourceID };
    set sourceID(value: string) {
        this._sourceID = value;
        this.targetID = this._sourceID + "-test";
    }
    targetID: string = "";
    overwriteExisting: boolean = false;
    cloneConfig: boolean = false;
    cloneProducts: boolean = false;
    cloneMenu: boolean = false;
    clonePrices: boolean = false;
    cloneSelections: boolean = false;
    skipIfExisting: boolean = true;
    targetPostfix: string = "";
    localClone: boolean = true;
    remoteUrl: string = "https://eude-sandbox-vm-01.iovent.net/api/1.0";
    //sourceLicenseKey: string = "";
    userID: string = "iovent";
    password: string = "";

    importing = 0;

    constructor(public dialogRef: MatDialogRef<DeviceCloneDialogComponent>,
        translationService: TranslationService, protected dataService: DataService, protected guiService: GuiService) {
        super(translationService);
    }

    async confirm() {
        this.importing = 0.01;
        let promise = undefined;
        let query = {
            SourceID: this.sourceID,
            TargetID: this.targetID,
            OverwriteExisting: this.overwriteExisting,
            TargetPostfix: this.targetPostfix,
            CloneConfig: this.cloneConfig,
            CloneProducts: this.cloneProducts,
            CloneMenu: this.cloneMenu,
            ClonePrices: this.clonePrices,
            CloneSelections: this.cloneSelections,
            SkipIfExisting: this.skipIfExisting
        }
        if (!this.localClone) {
            promise = this.dataService.postbase("x/devices/import",
                {
                    Url: this.remoteUrl,
                    UserID: this.userID,
                    Password: this.password,
                    ...query
                },
                null);
        } else {
            promise = this.dataService.postbase("x/devices/clone",
                query,
                null);
        }
        let result = await promise
            .then((result) => {
                if (result) console.log(result);
                this.dialogRef.close({ ID: this.targetID });
            })
            .catch((ex) => {
                console.log(ex);
                this.guiService.showToast(ex.message, this.translate("🌐Messages.ErrorWhileCloning"), "danger", 15000);
                this.importing = 0;
                //this.dialogRef.close({Error: ex.message});
            });
    }
}
