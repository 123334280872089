import { Component, OnInit } from '@angular/core';
import { GuiService } from 'app/services/gui.service';
import { Choice } from 'shared-gen/Model/Products/Choice';
import { Option } from 'shared-gen/Model/Products/Option';
import { defaultOptions } from './default-options';
import { supportedProducts } from './supported-products';
import { cloneDeep } from 'shared/utils/core-utils';
import { ProductBase } from 'shared-gen/Model/Products/ProductBase';
import { UserStore } from 'app/@core/stores/user.store';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Localized } from 'shared/localized';
import { TranslationService } from 'shared/services/translation.service';
import { EversysProductGeneratorTheme, productThemes } from './product-themes';
import { Resource } from 'shared-gen/Model/Products/Resource';
import { DataService } from 'app/services/data.service';
import {EntityType} from 'shared-gen/Model/Utils/EntityType';
import { generateProductBase } from './eversys-product-generation-logic';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'iovent-eversys-product-generator-dialog',
  templateUrl: './eversys-product-generator-dialog.component.html',
  styleUrls: ['./eversys-product-generator-dialog.component.scss']
})
export class EversysProductGeneratorDialog extends Localized implements OnInit {

  providerId: string;
  options: Option[];
  blueprintProducts: { checked: boolean, productBase: ProductBase }[];
  productPrefix: string = "";
  vat: number = 19;
  productThemes = productThemes;
  theme: EversysProductGeneratorTheme = productThemes[0];
  beanResources: Resource[];
  milkResources: Resource[];
  powderResources: Resource[];

  newOptionId: string;
  newOptionName: string;

  isGenerating = false;
  isSaving = false;
  generatedProductBases: ProductBase[] = undefined;

  constructor(
    public dialogRef: MatDialogRef<EversysProductGeneratorDialog>,
    translationService: TranslationService, 
    userStore: UserStore, 
    private guiService: GuiService, 
    private dataService: DataService
  ) {
    super(translationService)
    this.options = cloneDeep(defaultOptions);
    this.blueprintProducts = cloneDeep(supportedProducts).map(productBase => ({
      checked: false,
      productBase
    }));
    this.beanResources = [Resource.BeansLeft, Resource.BeansRight];
    this.milkResources = [Resource.Milk, Resource.Milk2];
    this.powderResources = [Resource.Chocolate, Resource.Powder, Resource.Powder2];
    this.providerId = userStore.getIoventUser().ProviderID;
  }

  ngOnInit(): void {
    // this.blueprintProducts[0].checked = true;
    // this.generate();
  }

  //////////////////////////////////////
  // Form - Option Handling           //
  //////////////////////////////////////

  drop(event: CdkDragDrop<Option>) {
    if (event.previousContainer !== event.container) {
      return;
    }
    const dragedOption = this.options[event.previousIndex];

    let nextOptions = [...this.options];
    nextOptions.splice(event.previousIndex, 1);
    const optionsBefore = nextOptions.slice(0, event.currentIndex);
    const optionsAfter = nextOptions.slice(event.currentIndex);
    nextOptions = [...optionsBefore, dragedOption, ...optionsAfter];
    this.options = nextOptions;
  }

  removeOption(option: Option) {
    const index = this.options.indexOf(option);
    if (index < 0) {
      return;
    }
    this.options.splice(index, 1);
  }

  removeChoice(option: Option, choice: Choice) {
    const index = option.Choices.indexOf(choice);
    if (index < 0) {
      return;
    }
    option.Choices.splice(index, 1);
  }

  addChoice(option: Option) {
    option.Choices.push({
      ID: "",
      Name: "",
    })
  }

  addOption() {
    if (!this.newOptionId || !this.newOptionName) {
      this.guiService.showToast("ID und Name sind Pflichtfelder", "Fehler", 'danger');
      return;
    }
    if (this.options.some((option) => option.ID.toLocaleLowerCase() === this.newOptionId?.toLocaleLowerCase())) {
      this.guiService.showToast("ID ist bereits vergeben.", "Fehler", 'danger');
      return;
    }
    this.options.push({
      ID: this.newOptionId,
      Name: this.newOptionName,
      Choices: [
        { ID: "", Name: "" }
      ]
    });
    this.newOptionId = undefined;
    this.newOptionName = undefined;
  }

  //////////////////////////////////////
  // Form - Additional Form Fields    //
  //////////////////////////////////////


  changeVat(vat) {
    this.vat = vat;
  }
  changeTheme(theme: EversysProductGeneratorTheme) {
    this.theme = theme;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Generates the product bases including desired product instanmces. This function does NOT save the products yet.
   * It stores the generated products bases in this.generatedProductBases which will trigger the review page being shown.
   */
  async generate() {
    this.isGenerating = true;

    try {
      const blueprints = this.blueprintProducts.filter(blueprint => blueprint.checked);
      const generatedProductBases = blueprints.map(blueprint => generateProductBase(blueprint.productBase, this.options, {
        productPrefix: this.productPrefix, 
        providerId: this.providerId, 
        vat: this.vat, 
        theme: this.theme
      }));
      
      for (const generatedProductBase of generatedProductBases) {
        const productIdExists = await this.dataService.existsEntity(generatedProductBase.ID, EntityType.ProductBase);
        if (productIdExists) {
          this.guiService.showToast(`Product with ID ${generatedProductBase.ID} already exists`, "Fehler", 'danger');
          return;
        }
      }

      this.generatedProductBases = generatedProductBases;
    } finally {
      this.isGenerating = false;
    }
  }

  //////////////////////////////////////
  // Review Page                      //
  //////////////////////////////////////

  isResourceChecked(resources: Resource[], resource: Resource) {
    return resources.includes(resource);
  }
  toggleResource(resources: Resource[], resource: Resource) {
    const index = resources.findIndex(it => it === resource);
    if (index >= 0) {
      resources.splice(index, 1);
    } else {
      resources.push(resource);
    }
  }

  /**
   * Rejects the temporary generated product bases and naviagtes back to the form
   */
  rejectReview() {
    this.generatedProductBases = undefined;
  }

  /**
   * Actual save of generated product bases incl. all instances
   */
  async save() {
    this.isSaving = true;
    try {
      for (const productBase of this.generatedProductBases) {
        console.log('saving ', productBase.ID);
        await this.dataService.save(productBase, EntityType.ProductBase, false);
      }
      console.log('saving of generated products done');
      this.guiService.showToast('Saving of generated Products done', 'Fertig', 'success');
      this.closeDialog();
    } finally {
      this.isSaving = false;
    }
  }

}
