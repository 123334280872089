import { Component } from '@angular/core';
import { Localized } from 'shared/localized';
import { TranslationService } from 'shared/services/translation.service';

@Component({
    selector: 'ngx-table-help-dialog',
    templateUrl: './table-help-dialog.component.html',
    styleUrls: ['./table-help-dialog.component.scss']
})
export class TableHelpDialogComponent extends Localized {

    constructor(translationService: TranslationService) {
        super(translationService);
    }


}
