<nb-card class="nb-nested-card">

    <nb-card-header class="d-flex justify-content-between">
        <span>
            <ng-content select="[title]"></ng-content>
        </span>
        <span class="pl-3 row" *ngIf="data">
            <span class="col-3"></span>
            <input nbInput #timedEntryInput id="timedEntryInput" class="col-6" [disabled]="!isTimedEntryEditable()"
                [nbDatepicker]="timedDatePicker" [(ngModel)]="selectedTimedEntryStartZoneDate"
                (ngModelChange)="selectedTimedEntryStartZoneDateChanged($event)" />
            <nb-datepicker #timedDatePicker [min]="minTimedStartZoneDate" startView="month"
                [filter]="startZoneDateFilter"></nb-datepicker>
            <!-- TODO: optimize as month picker -->
            <nb-select id="timedEntrySelect" class="col-8" [selected]="selectedTimedEntryIndex"
                (selectedChange)="changeSelectedTimedEntry($event)">
                <nb-option *ngFor="let entry of data.Timed; let i = index" [value]="i">{{asDate(entry.StartZoneDate) |
                    date:'yyyy-MM-dd'}}</nb-option> <!-- TODO: international date formats -->
            </nb-select>
            <button *ngIf="isEditable" nbButton class="col-1" status="primary"
                (click)="addTimedEntry(); timedEntryInputRef?.nativeElement?.focus();">
                <nb-icon icon="plus-circle-outline"></nb-icon>
            </button>
        </span>
        <span class="row" *ngIf="!data">
            <span class="col-11"></span>
            <button *ngIf="isEditable" nbButton class="col-1" status="primary" (click)="undefined">
                {{"🌐General.Add" | translate:lang}}
            </button>
        </span>
    </nb-card-header>

    <nb-card-body *ngIf="data" class="py-3">
        <button *ngIf="isEditable && (!isAlwaysUnlocked && selectedTimedEntryIsLocked)" nbButton status="danger"
            (click)="unlock();">
            <nb-icon icon="unlock-outline"></nb-icon>
            {{"🌐Timed.UnlockActiveDataEditing" | translate:lang}}
        </button>
        <div [class.disabledContent]="!isTimedEntryEditable()">
            <ng-content select="[content]"></ng-content>
        </div>
        <div>
            <ng-content select="[readonlycontent]"></ng-content>
        </div>
    </nb-card-body>

</nb-card>
