<ng-container *ngIf="style == 'tablecell'">
    <span *ngIf="isLocal">{{"🌐General.Local" | translate:lang}} ({{locIDs.length}}
        {{"🌐General.Location" + (locIDs.length > 1 ? "s" : "") | translate:lang}})</span>
    <span *ngIf="!isLocal">{{"🌐General.Global" | translate:lang}}</span>
</ng-container>

<ng-container *ngIf="style == 'locallabel'">
    <span *ngIf="isLocal">({{"🌐General.LocalConfiguration" | translate:lang}}:
        <span class="clickableLocations" (click)="showLocations()">
            {{locIDs.length}}
            {{"🌐General.Location" + (locIDs.length > 1 ? "s" : "") | translate:lang}})</span>
    </span>
</ng-container>

<ng-container *ngIf="!style">
    <span *ngIf="isLocal">{{"🌐General.Local" | translate:lang}}</span>
    <span *ngIf="!isLocal">{{"🌐General.Global" | translate:lang}}</span>
</ng-container>
