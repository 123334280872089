import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Localized } from "shared/localized";
import { TranslationService } from "shared/services/translation.service";


@Component({
    selector: "ngx-date-dialog",
    templateUrl: "./date-dialog.component.html",
    styleUrls: ["./date-dialog.component.scss"]
})
export class DateDialogComponent extends Localized {

    date: Date = new Date();

    get now(): Date {
        return new Date();
    }

    minDate = new Date("2021-01-01"); // TODO ?

    constructor(public dialogRef: MatDialogRef<DateDialogComponent>, translationService: TranslationService) {
        super(translationService);
    }

    initalize() {

    }

    confirm() {
        this.dialogRef.close({ date: this.date });
    }
}
