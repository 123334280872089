import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DataService } from "../../../services/data.service";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";
import { UserStore } from "../../../@core/stores/user.store";

/**
 * In this dialog, the user can input a new password.
 * The password is checked on the Backend, if it meets the requirements.
 * If not, the reason is shown. If it is OK, the user can click "OK"
 * and the password is returned, otherwise just "Cancel" can be clicked,
 * where null is returned.
 */
@Component({
    selector: "iovent-password-dialog",
    templateUrl: "./password-dialog.component.html",
    styleUrls: ["./password-dialog.component.scss"]
})
export class PasswordDialogComponent extends Localized {

    // Internal
    errorMessage = "";
    password1 = "";
    password2 = "";
    isOK = false;

    constructor(private dataService: DataService,
        public dialogRef: MatDialogRef<PasswordDialogComponent>,
        private userStore: UserStore,
        translationService: TranslationService,
        private changeDetectorRef: ChangeDetectorRef) {
        super(translationService);
    }

    async update() {
        this.isOK = false;
        // Check on Backend, if the new password meets the requirements
        this.errorMessage = await this.dataService.validatePassword(
            this.password1, this.translationService.getLanguageCode()) ?? "";
        // Check, if the two passwords match
        if (this.errorMessage == "" && this.password1 != this.password2)
            this.errorMessage = this.translate("🌐ChangePassword.PasswordAndConfirmPasswordDoNotMatch");
        this.isOK = (this.errorMessage.length == 0);
    }

    async save() {
        if (this.isOK)
            this.dialogRef.close(this.password1);
    }

    async cancel() {
        this.dialogRef.close(null);
    }

}
