import { Menu } from "../../../shared-gen/Model/Menus/Menu";
import { MenuPageFun } from "./menu-page.fun";
import { getDefaultID } from "../../core";
import { Product } from '../../../shared-gen/Model/Products/Product';
import { ProductBase } from '../../../shared-gen/Model/Products/ProductBase';
import { EntityType } from "../../../shared-gen/Model/Utils/EntityType";

export class MenuFun {

    static createNew(provider: string): Menu {
        return {
            Type: EntityType.Menu,
            ID: getDefaultID(),
            Hash: '',
            Name: '',
            AutoStartPage: false,
            Pages: [MenuPageFun.createNew()],
            Products: [],
            ProviderID: provider
        };
    }

    static findProductBase(menu: Menu, product: Product): ProductBase | null {
        if (!product)
            return null;
        for (let menuPage of menu.Pages) {
            for (let cell of menuPage.Cells) {
                if (cell && cell.Product) {
                    if (cell.Product.Instances.findIndex(it => {
                        return it.ID === product.ID;
                    }) !== -1) {
                        return cell.Product;
                    }
                }
            }
        }
        return null;
    }

    /**
     * Gets all products from the given menu, by iterating the pages,
     * cells, product bases and accumulating their instances.
     */
    static getAllProducts(menu: Menu): Product[] {
        const acc: Product[] = [];
        for (let menuPage of menu.Pages)
            MenuPageFun.getAllProductsAcc(menuPage, acc);
        return acc;
    }

    /**
     * Gets all base products from the given menu, by iterating the pages,
     * the cells and accumulating their base products.
     */
    static getAllProductBases(menu: Menu): ProductBase[] {
        const acc: ProductBase[] = [];
        for (let menuPage of menu.Pages)
            MenuPageFun.getAllProductBasesAcc(menuPage, acc);
        return acc;
    }



}
