import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { IRestrictedToLocations } from "../../../../shared-gen/Model/Locations/IRestrictedToLocations";
import { Dependents } from "../../../../shared-gen/Model/Utils/Dependents";
import { Entity } from "../../../../shared-gen/Model/Utils/Entity";
import { EntityType } from "../../../../shared-gen/Model/Utils/EntityType";
import { Localized } from "../../../../shared/localized";
import { DependentsFun } from "../../../../shared/model/utils/dependents.fun";
import { EntityFun } from "../../../../shared/model/utils/entity.fun";
import { TranslationService } from "../../../../shared/services/translation.service";
import { UserStore } from "../../../@core/stores/user.store";
import { DataService } from "../../../services/data.service";

/**
 * This dialog can be shown when an entity is saved, to present the
 * user a list of entities which are using this entity and should be
 * updated by the user.
 * The user can confirm the saving (overwrite the old entity, if access
 * rights suffice), choose "save as" to allow choosing a new ID, or cancel the saving.
 */
@Component({
    selector: "ngx-save-dialog",
    templateUrl: "./save-dialog.component.html",
    styleUrls: ["./save-dialog.component.scss"]
})
export class SaveDialogComponent<T extends Entity> extends Localized implements OnInit {

    // Parameters
    entity: T;
    entityType: EntityType;

    // Internal
    overwriteOption: 'IsNew' | 'MayOverwrite' | 'NewIDRequired' = 'IsNew';
    freeID: string;
    newID: string;
    newIDExists = false;
    isNewIDValid = false;
    newIDChangedButExists = false;
    saveAsNew = false;
    dependents: Dependents = {} as Dependents;
    hasDependents = false;
    propagationEnabled = false;

    constructor(private dataService: DataService,
        public dialogRef: MatDialogRef<SaveDialogComponent<T>>,
        private userStore: UserStore,
        translationService: TranslationService,
        private changeDetectorRef: ChangeDetectorRef) {
        super(translationService);
    }

    async ngOnInit() {
        if (this.entity == null || this.entityType == null) throw new Error("Entity or type not given");
        // When the given entity has no valid ID, start with <EntityType>1.
        if (!EntityFun.isIDValid(this.entity.ID)) {
            this.entity.ID = this.entityType + "1";
        }
        this.newID = this.entity.ID;
        this.isNewIDValid = EntityFun.isIDValid(this.newID);
        // Compute recommended free ID (the same as the original one, if it is new) - not for Device
        const changeIDAllowed = this.entityType != EntityType.Device;
        if (changeIDAllowed) {
            const mayOverwrite = await this.dataService.mayWriteEntity(this.entity.ID, this.entityType)
            this.freeID = await this.dataService.getNewID(this.entity.ID, this.entityType);
            const oldIDExists = this.entity.ID != this.freeID;
            if (oldIDExists && mayOverwrite) {
                // User may choose a different ID, but can also overwrite (default)
                this.overwriteOption = "MayOverwrite";
            }
            else if (oldIDExists && !mayOverwrite) {
                // User must choose a different ID. Preselect it.
                this.overwriteOption = "NewIDRequired";
                this.saveAsNew = true;
                this.newID = this.freeID;
            }
            else {
                // Entity ID is new
                this.overwriteOption = "IsNew";
            }
        }
        await this.update();
    }

    async update() {
        // Check, if the new ID is valid
        this.isNewIDValid = EntityFun.isIDValid(this.newID);
        if (this.isNewIDValid) {
            // Check, if an entity with the new ID already exists on the server
            this.newIDExists = await this.dataService.existsEntity(this.newID, this.entityType);
            // When the original ID was changed, but the changed ID is already used, don't allow to overwrite it
            this.newIDChangedButExists = (this.newIDExists && this.saveAsNew);
            // When it already exists, query entities dependent on this one
            this.dependents = this.newIDExists
                ? await this.dataService.getDependents([this.newID], this.entityType)
                : DependentsFun.none();
            this.hasDependents = DependentsFun.hasAny(this.dependents, this.newID);
        } else {
            this.newIDExists = false;
            this.newIDChangedButExists = false;
            this.hasDependents = false;
        }
        this.changeDetectorRef.detectChanges();
    }

    private async changedSaveAsNew() {
        if (this.saveAsNew) // When the checkbox was activated, use free ID as recommended one
            this.newID = this.freeID;
        else // Otherwise, the original ID is used again
            this.newID = this.entity.ID;
        await this.update();
    }

    async save() {
        const id = this.saveAsNew ? this.newID : this.entity.ID;
        if (EntityFun.isIDValid(id)) {
            this.entity.ID = id;
            // Currently always save with the location restrictions of this user.
            // (Not all entities support this property yet, but the backend will just ignore it when set but unsupported)
            (this.entity as IRestrictedToLocations).RestrictedToLocationIDs =
                this.userStore.getUser().ioventUser.RestrictedToLocationIDs;
            const result: SaveDialogResult<T> = {
                entity: this.entity,
                propagationEnabled: this.propagationEnabled
            };
            this.dialogRef.close(result);
        }
    }

    translateWithEntity(vocabID: string) {
        const entityTypeName = this.translate("🌐EntityType-Nominative." + this.entityType);
        return this.translateWithTokens(vocabID, ["%entity-type%", entityTypeName,
            "%entity-id%", this.entity.ID]);
    }

}

export interface SaveDialogResult<T extends Entity> {
    entity: T;
    propagationEnabled: boolean;
}
