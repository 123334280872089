import { APIEndpoint } from "../@models/core";
import { EntityType } from "../../shared-gen/Model/Utils/EntityType";
import { Feature } from "../../shared-gen/Model/Auth/Feature";

/**
 * Maps EntityTypes to APIEndpoint, table page route and details page route.
 */
const entityTypesData: EntityTypesData[] = [
    type(EntityType.Location, Feature.Locations, "locations", "/pages/locations/list", "/pages/locations/detail/"),
    type(EntityType.Device, Feature.Devices, "devices", "/pages/devices/list", "/pages/devices/detail/"),
    type(EntityType.User, Feature.Users, "users/accounts", "/pages/users/list", "/pages/users/detail/"),
    type(EntityType.Campaign, Feature.Campaigns, "campaigns", "/pages/campaigns/list", "/pages/campaigns/detail/"),
    type(EntityType.ProductBase, Feature.Products, "products", "/pages/configuration/products/list", "/pages/configuration/products/detail/"),
    type(EntityType.Menu, Feature.Menus, "menus", "/pages/configuration/menus/list", "/pages/configuration/menus/detail/"),
    type(EntityType.Prices, Feature.Prices, "prices", "/pages/configuration/prices/list", "/pages/configuration/prices/detail/"),
    type(EntityType.Selections, Feature.Selections, "selections", "/pages/configuration/selections/list", "/pages/configuration/selections/detail/"),
    type(EntityType.DeviceConfig, Feature.DeviceConfigs, "deviceconfigs", "/pages/configuration/deviceconfigs/list", "/pages/configuration/deviceconfigs/detail/"),
    type("QRVoucher", Feature.QRVouchers, "qrvouchers", "/pages/reports/qrvouchers/list", "/pages/reports/qrvouchers/detail/")
]

/**
 * Some classes are technically not subtypes of Entity, however we can share the logic above.
 * Maybe we can TIDY this up later.
 */
type ExtendedEntityType = EntityType | "QRVoucher";

interface EntityTypesData {
    entityType: ExtendedEntityType;
    feature: Feature;
    apiEndpoint: APIEndpoint;
    tablePageRoute: string;
    detailsPageRoute: string;
}

function type(entityType: ExtendedEntityType, feature: Feature, apiEndpoint: APIEndpoint,
    tablePageRoute: string, detailsPageRoute: string): EntityTypesData {
    return { entityType, feature, apiEndpoint, tablePageRoute, detailsPageRoute };
}

/**
 * Gets the Feature which belongs to the given EntityType.
 */
export function getFeatureForEntityType(entityType: ExtendedEntityType): Feature {
    const ret = entityTypesData.find(it => it.entityType == entityType)?.feature;
    if (ret == null)
        throw new Error("Unknown Feature for EntityType " + entityType);
    return ret;
}

/**
 * Gets the EntityType which belongs to the given APIEndpoint.
 */
export function getEntityTypeForEndpoint(apiEndpoint: APIEndpoint): ExtendedEntityType {
    const ret = entityTypesData.find(it => it.apiEndpoint == apiEndpoint)?.entityType;
    if (ret == null)
        throw new Error("Unknown EntityType for APIEndpoint " + apiEndpoint);
    return ret;
}

/**
 * Gets the APIEndpoint which belongs to the given EntityType.
 */
export function getEndpointForEntityType(entityType: ExtendedEntityType): APIEndpoint {
    const ret = entityTypesData.find(it => it.entityType == entityType)?.apiEndpoint;
    if (ret == null)
        throw new Error("Unknown APIEndpoint for EntityType " + entityType);
    return ret;
}

/**
 * Gets the APIEndpoint which belongs to the given EntityType.
 */
export function getEndpointForEntityTypeShort(entityType: ExtendedEntityType): APIEndpoint {
    let ret = entityTypesData.find(it => it.entityType == entityType)?.apiEndpoint;
    if (ret == null)
        throw new Error("Unknown APIEndpoint for EntityType " + entityType);
    if (entityType == EntityType.Device || entityType == EntityType.Location) {
        ret += "/short/*";
    }
    return ret;
}

/**
 * Gets the table page route which belongs to the given EntityType.
 */
export function getTablePageRouteForEntityType(entityType: ExtendedEntityType): string {
    const ret = entityTypesData.find(it => it.entityType == entityType)?.tablePageRoute;
    if (ret == null)
        throw new Error("Unknown table page route for EntityType " + entityType);
    return ret;
}

/**
 * Gets the details page route which belongs to the given EntityType.
 * The ID must be appended, i.e. {detailsPageRoute}/{entityID}
 */
export function getDetailsPageRouteForEntityType(entityType: ExtendedEntityType): string {
    const ret = entityTypesData.find(it => it.entityType == entityType)?.detailsPageRoute;
    if (ret == null)
        throw new Error("Unknown details page route for EntityType " + entityType);
    return ret;
}
