import * as _ from 'lodash';

/**
 * Creates a deep clone of the given object.
 */
export function cloneDeep<T>(object: T): T {
    return _.cloneDeep(object);
}

/**
 * Returns true, iff the given two objects are deeply identical.
 * This is simply solved by a comparison of their JSON representations.
 */
export function equalsDeep(object1: any, object2: any) {
    return JSON.stringify(object1) === JSON.stringify(object2);
}

/**
 * Waits the given amount in ms.
 */
export async function sleep(ms: number) {
    if (ms <= 0) {
        return;
    }
    await new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Returns true, iff all values of the first given array (needles) can
 * be found in the second given array (haystack).
 * When the needles are falsy, true is returned.
 * When the haystack is falsy, the needles must be falsy for returning true,
 * otherwise false is returned.
 */
export function containsAll<T>(needles: T[], haystack: T[]) {
    if (!needles)
        return true;
    if (!haystack)
        return false;
    return needles.every(v => haystack.indexOf(v) > -1);
}

/**
 * Adds the given item to the given array, if not already part of the array,
 * or removes it, when already part of the array.
 */
export function swapInArray<T>(array: T[], item: T) {
    const index = array.indexOf(item);
    if (index == -1)
        array.push(item);
    else
        array.splice(index, 1);
}

/**
 * Modifies the given object by removing all null/undefined values from the
 * object, including array items and recursively.
 */
export function removeNulls(obj: any) {
    var isArray = obj instanceof Array;
    for (var k in obj) {
        if (obj[k] == null) isArray ? obj.splice(k, 1) : delete obj[k];
        else if (typeof obj[k] == "object") removeNulls(obj[k]);
        if (isArray && obj.length == k) removeNulls(obj);
    }
    return obj;
}

/**
 * Replacement for missing "?." support in TypeScript. Call like this:
 * const x = nullSafe(() => all.of.these.objects.may.be._null);
 * which returns the value of "_null" or the given default value (when
 * the expression throws any type of exception) or null.
 */
export function nullSafe<T>(getter: () => T | null, defaultValue: T | null = null): T | null {
    try {
        return getter();
    }
    catch {
        return defaultValue;
    }
}

/**
 * Returns a new array, with each element of the given array appearing
 * only a single time (i.e. removing duplicates).
 */
export function unique<T>(array: T[]): T[] {
    return array.filter((value, index, self) =>
        self.indexOf(value) === index);
}
