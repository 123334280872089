<nb-card>

    <nb-card-header>
        <span *ngIf="locationTableItem">{{"🌐Supply.AdjustSupplies" | translate:lang}}: {{locationTableItem.Name}}
            ({{locationTableItem.ID}}) </span>
    </nb-card-header>

    <nb-card-body>

        <div *ngFor="let line of lines">
            <div class="row align-items-center my-3">
                <nb-select class="col-6" fullWidth [(selected)]="line.product" scrollStrategy="noop">
                    <nb-option [value]="null">{{"🌐General.None" | translate:lang}}</nb-option>
                    <nb-option *ngFor="let product of resourceProducts" [value]="product">{{product.Name}}</nb-option>
                </nb-select>
                <div class="col-6">
                    <input type="number" nbInput [(ngModel)]="line.amount"
                        fullWidth />{{getResourceProductUnit(line.product)}}
                </div>
            </div>
        </div>

        <button nbButton status="primary" size="small" (click)="addLine()">
            {{"🌐General.AddLine" | translate:lang}}
        </button>

        <ng-container *ngIf="mode!='order'">

            <div *ngIf="supplyStatus" class="row align-items-center my-3">
                <label class="col-6">{{"🌐Supply.AdjustmentMode" | translate:lang}}</label>
                <nb-select class="col-6" fullWidth [(selected)]="mode" scrollStrategy="noop">
                    <nb-option [value]="'add'">{{"🌐Supply.Add" | translate:lang}}</nb-option>
                    <nb-option [value]="'set'">{{"🌐Supply.Set" | translate:lang}}</nb-option>
                </nb-select>
            </div>

            <ng-container *ngIf="mode=='set'">
                <div class="row align-items-center my-3">
                    <label class="col-6">{{"🌐Supply.SetDate" | translate:lang}}</label>
                    <input class="col-6" fullWidth nbInput [nbDatepicker]="dateTimePicker" [(ngModel)]="supplySetDate">
                    <!---->
                    <nb-date-timepicker #dateTimePicker format="yyyy-MM-dd HH:mm" [min]="minTimedStartZoneDate"
                        [max]="now"></nb-date-timepicker><!-- -->
                </div>
            </ng-container>

        </ng-container>


    </nb-card-body>


    <nb-card-footer class="d-flex">
        <button nbButton status="basic" (click)="dialogRef.close(false)" class="w-100 mr-2">{{"🌐General.Cancel" |
            translate:lang}}</button>
        <button nbButton status="success" (click)="confirm()" class="w-100 ml-2">{{"🌐General.Confirm" |
            translate:lang}}</button>
    </nb-card-footer>

</nb-card>
