import { Component } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';
import { Dependencies } from "shared-gen/Model/Utils/Dependencies";
import { Entity } from "../../../../shared-gen/Model/Utils/Entity";
import { EntityType } from "../../../../shared-gen/Model/Utils/EntityType";
import { Localized } from "../../../../shared/localized";
import { TranslationService } from "../../../../shared/services/translation.service";

@Component({
    selector: 'iovent-update-dependencies-dialog',
    templateUrl: './update-dependencies-dialog.component.html',
    styleUrls: ['./update-dependencies-dialog.component.scss']
})
export class UpdateDependenciesDialogComponent extends Localized {

    // Parameters
    entity: Entity;
    entityType: EntityType;
    dependencies: Dependencies = {} as Dependencies;

    constructor(public dialogRef: MatDialogRef<UpdateDependenciesDialogComponent>,
        translationService: TranslationService) {
        super(translationService);
    }

}
