import { Directive, HostListener } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ContactDialogComponent } from '../components/@dialogs/contact-dialog/contact-dialog.component';
import { GuiService } from '../services/gui.service';

@Directive({
    selector: '[contact]'
})
export class ContactDirective {

    constructor(private guiService: GuiService) {
    }

    @HostListener('click', ['$event'])
    clickEvent(event) {
        event.preventDefault();
        event.stopPropagation();

        this.guiService.showDialog({}, ContactDialogComponent, () => { })



    }

}
