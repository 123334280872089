import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { TranslationService } from "shared/services/translation.service";
import { environment } from "../../../environments/runtime-environment";
import { Currency } from "../../../shared-gen/Model/Utils/Currency";
import { Localized } from "../../../shared/localized";
import { getCurStringSettings, toCurStringWithPrecision, toCurStringWithPrecisionShort } from "../../../shared/payment/currencies";
import { UserStore } from "../../@core/stores/user.store";
import { GuiService } from "../../services/gui.service";

@Component({
    selector: 'ngx-price-input',
    templateUrl: './price-input.component.html',
    styleUrls: ['./price-input.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: PriceInputComponent
        }
    ]
})
export class PriceInputComponent extends Localized implements ControlValueAccessor {

    @Input() inputDisabled: boolean = false;

    @Input() short: boolean = false;

    @Input() currency: Currency = undefined;

    _defaultPrice?: number;

    disabled = false;
    private _price: number | null = 0;
    private _precision: number = 2;
    private _precisionFactor: number = 100;
    _placeholder: string = "";

    formatedPrice: string;
    hasFocus: boolean;

    @Input()
    set precision(p: number) {
        this._precision = p ?? 2;
        this._precisionFactor = Math.pow(10, this._precision)
    }

    @Input()
    set defaultPrice(cents: number | undefined) {
        this._defaultPrice = cents;
        this.updateDisplay();
    }

    // always in cents (1/100) , even if different precision : TODO: generalize ?
    @Input()
    set price(cents: number | undefined) {
        if (!cents && cents !== 0) {
            this._price = null;
        }
        else {
            this._price = (cents * (this._precisionFactor / 100)) || 0;   // adjust for different precision than cents
        }

        this.onPriceChanged(this._price);
    }
    @Output() priceChange: EventEmitter<number | null> = new EventEmitter();


    updateDisplay() {
        if (!this._price && this._price !== 0 && this._defaultPrice !== undefined) {
            this._placeholder = this.format(this._defaultPrice);
            this.formatedPrice = "";
            return;
        }
        if (this.hasFocus) {
            this.formatedPrice = (this._price / this._precisionFactor).toFixed(this._precision).toString();
        }
        else {
            this.formatedPrice = this.format(this._price)
        }
    }

    format(price: number): string {
        if (this.short !== false) {
            return toCurStringWithPrecisionShort(price / this._precisionFactor, this.currency, this._precision);
        } else {
            return toCurStringWithPrecision(price / this._precisionFactor, this.currency, this._precision);
        }
    }

    constructor(private userStore: UserStore,
        private guiService: GuiService,
        translationService: TranslationService) {
        super(translationService);
    }

    async ngOnInit() {
        if (!this.currency) {
            this.currency = this.userStore.getCurrency();
            this.updateDisplay();
        }
    }

    onChange(e: Event) {
        const inputString = (e.target as HTMLInputElement).value;
        const currencySymbol = getCurStringSettings(this.currency)[1];
        const centsString = inputString.replace(currencySymbol, "").replace(",", ".").replace(/[^0-9.-]+/g, "");
        if (this._defaultPrice !== undefined && !centsString.length) {
            this.onPriceChanged(null);
        } else {
            this.onPriceChanged(Math.trunc(Math.round(Number(centsString) * this._precisionFactor)));
        }
    }

    onPriceChanged(priceInt: number | null) {
        if (this._precision == 2 && priceInt !== null) { // TODO: handle currencies with other default precisions than 2
            // Check price step.
            const minStep = environment().priceMinStepCents;
            if (minStep > 0 && priceInt % minStep != 0) {
                // Price does not match the defined step. Round down and show message.
                const wrongPrice = toCurStringWithPrecision(priceInt / this._precisionFactor, this.currency, this._precision);
                priceInt = Math.max(0, priceInt - (priceInt % minStep));
                const fixedPrice = toCurStringWithPrecision(priceInt / this._precisionFactor, this.currency, this._precision);
                this.guiService.messageDialog(this.translate("🌐General.Price"),
                    this.translateWithTokens("🌐Messages.PriceStepRounded",
                        ["%minstep%", minStep, "%wrongprice%", wrongPrice, "%fixedprice%", fixedPrice]));
            }
        }

        this._price = priceInt;
        this.updateDisplay();
        this.priceChange.emit(priceInt === null ? null : this._price * (100 / this._precisionFactor)); // adjust for different precision than cents
        this._onChange(this._price);
    }

    writeValue(price: number): void {
        this._price = price;
    }

    registerOnChange(fn: any): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this._onTouched = fn;
    }

    // empty function if the registration of the handler is late
    _onChange = (price: number) => { };
    _onTouched = (_: any) => { };

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    onFocus(e: any) {
        this.hasFocus = true;
        this.updateDisplay();
    }

    onBlur(e: any) {
        this.hasFocus = false;
        this.updateDisplay();
    }

    onTouched() {
        this._onTouched(undefined);
    }

}
